import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import Options from "./Options";

import { FormClient } from "./FormClient";
import { TitleAndArrow } from "./TitleAndArrow";

const RegisterUser = ({
  valTot,
  changeCepCliente,
  changeClient,
  formVisible,
  setFormVisible,
  errosDataProspectAndPartner,
  setErrosDataProspectAndPartner
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Card id="cliente">
      <CardBody>
        <TitleAndArrow
          formVisible={formVisible}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />

        {isOpen ? (
          <FormClient
            errosDataProspectAndPartner={errosDataProspectAndPartner}
            setErrosDataProspectAndPartner={setErrosDataProspectAndPartner}
            formVisible={formVisible}
            setFormVisible={setFormVisible}
            changeCepCliente={changeCepCliente}
            changeClient={changeClient}
            valTot={valTot}
          />
        ) : (
          <div />
        )}
      </CardBody>
    </Card>
  );
};

export default RegisterUser;
