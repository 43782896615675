import React, { useState, useEffect } from "react";
import {
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Tooltip,
} from "reactstrap";
import { formato } from "../../utils";
import { emitirPedido, editarPedido } from "../../store/actions";
import { getTermos, saveProspectClient } from "../../api/index";
import { useDispatch, useSelector } from "react-redux";
import { validaCpfCnpj } from "../../utils";
import { useHistory, useParams } from "react-router-dom";
import { useSolar } from "../../hooks";
import { toast } from "react-toastify";
import { Coin } from "phosphor-react";

const Resumo = ({
  valTot,
  frete,
  saldo,
  pedido,
  changePedidoEmitido,
  valRt,
  changeType,
  formVisible,
  setLoadingProspect,
  percent,
  qtd,
  discountValue,
  arrayShippingFilialSelect,
  newAddItemsSeparadoPorFilial,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { emissaoPedido, order, vendedor } = useSelector(state => ({
    order: state.Order.orderActive.order,
    emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
    vendedor: state.Order.newOrder.newOrder.vendedor
  }));

  const [aceiteTerm, setAceiteTerm] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [modalTerm, setModalTerm] = useState(false);
  const [modalPedido, setModalPedido] = useState({ open: false, name: "" });
  const discount =
    discountValue?.typerulediscount == 1
      ? (parseFloat(valTot) / 100) * Number(discountValue?.discountValue)
      : discountValue?.discountValue;

  var totalDiscount = parseFloat(valTot) - parseFloat(discount || 0);
  var shipping = frete?.priceSomado ? frete?.priceSomado : frete.price || 0;

  const isSolar = useSolar();

  const [termos, setTermos] = useState(<></>);

  const vendedorComRt = vendedor.VENDEDOR.TEMRT == '1';

  useEffect(() => {
    const getTermosApi = async company => {
      const response = await getTermos(company);
      setTermos(response);
    };

    getTermosApi(isSolar ? "02" : "01");
  }, [isSolar]);

  const validarErros = typeFormIsClient => {
    let erros = [];
    if (typeFormIsClient) {
      if (!validaCpfCnpj(pedido.client.cpfcnpj))
        erros.push("CPF ou CNPJ inválido. Revise e tente novamente!");
    }
    if (pedido.items.length < 1)
      erros.push("Não é possível emitir pedido com carrinho vazio.");
    if (!pedido.filters.condpagto)
      erros.push(
        "Condição de pagamento não selecionada! Caso apareça selecionada, tente selecionar novamente"
      );
    if (!pedido.filters.tabela_preco)
      erros.push(
        "Tabela não selecionada! Caso apareça selecionada, tente selecionar novamente"
      );
    if (!pedido.shipping.id) erros.push("Frete não selecionado.");
    if (!pedido.client.email) erros.push("Email não preenchido.");

    if (typeFormIsClient) {
      if (pedido.client.cpfcnpj.length <= 11) {
        if (
          !pedido.client.dt_nasc ||
          pedido.client.dt_nasc === "Data inválida"
        ) {
          erros.push("Data de nascimento inválida");
        }
      }
      if (!pedido.client.customer_phones[1].phone)
        erros.push("Celular não preenchido.");
      if (!pedido.client.customer_address[0].number)
        erros.push("Numero de faturamento não preenchido.");
      if (!pedido.client.customer_address[1].number)
        erros.push("Numero de entrega não preenchido. ");
      if (!pedido.client.customer_phones[0].phone)
        erros.push("Telefone não preenchido.");
    } else {
      if (!pedido.client.customer_phones[0].phone)
        erros.push("Celular não preenchido.");
    }
    if (typeFormIsClient) {
      if (!(pedido.client.fullname && pedido.client.name_reduced))
        erros.push("Nome e nome reduzido não preenchido.");
    } else {
      if (!pedido.client.fullname) erros.push("Nome  não preenchido.");
    }
    if (typeFormIsClient) {
      if (pedido.client.pessoa == "F" && !pedido.client.dt_nasc)
        erros.push("Data de Nascimento não preenchida.");
    }
    if (
      !(
        pedido.client.customer_address[0].zipcode &&
        pedido.client.customer_address[0].address &&
        pedido.client.customer_address[0].number
      )
    )
      erros.push("Endereço de cobrança não preenchido.");
    if (typeFormIsClient) {
      if (
        !(
          pedido.client.customer_address[1].zipcode &&
          pedido.client.customer_address[1].address &&
          pedido.client.customer_address[1].number
        )
      )
        erros.push("Endereço de entrega não preenchido.");
    }
    return erros;
  };

  const openModal = () => {
    let erros = validarErros(formVisible.client);

    if (erros.length > 0) {
      erros.map(value => {
        toast.warning(value, { theme: "colored" });
      });
      return false;
    }
    
    setModalPedido({ open: true, name: "order" });
  };

  const editOrder = () => {
    setLoadingProspect(true);
    if (aceiteTerm) {
      dispatch(
        editarPedido({
          codEmpresa: '01',
          filial: order.CodFilial,
          id,
          pedido,
        })
      );
    } else {
      toast.warning(
        `É preciso aceitar os termos de fornecimento para emitir pedidos.`,
        { theme: "colored" }
      );
    }
    setModalPedido({ open: !modalPedido, name: "" });
    setLoadingProspect(false);
  };

  useEffect(() => {
    if (!emissaoPedido.loading && emissaoPedido.data && fetch) {
      if (emissaoPedido.data?.success) {
        changePedidoEmitido();
      }
      setFetch(false);
    }
  }, [emissaoPedido, fetch]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4
            style={{
              fontSize: 23,
              fontWeight: "bold",
              color: "#202833",
              marginBottom: 25,
            }}
          >
            Resumo
          </h4>
          <Table className="mt-0 tabela-resumo mb-0" borderless>
            <tbody>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Valor dos Produtos
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {Number(valTot).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Frete
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {Number(
                    frete?.priceSomado ? frete?.priceSomado : frete.price || 0
                  ).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Desconto Aplicado
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {"- " + (discount || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid #e7e7e7" }}>
                <td style={{ padding: "0 0 17px 0" }} colSpan={2}>
                  Saldo em conta utilizado
                </td>
                <td style={{ padding: "0 0 17px 0", textAlign: "end" }}>
                  {(saldo || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              {vendedorComRt && (
                <tr style={{ borderBottom: "1px solid #e7e7e7" }}>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "17px 0 17px 0",
                    }}
                  >
                    Cashback Wattpay
                    <Coin style={{ marginLeft: 10 }} size={15} weight="regular" />
                  </td>
                  { }
                  <td style={{ padding: "17px 0 17px 0", textAlign: "end" }}>
                    {parseFloat(percent || 0).toFixed(2)} %
                  </td>
                  <td style={{ padding: "17px 0 17px 0", textAlign: "end" }}>
                    {(valRt || 0).toLocaleString("pt-br", formato)}
                  </td>
                </tr>
              )}
              <tr>
                <td style={{ padding: "20px 0 35px 0" }} colSpan={2}>
                  <h4
                    className="mb-0"
                    style={{
                      fontSize: 23,
                      fontWeight: "bold",
                      color: "#202833",
                    }}
                  >
                    Valor Total
                  </h4>
                </td>
                <td style={{ padding: "20px 0 35px 0" }}>
                  <h4
                    className="mb-0"
                    style={{
                      fontSize: 23,
                      fontWeight: "bold",
                      color: "#202833",
                      marginBottom: 25,
                      textAlign: "end",
                    }}
                  >
                    {parseFloat(
                      parseFloat(totalDiscount) +
                      parseFloat(shipping) -
                      parseFloat(saldo)
                    ).toLocaleString("pt-br", formato)}
                  </h4>
                </td>
              </tr>
            </tbody>
          </Table>
          <FormGroup check className="d-flex">
            <Input
              type="checkbox"
              value="A"
              className="modalidade aceite"
              onClick={e => setAceiteTerm(e.target.checked)}
            />
            <Label
              className={aceiteTerm ? "label-prazo-active" : ""}
              style={{ marginLeft: 20, fontWeight: 500 }}
            >
              Li e concordo com os{" "}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setModalTerm(!modalTerm);
                }}
              >
                Termos e condições <br />
                de emissão de pedidos e orçamentos
              </a>
            </Label>
          </FormGroup>
          {emissaoPedido.loading && (
            <div className="carregando-emissao">
              <Spinner />
            </div>
          )}

          <Button
            style={{
              backgroundColor: "#7EACC4",
              color: "#FFF",
              width: "100%",
              marginTop: 30,
              display: "block",
              fontSize: 17,
              border: "none",
              padding: 20,
              borderRadius: 6,
              opacity: formVisible.client ? 1 : 0.7,
              cursor: formVisible.client ? "pointer" : "not-allowed",
            }}
            id="emitir-pedido"
            onClick={() => {
              openModal("order");
            }}
          >EDITAR PEDIDO</Button>
        </CardBody>
      </Card>
      <Modal
        isOpen={modalPedido.open}
        size="sm"
        toggle={() => setModalPedido({ open: !modalPedido, name: "" })}
      >
        <ModalHeader
          toggle={() => setModalPedido({ open: !modalPedido, name: "" })}
          className="w-100 justify-content-center"
        >
          Deseja confirmar a edição do pedido?
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center gap-5">
          <Button
            style={{
              backgroundColor: "#004476",
              color: "white",
            }}
            onClick={() => {
              editOrder("order");
              setFetch(true);
            }}
          >
            Confirmar
          </Button>
          <Button
            style={{ backgroundColor: "#E0E0E0", color: "#787878" }}
            onClick={() => setModalPedido({ open: !modalPedido, name: "" })}
          >
            Cancelar
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalTerm}
        size="lg"
        toggle={() => setModalTerm(!modalTerm)}
      >
        <ModalHeader toggle={() => setModalTerm(!modalTerm)}>
          Termos e condições de emissão de pedidos e orçamentos
        </ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: termos }}></ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Resumo;
