import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Input, Card, CardBody, Col, Row } from "reactstrap";
import AddedItem from "../AddedItem";
import { formato } from "../../../../utils";
import "./style.js";
import { toast } from "react-toastify";
import { Container } from "./style.js";

const Cart = ({
  addValTot,
  valores,
  addValorRt,
  ocultarRt,
  setItensCarrinho,
  percentCashBackAll,
  setPercentCashBackAll,
  setPercentCashBackAllOnBlur,
  percentCashBackAllOnBlur,
  setValTotAndRt,
  valTotAndRt,
  changeFormOfPayment,
  addValorRtNoRetention,
  total,
  itHasForecast,
  shippingValues,
  setShippingValues,
  triangularSale,
}) => {
  const { newOrder, vendedor } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
      vendedor: state.Order.newOrder.newOrder.vendedor,
    };
  });

  const handleAllCashBack = event => {
    var value = event.target.value;

    if (parseInt(value) < 0 || parseInt(value) > 50) {
      toast.warning(`A % deve estar entre 0% e 50%`, { theme: "colored" });
    } else {
      setPercentCashBackAll(event.target.value);
    }
  };

  const handleAllCashBackBlur = () => {
    setPercentCashBackAllOnBlur(state => !state);
  };

  useEffect(() => {
    addValTot(valTotAndRt);
  }, [valTotAndRt]);

  useEffect(() => {
    if (changeFormOfPayment) {
      setPercentCashBackAll("0");
    }
  }, [changeFormOfPayment]);

  return (
    <Container ItHasForecast={itHasForecast}>
      <Col className="cart">
        <h4
          className="mb-4"
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "#FF601A",
          }}
        >
          Produtos Adicionados
        </h4>

        <Card>
          <CardBody>
            <Row>
              <Col
                md={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                }}
              >
              </Col>
              <Col md={4}>
                <div className="product-info" hidden={ocultarRt}>
                  <p
                    style={{
                      marginBottom: 3,
                      textAlign: "center",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Adicionar % de CashBack para todos os produtos"
                  >
                    Adicionar % de CashBack para todos os produtos
                  </p>
                  <Input
                    className="mb-0 mt-4 pre-venda"
                    style={{ maxWidth: 170 }}
                    value={percentCashBackAll.replace(/[^\d.,]/g, "")}
                    onChange={handleAllCashBack}
                    onBlur={handleAllCashBackBlur}
                    readOnly={vendedor?.VENDEDOR?.TEMRT != 1 || triangularSale}
                  />
                </div>
              </Col>
            </Row>

            <Row
              style={{ marginLeft: 30, marginRight: 30, marginTop: 20 }}
            >
              <Col
                style={{ padding: 0 }}
                md={triangularSale ? 5 : 6}
              >
                PRODUTO
              </Col>
              <Col style={{ padding: 0, textAlign: "center" }} md={triangularSale ? 1 : 2}>
                DATA SHEET
              </Col>
              <Col style={{ padding: 0 }} md={1}>
                QTDE
              </Col>
              <Col style={{ padding: 0 }} md={2}>
                PREVISÃO DISP.
              </Col>
              {triangularSale && (
                <Col style={{ padding: 0 }} md={2}>
                  VALOR REMESSA
                </Col>
              )}
              <Col md={1}>AÇÕES</Col>
            </Row>

            <div className="box-cart">
              {newOrder.addedItems.length > 0 ? (
                newOrder.addedItems?.map((item, k) => (
                  <AddedItem
                    key={k}
                    item={item.produto[0]}
                    addValTot={addValTot}
                    valores={valores}
                    setItensCarrinho={setItensCarrinho}
                    addValorRt={addValorRt}
                    ocultarRt={ocultarRt}
                    percentCashBackAll={percentCashBackAll}
                    percentCashBackAllOnBlur={percentCashBackAllOnBlur}
                    setValTotAndRt={setValTotAndRt}
                    changeFormOfPayment={changeFormOfPayment}
                    itHasForecast={itHasForecast}
                    addValorRtNoRetention={addValorRtNoRetention}
                    shippingValues={shippingValues}
                    setShippingValues={setShippingValues}
                    triangularSale={triangularSale}
                  />
                ))
              ) : (
                <Row className="mt-2">
                  <Col md={12} style={{ display: "flex", justifyContent: "center" }}>
                    Nenhum produto inserido no carrinho
                  </Col>
                </Row>
              )}
            </div>
            <Card
              style={{
                borderRadius: "0 0  15px 15px",
                backgroundColor: "#FFF7F2",
                zIndex: 100,
                marginTop: 20,
              }}
            >
              <CardBody>
                <Row>
                  <Col style={{ color: "#FF601A" }} md={3}>
                    TOTAL DO ORÇAMENTO
                  </Col>
                  <Col style={{ color: "#FF601A" }} md={3}>
                    -
                  </Col>
                  <Col md={3}></Col>
                  <Col style={{ color: "#FF601A" }} md={3}>
                    {total.toLocaleString("pt-br", formato)}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </Col>
    </Container >
  );
};

export default Cart;
