import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import {
  compareValues,
  handleValueUpdate,
} from "../../utils/functions/cashBack";

const RtAndCashBack = ({
  item,
  subTotal,
  valores,
  setItensCarrinho,
  addValTot,
  addValorRt,
  ocultarRt,
  percentCashBackAll,
  percentCashBackAllOnBlur,
  collapse,
  setValTotAndRt,
  changeFormOfPayment,
  addValorRtNoRetention,
}) => {
  const { vendedor } = useSelector(state => {
    return {
      vendedor: state.Order.newOrder.newOrder.vendedor,
    };
  });
  const maximumValueMultiplier = 2.1;
  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };
  const [precoItem, setPrecoItem] = useState(parseFloat(subTotal.toFixed(2)));
  const [valMin, setValMin] = useState(parseFloat(subTotal.toFixed(2)));
  const [valMax, setValMax] = useState(
    parseFloat(item?.subTotal.toFixed(2)) * maximumValueMultiplier
  );
  const [valCashbackNoRetention, setValCashbackNoRetention] = useState(0);
  const [quant, setQuant] = useState(item?.qtd ? item.qtd : 1);
  const [precoVenda, setPrecoVenda] = useState(parseFloat(subTotal.toFixed(2)));
  const [valorTotal, setValorTotal] = useState(parseFloat(subTotal.toFixed(2)));
  const [valorTabela, setValorTabela] = useState(
    parseFloat(subTotal.toFixed(2))
  );
  const [percent, setPercent] = useState(0);
  const [valCashback, setValCashback] = useState(0);
  const [formattedPercent, setFormattedPercent] = useState("10");
  const [formattedPrecoVenda, setFormattedPrecoVenda] = useState("0");
  const [formattedValorTotal, setFormattedValorTotal] = useState(
    parseFloat(subTotal.toFixed(2)).toLocaleString("pt-br", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  );

  useEffect(() => {
    setQuant(item?.qtd ? item.qtd : 1);
  }, [item?.qtd]);

  useEffect(() => {
    setPrecoItem(parseFloat(subTotal.toFixed(2)));
    setValMin(parseFloat(subTotal.toFixed(2)));
    setValMax(parseFloat(subTotal.toFixed(2)) * maximumValueMultiplier);
    setPrecoVenda(parseFloat(subTotal.toFixed(2)));
    setValorTotal(parseFloat(subTotal.toFixed(2)));
    setValorTabela(parseFloat(subTotal.toFixed(2)));
  }, [item, subTotal, collapse]);

  useEffect(() => {
    let valTotalRt = {
      nome: item?.id ? item.id + " " + item.nome : item.nome,
      valCashBack: Number(valCashback),
    };

    addValorRt(valTotalRt);

    let valTotalRtNoRetention = {
      nome: item?.id ? item.id + " " + item.nome : item.nome,
      valCashBackNoRentention: Number(valCashbackNoRetention),
    };

    addValorRtNoRetention(valTotalRtNoRetention);
  }, [valCashback, valCashbackNoRetention]);

  useEffect(() => {
    if (changeFormOfPayment) {
      return;
    }
    if (percentCashBackAll == "0") {
      setValTotAndRt(state => {
        return {
          ...state,
          [item?.id ? item?.id + " " + item.nome : item.nome]: Number(subTotal),
        };
      });
    }
  }, [percentCashBackAll, collapse]);

  useEffect(() => {
    if (changeFormOfPayment) {
      return;
    }
    if (percentCashBackAll != "0" || collapse) {
      handleValueUpdateForm(percentCashBackAll, "percent");
    }
  }, [percentCashBackAll, collapse]);

  useEffect(() => {
    if (changeFormOfPayment) {
      return;
    }
    if (percentCashBackAll != "0" || collapse) {
      compareValuesInput("percent");
    }
  }, [percentCashBackAllOnBlur, collapse]);

  useEffect(() => {
    if (!collapse) {
      setValCashback(0);
      setValCashbackNoRetention(0);
      setPercent(0);
    }
  }, [collapse]);

  // Formatando os valores
  useEffect(() => {
    if (collapse) {
      let indexOfComma = String(percent).indexOf(".");
      let percentFormatado = "";
      if (indexOfComma !== -1) {
        percentFormatado = String(percent).slice(0, indexOfComma + 3);
      } else {
        percentFormatado = String(percent);
      }

      setFormattedPercent(percentFormatado);
      setFormattedPrecoVenda(
        precoVenda.toLocaleString("pt-br", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      );
      let replacedPrecoVenda = parseFloat(
        precoVenda.toString().replace(",", ".")
      );

      let total = replacedPrecoVenda * quant;
      setFormattedValorTotal(
        total.toLocaleString("pt-br", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      );
    }
  }, [precoVenda, valorTotal, valorTabela, percent, valCashback, collapse]);

  useEffect(() => {
    if (changeFormOfPayment) {
      return;
    }
    if (percentCashBackAll != "0") {
      if (Object.keys(item).length > 0) {
        let valTotal = {
          ...valores,
          [item?.id ? item?.id + " " + item.nome : item.nome]:
            Number(valorTotal),
        };
        addValTot(valTotal);
      }
    }
  }, [item]);

  useEffect(() => {
    if (!collapse) {
      return;
    }
    setValuesCart();
  }, [valCashback, changeFormOfPayment, collapse]);

  const setValuesCart = () => {
    let valTotal = {
      ...valores,
      [item.nome]: Number(valorTotal),
    };

    setItensCarrinho(state => {
      return {
        ...state,
        [item.nome]: {
          AMOUNT: Number(quant),
          PRCRT: precoVenda,
          VALORRT: Number(valCashback) / Number(quant),
          subtotal: valTotal,
          maskPrcVenda: precoVenda,
          PERCENT: percent,
        },
      };
    });
  };

  useEffect(() => {
    let replacedPrecoVenda = parseFloat(
      precoVenda.toString().replace(",", ".")
    );

    setValorTotal(replacedPrecoVenda * quant);
  }, [precoVenda, quant]);

  const handleValueUpdateForm = (newValue, updatedState) => {
    handleValueUpdate(
      newValue,
      updatedState,
      setPercent,
      setValCashback,
      setPrecoVenda,
      setValCashbackNoRetention
    );
  };

  const compareValuesInput = changed => {
    compareValues(
      changed,
      precoVenda,
      valCashback,
      valorTabela,
      valores,
      item,
      addValTot,
      setValCashback,
      setPercent,
      setPrecoVenda,
      valMin,
      valMax,
      percent,
      precoItem,
      setValTotAndRt,
      setValCashbackNoRetention
    );
  };

  const maskMoney = value => {
    let stringValue = value.toString();
    let v = new Intl.NumberFormat("pt-br", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(stringValue);
    return v;
  };

  return (
    <>
      {collapse && (
        <div
          className="product-infos"
          style={{
            display: "none",
            background: "#fff7f2",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div className="product-info">
            <p className="info-title">Preço de Venda (R$)</p>
            <div style={{ position: "relative" }}>
              <Input
                className="mb-0 mt-4 pre-venda"
                value={formattedPrecoVenda?.replace(/[^\d.,]/g, "")}
                onChange={e => {
                  handleValueUpdateForm(e.target.value, "precoVenda");
                }}
                readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
                onBlur={() => {
                  compareValuesInput("precoVenda");
                }}
              />
            </div>
          </div>
          <div className="product-info">
            <p className="info-title">Valor Total (R$)</p>
            <p className="info-result mb-0">{formattedValorTotal}</p>
          </div>
          <div className="product-info">
            <p className="info-title">Tabela (R$)</p>
            <p className="info-result mb-0">{`${maskMoney(valorTabela)}`}</p>
          </div>
          <div className="product-info" hidden={ocultarRt}>
            <p className="info-title">%</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              value={formattedPercent.replace(/[^\d.,]/g, "")}
              onChange={e => {
                handleValueUpdateForm(e.target.value, "percent");
              }}
              onBlur={() => compareValuesInput("percent")}
              readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
            />
          </div>
          <div className="product-info" hidden={ocultarRt}>
            <p className="info-title">Valor Cashback (R$)</p>
            <p className="info-result mb-0">{`${maskMoney(valCashback)}`}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default RtAndCashBack;
