import React, { useState, useEffect } from "react";
import { CardBody, Col, Row, Card, Input, Button, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { orderApiSuccess, removeFromCart } from "../../store/actions";
import { formato } from "../../utils";
import { toast } from "react-toastify";
import { filiais } from "../../utils/filiais";
import { Minus, Plus, Trash } from "phosphor-react";
import InputMask from "react-input-mask";
import { useDataLayer } from "../../hooks/DataLayerHooks";

const AddedItem = ({
  item,
  addValTot,
  valores,
  setItensCarrinho,
  itensCarrinho,
  addValArqu,
  valArquiteto,
  addValorRt,
  valorRt,
  setValServ,
  setSendValServ,
  setPercentResumo,
  setQtdAdd,
}) => {
  const dispatch = useDispatch();
  const { vendedor, order, findedItems, addedItems, frete, ocultarRt, comRt } = useSelector(
    state => ({
      vendedor: state.Order.newOrder.newOrder.vendedor,
      order: state.Order.orderActive.order,
      findedItems: state.Order.newOrder.newOrder.findedItems,
      addedItems: state.Order.newOrder.newOrder.addedItems,
      frete: state.Order.newOrder.newOrder.frete,
      ocultarRt: state.Order.newOrder.newOrder.filters.ocultarRt,
      comRt: state.Order.newOrder.newOrder.comRt
    })
  );

  const { pushToDataLayer } = useDataLayer();

  const vendedorComRt = comRt == '1';

  useEffect(() => {
    setQtdAdd(item.quant);
  }, [item.quant]);

  //variaveis input
  const [precoItem, setPrecoItem] = useState(
    parseFloat(item.precoVenda).toFixed(2)
  );
  const [valMin, setValMin] = useState(
    parseFloat(item.valorTabela).toFixed(2) -
      parseFloat(item.valorTabela).toFixed(2) * 0.05
  );
  const [valMax, setValMax] = useState(
    parseFloat(item.valorTabela).toFixed(2) * 1.5
  );
  const [precoVenda, setPrecoVenda] = useState(
    parseFloat(item.precoVenda).toFixed(2)
  );
  const [valorTotal, setValorTotal] = useState(
    parseInt(item.quant || 1) * parseFloat(item.precoVenda).toFixed(2)
  );
  const [valorTabela, setValorTabela] = useState(
    parseFloat(item.valorTabela).toFixed(2)
  );

  //formatado
  const [formattedServico, setFormattedServico] = useState("0,00");
  const [formattedPercent, setFormattedPercent] = useState("");
  const [formattedValCashback, setFormattedValCashback] = useState("0");
  const [formattedValorTotal, setFormattedValorTotal] = useState(
    (
      parseInt(item.quant || 1) * parseFloat(item.precoVenda).toFixed(2)
    ).toLocaleString("pt-br", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  );
  const [formattedPrecoVenda, setFormattedPrecoVenda] = useState(
    precoItem.toLocaleString("pt-br", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  );
  const percentCashback = 50;
  const [addServico, setAddServico] = useState(false);
  const [servico, setServico] = useState();
  const [quant, setQuant] = useState(Number(item.quant) || 1);
  const [percent, setPercent] = useState(0);
  const [valCashback, setValCashback] = useState(0);
  const [rtArquiteto] = useState(0);
  const [, setDiff] = useState();

  useEffect(() => {
    setPrecoItem(parseFloat(item.precoVenda).toFixed(2));
    setValMin(parseFloat(item.valorTabela).toFixed(2) * 0.95);
    setValMax(parseFloat(item.valorTabela).toFixed(2) * 1.5);
    setPrecoVenda(parseFloat(item.precoVenda).toFixed(2));
    setValorTotal(
      parseInt(item.quant || 1) * parseFloat(item.precoVenda).toFixed(2)
    );
    setValorTabela(parseFloat(item.valorTabela).toFixed(2));
    setDiff(
      parseFloat(item.precoVenda).toFixed(2) -
        parseFloat(item.precoVenda).toFixed(2)
    );
    compareValues("start");
  }, [item, item?.precoVenda]);

  const ratio = 30 / 70; // quantos % aumentar o valor levando em conta a diminuição de 30% -> ex: diminuiu o produto em 30%, para voltar ao valor normal, aumentar em ratio%
  const retained = 0.7;
  /////////////////////////////////////////////////////////////

  const detalhes = findedItems[item.cod] || {};

  //formatando os valores
  useEffect(() => {
    setDiff(precoVenda - valorTabela);
    let indexOfComma = String(percent).indexOf(".");
    let percentFormatado = "";
    if (indexOfComma !== -1) {
      percentFormatado = String(percent).slice(0, indexOfComma + 3);
    } else {
      percentFormatado = String(percent);
    }

    setFormattedPercent(percentFormatado);
    setFormattedPrecoVenda(
      precoVenda.toLocaleString("pt-br", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
    setFormattedValCashback(
      valCashback.toLocaleString("pt-br", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
    let replacedPrecoVenda = parseFloat(
      precoVenda.toString().replace(",", ".")
    );
    let total = replacedPrecoVenda * quant;
    setFormattedValorTotal(
      total.toLocaleString("pt-br", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );

    let valTotal = {
      ...valores,
      [item.produto.CODPRODUTO]: Number(valorTotal),
    };
    addValTot(valTotal);
  }, [precoVenda, valorTotal, valorTabela, percent, valCashback]);

  //alterações componente pai
  useEffect(() => {
    let valTotal = {
      ...valores,
      [item.produto.CODPRODUTO]: Number(valorTotal),
    };

    let itemsCar = {
      [item.produto.CODPRODUTO]: {
        AMOUNT: Number(quant),
        VALORRT: Number(valCashback) / Number(quant),
        PRCRT: Number(precoVenda.toString().replace(",", ".")),
        subtotal: valTotal,
        maskPrcVenda: precoVenda,
        PERCENT: percent,
      },
    };

    setItensCarrinho(state => {
      return { ...state, ...itemsCar };
    });

    let arqu = {
      ...valArquiteto,
      [item.produto.CODPRODUTO]: Number(rtArquiteto),
    };
    addValArqu(arqu);

    if (vendedorComRt) {
      let rtVal = {
        ...valorRt,
        [item.produto.CODPRODUTO]: Number(valCashback),
      };
      addValorRt(rtVal);  
    }
  }, [valorTotal, quant, valCashback, precoVenda, percent, rtArquiteto]);

  useEffect(() => {
    let replacedPrecoVenda = parseFloat(
      precoVenda.toString().replace(",", ".")
    );
    setValorTotal(replacedPrecoVenda * quant);
  }, [precoVenda, quant]);

  useEffect(() => {
    if (addServico && servico) {
      let servicoNumber = parseFloat(
        servico.replace(".", "").replace(",", ".")
      );
      setFormattedServico(servicoNumber.toLocaleString("pt-br", formato));
    }
  }, [servico]);

  //resetando valores
  const resetValues = () => {
    var percentInitial = 0.05;
    var percent = 0;
    var cashbackInitial = valorTabela * percentInitial;
    let cashback = valorTabela * (percent / 100);
    var cashbackValue = cashbackInitial - cashback;
    var precoVenda = valorTabela;

    setPrecoVenda(precoVenda);
    setPercent(0);
    setValCashback(0);
  };

  //lidando com "onChange"
  const handleValueUpdate = (newValue, updatedState) => {
    switch (updatedState) {
      case "percent":
        updatePercent(newValue);
        break;
      case "cashback":
        updateValCashback(newValue);
        break;
      case "precoVenda":
        newValue = newValue.toString().replace(".", "");
        setPrecoVenda(newValue);
        break;
    }
  };

  const updatePercent = newPercent => {
    if (vendedorComRt) {
      setPercent(newPercent);
    }
  }

  const updateValCashback = newValCashback => {
    if (vendedorComRt) {
      setValCashback(newValCashback);
    }
  }

  const validStock = qtd => {
   const sortedBranchesByQuantity = item.produto.PRDDISPS.STRETPRDFILADIAS.sort((a, b) => b.ESTOQUE - a.ESTOQUE);

    let sumOfTheTwoLargestStocks = 0;
    if (sortedBranchesByQuantity.length >= 2) {
      sumOfTheTwoLargestStocks = sortedBranchesByQuantity[0].ESTOQUE + sortedBranchesByQuantity[1].ESTOQUE;
    } else if (sortedBranchesByQuantity.length === 1) {
      sumOfTheTwoLargestStocks = sortedBranchesByQuantity[0].ESTOQUE;
    }

    if (qtd > sumOfTheTwoLargestStocks) {
      return sumOfTheTwoLargestStocks;
    } else {
      return null;
    }
  };

  //matematica dos valores
  const compareValues = (changed, e) => {
    let valTotal = {
      ...valores,
      [item.produto.CODPRODUTO]: Number(valorTotal),
    };
    addValTot(valTotal);

    let replacedPrecoVenda =
      changed === "start"
        ? precoVenda
        : parseFloat(precoVenda.toString().replace(",", "."));
    let replacedValCashback =
      changed === "start"
        ? valCashback
        : parseFloat(valCashback.toString().replace(",", "."));

    const precoVendaChanged = () => {
      //formula inputando preço de venda
      if (replacedPrecoVenda >= valorTabela) {
        let cashback =
          quant *
          (valorTabela * 0.05 + (replacedPrecoVenda - valorTabela) * retained);

        let percent = ((cashback / replacedPrecoVenda) * 100) / quant;
        updateValCashback(cashback);
        updatePercent(percent);
      } else if (replacedPrecoVenda < valorTabela) {
        var percentInital = 5;
        var percent = (
          parseFloat(percentInital) +
          ((replacedPrecoVenda - valorTabela) / valorTabela) * 100
        ).toFixed(2);
        var cashback = (replacedPrecoVenda / 100) * percent;

        updateValCashback(cashback);
        updatePercent(percent);
      }
    };

    //////////////////////////////////////WORK IN PROGRESS
    const percentChanged = () => {
      //102.4205 + 5% valor tabela = cashback -> eletrolux 12000 BTU
      //descobrir como chegar em 102.4205
      //formula inputando a porcentagem
      if (vendedorComRt) {
        if (percent >= 5) {
          let d = valorTabela * 0.05;
  
          let cashback =
            (retained * percent * valorTabela - percent * d) / (70 - percent);
          updateValCashback(cashback * quant);
          setPrecoVenda((cashback * 100) / percent);
        } else {
          var percentInitial = 0.05;
          var cashbackInitial = valorTabela * percentInitial;
          let cashback = valorTabela * (percent / 100);
          var cashbackValue = cashbackInitial - cashback;
          var precoVenda = valorTabela - cashbackValue;
          var cashbackFinal = precoVenda * (percent / 100);
  
          updateValCashback(quant * cashbackFinal);
          setPrecoVenda(precoVenda);
        }
      }
    };

    /////////////////////////////////////////////////////////

    const cashbackChanged = () => {
      //formula inputando o cashback
      //cashback inputado - 5% do valor tabela ->+ratio% + valor Tabela = precoVenda se for over

      if (vendedorComRt) {
        if (replacedValCashback >= valorTabela * 0.05) {
          let precoVenda =
            replacedValCashback -
            valorTabela * 0.05 +
            (replacedValCashback - valorTabela * 0.05) * ratio +
            valorTabela;
  
          let percent = (replacedValCashback * 100) / precoVenda;
          setPrecoVenda(precoVenda);
          updatePercent(percent);
        } else {
          let precoVendaTemp = valorTabela + replacedValCashback;
          let percentTemp = (precoVendaTemp * 100) / valorTabela;
          setPrecoVenda(precoVendaTemp);
          updatePercent(percentTemp);
        }
      }
    };

    const precoQtd = () => {
      var qtd = Number(e.target.value);
      var hasStock = validStock(qtd);

      if (hasStock) {
        return toast.info(
          `Não foi possivel adicionar quantidade. Limite em estoque: ${hasStock} `,
          { theme: "colored" }
        );
      }

      setQuant(qtd);
      setQtdAdd(qtd);
      let newValCashBack = ((qtd * replacedPrecoVenda) / 100) * percent;
      updateValCashback(Number(newValCashBack));
    };

    switch (changed) {
      case "precoQtd":
        precoQtd();
        break;
      case "precoVenda":
        precoVendaChanged();
        break;
      case "percent":
        percentChanged();
        break;
      case "cashback":
        cashbackChanged();
        break;
      case "start":
        precoVendaChanged();
        break;
    }

    checkValues();
  };

  //limitando os valores
  const checkValues = () => {
    if (precoVenda < valMin || precoVenda > valMax) {
      resetValues();
      toast.warning(
        `O preço de venda deve estar entre ${valMin.toLocaleString(
          "pt-br",
          formato
        )} e ${valMax.toLocaleString("pt-br", formato)}`,
        { theme: "colored" }
      );
    }

    if (percent < 0 || percent > percentCashback) {
      resetValues();
      toast.warning(`A % deve estar entre 0% e ${percentCashback}%`, {
        theme: "colored",
      });
    }
  };

  //remover item do carrinho
  const removeItem = () => {
    let valTotal = valores;
    delete valTotal[item.produto.CODPRODUTO];
    addValTot(valTotal);

    let itemCar = itensCarrinho;
    delete itemCar[item.produto.CODPRODUTO];
    setItensCarrinho(itemCar);

    let arqu = valArquiteto;
    delete arqu[item.produto.CODPRODUTO];
    addValArqu(arqu);

    if (vendedorComRt) {
      let rt = valorRt;
      delete rt[item.produto.CODPRODUTO];
      addValorRt(rt);
    }

    pushToDataLayer(
      'remove_from_cart',
      {
        ecommerce: {
          currency: "BRL",
          items: [{
            item_id: item.produto.CODPRODUTO,
            item_name: item.produto.DESCRICAO,
            item_brand: item.produto.MARCA,
            item_category: item.produto.CATEGORIA,
            price: item.produto.PRCVENDA?.STRETPRCFILADIAS[0]?.PRCVENDA,
            quantity: quant
          }]
        }
      },
      { clearEcommerce: true }
    );

    toast.info(`${item.produto.DESCRICAO} removido do carrinho.`, {
      theme: "colored",
    });
    dispatch(removeFromCart(item));
  };

  const maskMoney = value => {
    let stringValue = value.toString();
    let v = new Intl.NumberFormat("pt-br", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(stringValue);
    return v;
  };

  const [isToastBeignCalled, setIsToastBeignCalled] = useState(false);

  const callWarningOnAFK = () => {
    if (history.location.pathname != "/pedidos/novo") {
      return;
    }
    if (addedItems.length > 0 && !isToastBeignCalled) {
      toast.warning(
        "Seu carrinho será limpo em 60 minutos devido à inatividade",
        { theme: "colored" }
      );
      setIsToastBeignCalled(true);
    }
  };

  const callRemoveItem = () => {
    const removeItemWhenAFK = () => {
      let valTotal = valores;
      delete valTotal[item.produto.CODPRODUTO];
      addValTot(valTotal);

      let itemCar = itensCarrinho;
      delete itemCar[item.produto.CODPRODUTO];
      setItensCarrinho(itemCar);

      let arqu = valArquiteto;
      delete arqu[item.produto.CODPRODUTO];
      addValArqu(arqu);

      if (vendedorComRt) {
        let rt = valorRt;
        delete rt[item.produto.CODPRODUTO];
        addValorRt(rt);
      }

      toast.warning(
        `${item.produto.DESCRICAO} removido do carrinho devido à inatividade.`,
        {
          autoClose: false,
        }
      );
      dispatch(removeFromCart(item));
    };
    if (history.location.pathname != "/pedidos/novo") {
      return;
    }
    if (addedItems.length > 0) {
      removeItemWhenAFK();
      setIsToastBeignCalled(false);
    }
  };

  let time;
  let timeHalf;

  document.onmousemove = () => {
    clearTimeout(time);
    clearTimeout(timeHalf);
    time = setTimeout(callRemoveItem, 1000 * 60 * 60); //roda em 1 hora
    timeHalf = setTimeout(callWarningOnAFK, 1000 * 60 * 45); //roda em 45 minutos
  };

  const getBranchesStrings = () => {
    let branchesString = "";
    const branchesUsedLength = item.produto?.branchesUsed?.length;

    item.produto.branchesUsed?.map((item, index) => {
      branchesString += (branchesUsedLength == 0 || index == branchesUsedLength - 1)
        ? filiais[Number(item.branchId)]
        : filiais[Number(item.branchId)] + ", ";
    });

    return branchesString;
  };

  useEffect(() => {
    const itemOrcamento = order?.items_orcamento?.find(itemOrcamento => itemOrcamento.Produto == item?.produto?.CODPRODUTO);
    if (itemOrcamento) {
      handleValueUpdate(itemOrcamento.nPERRT, "percent");
    }
  }, [order]);

  return (
    <Card className="mb-0 mt-4">
      <CardBody>
        <Row className="d-flex align-items-end">
          <Col md={3}>
            <p className="mb-0" style={{ fontWeight: 500 }}>
              Enviado de:{" "}
              {getBranchesStrings()}
            </p>
          </Col>
          <Col md={9} className="d-flex justify-content-end align-items-center">
            { (!ocultarRt && vendedorComRt) && (
                <Button color="info" size="sm" outline onClick={resetValues}>
                  Zerar Cashback
                </Button>
              ) 
            }
          </Col>
        </Row>
        <hr className="mb-3" />
        <Row>
          {detalhes.fotos ? (
            <Col md={1}>
              <img src={detalhes.fotos[1]} style={{ width: "100%" }} />
            </Col>
          ) : (
            <div />
          )}
          <Col md={8}>
            <h5>{item.produto.DESCRICAO}</h5>
            <Row>
              <Col className="mt-2 d-flex align-items-center">
                <div>
                  <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                    Cód. Fabricante: /
                  </p>
                  <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                    SKU: {item.produto.CODPRODUTO}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="mb-3" />
        <div className="product-infos">
          <div className="product-info" style={{ width: "10%" }}>
            <p className="info-title">Quantidade</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              type="number"
              value={quant}
              onChange={e => compareValues("precoQtd", e)}
              min={1}
              disabled
            />
          </div>
          <div className="product-info">
            <p className="info-title">Preço de Venda (R$)</p>
            <div style={{ position: "relative" }}>
              <InputMask
                className="mb-0 mt-4 pre-venda"
                //style={{ paddingLeft: "25px!important" }}
                value={formattedPrecoVenda}
                onChange={e => {
                  handleValueUpdate(e.target.value, "precoVenda");
                }}
                readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
                onBlur={() => {
                  compareValues("precoVenda");
                }}
              />
              {/* <div
                style={{
                  position: "absolute",
                  left: "15px",
                  top: "7px",
                  fontWeight: "bold",
                  color: "#929fb1",
                }}
              >
                R$
              </div> */}
            </div>
          </div>
          <div className="product-info">
            <p className="info-title">Valor Total (R$)</p>
            <p className="info-result mb-0">{formattedValorTotal}</p>
          </div>
          <div className="product-info">
            <p className="info-title">Tabela (R$)</p>
            <p className="info-result mb-0">{`${maskMoney(valorTabela)}`}</p>
          </div>
          <div className="product-info" hidden={ocultarRt || !vendedorComRt}>
            <p className="info-title">%</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              value={formattedPercent}
              onChange={e => {
                handleValueUpdate(e.target.value, "percent");
              }}
              onBlur={() => compareValues("percent")}
              readOnly={!vendedorComRt}
            />
          </div>
          <div className="product-info" hidden={ocultarRt || !vendedorComRt}>
            <p className="info-title">Valor Cashback (R$)</p>
            <p className="info-result mb-0">{formattedValCashback}</p>
            {/*  <div style={{ position: "relative" }}>
              {" "}
              <p className="info-title mt-3">{formattedValCashback}</p>
                  <Input
                className="mb-0 mt-4 p-input pre-venda"
                //style={{ paddingLeft: "25px!important" }}
                value={formattedValCashback}
                onChange={e => {
                  handleValueUpdate(e.target.value, "cashback");
                }}
                onBlur={() => compareValues("cashback")}
                readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
              /> 
            </div> */}
          </div>
          {/* 
          <div className="product-info" hidden={ocultarRt}>
            <p className="info-title">Cashback do Arquiteto</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              type="number"
              value={rtArquiteto}
              onChange={e => setRtArquiteto(e.target.value)}
            />
          </div> */}
        </div>{" "}
        {frete?.loading && (
          <p className="att-preco-frete">Atualizando preço do frete...</p>
        )}
        <hr className="mb-3" />
        <Row>
          <Col md={6}>
            <div
              className="cursor-pointer"
              style={{
                color: "rgb(0, 86, 150)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => {
                  setAddServico(prev => !prev);
                  setSendValServ(prev => !prev);
                }}
              >{`${addServico ? "Remover Serviço" : "Incluir Serviço"}`}</div>
              {!addServico ? (
                <Plus
                  className="ms-2"
                  size={15}
                  onClick={() => {
                    setAddServico(prev => !prev);
                    setSendValServ(prev => !prev);
                  }}
                  weight="bold"
                  style={{ marginLeft: 5 }}
                />
              ) : (
                <Minus
                  className="ms-2"
                  size={15}
                  onClick={() => {
                    setAddServico(prev => !prev);
                    setSendValServ(prev => !prev);
                  }}
                  weight="bold"
                  style={{ marginLeft: 5 }}
                />
              )}

              {addServico && (
                <div
                  className="ms-3"
                  style={{ width: "40%", position: "relative" }}
                >
                  <Input
                    value={servico}
                    style={{ paddingLeft: "30px" }}
                    onChange={e => {
                      setServico(e.target.value);
                      setValServ(parseFloat(e.target.value));
                    }}
                    onBlur={() => {
                      let temp = parseFloat(servico);
                      setServico(
                        temp.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      );
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "8px",
                      left: "8px",
                      color: "black",
                    }}
                  >
                    R$
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col md={6}>
            {addServico && (
              <a
                href="#"
                style={{
                  display: "block",
                  width: "fit-content",
                  marginLeft: "auto",
                  color: "rgb(0, 86, 150)",
                }}
              >
                Serviço de Instalação Incluso ({formattedServico})
              </a>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AddedItem;
