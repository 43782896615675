import React from "react";
import { useProfile, useSolar } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";;
import { useState } from "react";
import {
  addProductBySku,
  clearNewOrder,
  getNewOrder,
  getOrderActive,
  getProductDetails,
  ocultarRT,
  resetAllDataOrder,
  setFilterPedido,
  setPagtoSelected,
} from "../../../store/actions";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDataLayer } from "../../../hooks/DataLayerHooks";
const useLogic = () => {
  const { userProfile } = useProfile();
  const history = useHistory();

  const { newOrder, loading, order, orderActiveError, addedItems, emissaoPedido, freteRedux } =
    useSelector(state => {
      return {
        newOrder: state.Order.newOrder.newOrder,
        loading: state.Order.loading,
        order: state.Order.orderActive.order,
        orderActiveError: state.Order.orderActiveError,
        addedItems: state.Order.newOrder.newOrder.addedItems,
        emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
        freteRedux: state.Order.newOrder.newOrder.frete,
      };
    });

  const { pushToDataLayer } = useDataLayer();
  const dispatch = useDispatch();
  const { id, codFilial } = useParams();
  const isSolar = useSolar();
  const [frete, setFrete] = useState({});
  const [valores, setValores] = useState({});
  const [valArquiteto, setValArquiteto] = useState({});
  const [valorRt, setValorRt] = useState({});
  const [ocultarSemEstoque, setOcultarSemEstoque] = useState(true);
  const [nfIsOpen, setNfIsOpen] = useState(false);
  const [obsIsOpen, setObsIsOpen] = useState(false);
  const [cepCliente, setCepCliente] = useState(null);

  const [client, setClient] = useState({});
  const [saldo, setSaldo] = useState(0);
  const [itensCarrinho, setItensCarrinho] = useState({});
  const [filterPedido] = useState({});
  const [pedidoEmitido, setPedidoEmitido] = useState(false);
  const [type, setType] = useState("order");
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [discountValue, setDiscountValue] = useState();
  const [arrayShippingFilialSelect, setArrayShippingFilialSelect] = useState(
    []
  );
  const [isRevenda, setIsRevenda] = useState(false);
  const [awaitModfyCart, setAwaitModfyCart] = useState("open");
  const [pagamento, setPagamento] = useState([]);
  const [, setObsInterna] = useState("");
  const [, setObsNF] = useState("");
  const [percentResumo, setPercentResumo] = useState(0);

  const [sendValServ, setSendValServ] = useState(false);
  const [valServ, setValServ] = useState(0);

  const [dataLeed, setDataLeed] = useState([]);

  const [formVisible, setFormVisible] = useState(
    {
      prospect: false,
      client: true,
      partner: false,
    }
  );
  const [loadingProspect, setLoadingProspect] = useState(false);
  const [dataProducts, setDataProducts] = useState(
    newOrder?.orderProducts?.produtos || []
  );

  const [errosDataProspectAndPartner, setErrosDataProspectAndPartner] =
    useState({
      name: "",
      email: "",
      ddd: "",
      tel: "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      state: "",
      city: "",
      district: "",
    });

  const vendedorComRt = newOrder.comRt == '1';

  const valTot = Somar(Object.values(valores));
  const valRt = Somar(Object.values(valorRt));

  useEffect(() => {
    dispatch(resetAllDataOrder());
  }, []);

  useEffect(() => {
    const formattedId = id?.toString().padStart(6, "0");
    toast.warning(
      "Atenção: valores de equipamentos e frete poderão ser alterados.",
      { theme: "colored" }
    );
    dispatch(getOrderActive(formattedId, isSolar ? "02" : "01", codFilial));
  }, [id]);

  useEffect(() => {
    if (order) {
      if (order.Situacao > 'D') {
        toast.error("A edição de pedidos é permitida apenas para pedidos não faturados.");
        history.push("/dashboard");
      }

      (order.items_orcamento || []).map(value => {
        dispatch(getProductDetails(value.Produto));
        const result = addedItems.find(i => i.cod === value.Produto);
        if (!result) {
          let data = {
            sku: value.Produto,
            tabela: order.TabelaPreco,
            pagto: order.CondicaoPagamento,
            filial: value.CodFilial,
            precoVenda: value.PrecoVend,
            quant: value.Qtdven,
            item: value.Item,
            valorTabela: value.nPVTAB
          };

          dispatch(addProductBySku(data));
        }
      });

      setObsInterna(order?.ObservacaoInterna);
      setObsNF(order?.ObservacaoNota);
    }
  }, [order]);

  useEffect(() => {
    dispatch(clearNewOrder());
    setFrete({});
    setValores({});
    setValArquiteto({});
    setValorRt({});
    setOcultarSemEstoque(true);
    dispatch(ocultarRT(false));
    setNfIsOpen(false);
    setObsIsOpen(false);
    setCepCliente(null);
    dispatch(setPagtoSelected([]));
    setClient({});
    setSaldo(0);
    setItensCarrinho({});

    dispatch(setFilterPedido({}));
    setPedidoEmitido(false);
    setType("order");
    dispatch(getNewOrder("CODEMPRESA=01&CODFILIAL=02", history));
  }, []);

  const total = () => {
    var shipping = frete?.priceSomado ? frete?.priceSomado : frete.price || 0;
    const discount =
      discountValue?.typerulediscount == 1
        ? (parseFloat(valTot) / 100) * Number(discountValue?.discountValue)
        : discountValue?.discountValue;
    var totalDiscount = parseFloat(valTot) - parseFloat(discount || 0);

    return parseFloat(
      parseFloat(totalDiscount) +
      parseFloat(shipping) -
      parseFloat(saldo)
    );
  }

  useEffect(() => {
    if (emissaoPedido?.data?.success) {

      pushToDataLayer(
        type == 'budget' ? 'solicitar_orcamento' : 'purchase',
        {
          ecommerce: {
            currency: 'BRL',
            value: total(),
            transaction_id: emissaoPedido.data.CODPEDIDO,
            shipping: emissaoPedido.data.FRETE,
            vendedor1: emissaoPedido.data.VENDEDOR1,
            vendedor2: emissaoPedido.data.VENDEDOR2,
            vendedor3: emissaoPedido.data.VENDEDOR3,
            items: newOrder?.addedItems.map(product => ({
              item_id: product.produto.CODPRODUTO,
              item_name: product.produto.DESCRICAO,
              item_brand: product.produto.MARCA,
              item_category: product.produto.CATEGORIA,
              price: itensCarrinho[product.produto.CODPRODUTO]?.PRCRT,
              quantity: itensCarrinho[product.produto.CODPRODUTO]?.AMOUNT
            }))
          }
        },
        { clearEcommerce: true }
      );

      toast.success('Pedido editado com sucesso.');
      history.push(`/pedidos/${id}/${codFilial}/order`);
    }
  }, [emissaoPedido]);

  useEffect(() => {
    if (newOrder.orderProducts?.produtos?.length > 0) {
      setPaginatedProducts(
        paginate(ocultarSemEstoque, newOrder.orderProducts.produtos, 10)
      );
    }
  }, [newOrder.orderProducts?.produtos, ocultarSemEstoque]);

  useEffect(() => {
    getBranchWithGreaterQuantityOfMoreProducts(addedItems);
  }, [addedItems, itensCarrinho]);

  const getBranchWithGreaterQuantityOfMoreProducts = (items) => {
    items.forEach((product) => {
      let branchesUsed = [];
      const quantityRequired = itensCarrinho[product.produto.CODPRODUTO]?.AMOUNT || 1;

      const orderBranch = product.produto.PRDDISPS.STRETPRDFILADIAS.filter(estoque => estoque.CODFILPRD == codFilial);
      branchesUsed.push({
        branchId: orderBranch[0]?.CODFILPRD,
        deliveryDays: orderBranch[0]?.PRAZOCALC,
        quantityUsed: quantityRequired,
      });

      product.produto.branchesUsed = branchesUsed;
    });
  };

  useEffect(() => {
    if (orderActiveError) {
      return history.push("/dashboard");
    }
  }, [orderActiveError]);

  useEffect(() => {
    if (valorRt) {
      setPercentResumo((valRt / valTot) * 100)
    } else {
      setPercentResumo(0);
    }
  }, [valorRt]);

  function canOrCanNot() {
    var profileTrueOrFalse = false;
    userProfile.claims.groups.map(value => {
      if (value === 8 || value === 9 || (value >= 14 && value <= 20)) {
        setIsRevenda((profileTrueOrFalse = true));
      }
    });
  }

  useEffect(() => {
    if (userProfile?.claims?.groups) {
      canOrCanNot();
    }
  }, [userProfile?.claims?.groups]);

  useEffect(() => {
    if (
      newOrder?.orderProducts?.success &&
      newOrder?.orderProducts?.produtos.length < 1
    ) {
      toast.warning("Nenhum produto encontrado com os filtros atuais", {
        theme: "colored",
      });
    }
  }, [newOrder?.orderProducts]);

  const pedido = {
    client: client,
    type_order: "order",
    product_type: "",
    budget_infos: {
      codigo_cliente: 0,
      tipo_cliente: 0,
    },
    items: retornaItems(),
    itemsValTot: valTot,
    shipping: {
      id: frete?.id || "",
      shipping_selected: {
        delivery_delay: frete?.delivery_delay || "",
        id: frete?.id || "",
        price: frete?.price || "",
        description: frete?.description || "",
        price_percent: frete?.price_percent || "",
        usado_percentual: frete?.usado_percentual || "",
        frete_gratis: frete?.frete_gratis || "",
      },
      price: frete?.price || "",
    },
    filters: filterPedido,
    services: {
      product_type: "",
      ambientes: [],
    },
    discountcashback: saldo,
    ...discountValue,
    CODEMPRESA: "01",
    CODFILIAL: order?.CodFilial,
  };

  function retornaItems() {
    let items = [];

    newOrder.addedItems.map(value => {
      function getEstoques(es) {
        let result =
          value.produto.PRDDISPS?.STRETPRDFILADIAS?.find(
            i => i.CODFILPRD === es
          ) || "";
        return result?.ESTOQUE || 0;
      }
      let produto = {
        ...value.produto,
        PRCTAB: value.valorTabela,
        ESTOQUE_CD01: getEstoques("01"),
        ESTOQUE_CD02: getEstoques("02"),
        ESTOQUE_CD03: getEstoques("03"),
        ESTOQUE_CD04: getEstoques("04"),
        PRCRT: value.precoVenda || 0,
        precos: {
          cd:
            value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.CODFILPRD ||
            "",
          preco: value.precoVenda,
          diferente: "N",
        },
        PRECODIFER:
          value.produto.PRCVENDA?.STRETPRCFILADIAS[value.filial]?.PRECODIFER ||
          "",
        ESTOQUE:
          value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.ESTOQUE || "",
        instalacao: false,
        VALSERV: sendValServ ? valServ : 0,
        ...itensCarrinho[value.produto.CODPRODUTO],
      };

      items.push(produto);
    });

    return items;
  }

  function Somar(array) {
    var total = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i] >= 2) {
        total += array[i];
      }
    }
    return total;
  }

  function paginate(ocultarSemEstoque, arr, size) {
    var newArray = [];
    if (ocultarSemEstoque) {
      hasEstoque(arr, newArray);
    } else {
      newArray = arr;
    }
    setCurrentPage(0);
    setDataProducts(newArray);

    if (newArray?.length <= 0) {
      return 0;
    }

    // Ordenar pelo menor valor
    var newArraySort = newArray.sort(
      (a, b) =>
        a.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA -
        b.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA
    );

    return newArraySort.reduce((acc, val, i) => {
      let idx = Math.floor(i / size);
      let page = acc[idx] || (acc[idx] = []);
      page.push(val);
      return acc;
    }, []);
  }
  const semEstoque = isRevenda ? 1 : 0;
  function hasEstoque(arr, newArray) {
    for (let prop1 in arr) {
      var arrayEstoque = [];
      for (var items in arr[prop1].PRDDISPS.STRETPRDFILADIAS) {
        if (arr[prop1].PRDDISPS.STRETPRDFILADIAS[items].ESTOQUE <= semEstoque) {
          arrayEstoque.push(true);
        } else {
          arrayEstoque.push(false);
        }
      }

      if (arrayEstoque.includes(false)) {
        newArray.push(arr[prop1]);
      }
    }
  }

  const addValTot = valor => {
    setValores(valor);
  };

  const addValArqu = valor => {
    setValArquiteto(valor);
  };

  const addValorRt = valor => {
    setValorRt(valor);
  };

  useEffect(() => {
    if (frete?.id) {
      pushToDataLayer(
        'add_shipping_info',
        {
          ecommerce: {
            currency: 'BRL',
            value: total(),
            selected_shipping: frete,
            items: newOrder?.addedItems.map(product => ({
              item_id: product.produto.CODPRODUTO,
              item_name: product.produto.DESCRICAO,
              item_brand: product.produto.MARCA,
              item_category: product.produto.CATEGORIA,
              price: itensCarrinho[product.produto.CODPRODUTO]?.PRCRT,
              quantity: itensCarrinho[product.produto.CODPRODUTO]?.AMOUNT
            }))
          }
        },
        { clearEcommerce: true }
      );
    }
  }, [frete]);

  const addFrete = valor => {
    var objetosFiltrados = [];
    setFrete(valor);

    if (freteRedux.arrayShipping) {
      freteRedux.arrayShipping.forEach(function (objetoPai) {
        objetoPai.forEach(function (objetoFilho) {
          if (objetoFilho.id === valor.id) {
            objetosFiltrados.push(objetoFilho);
          }
        });
      });

      setArrayShippingFilialSelect(objetosFiltrados);
    }
  };

  const changeType = type => {
    setType(type);
  };

  const changeCepCliente = cep => {
    setCepCliente(cep);
  };

  const changeSaldo = saldo => {
    setSaldo(Number(saldo));
  };

  const changeClient = client => {
    setClient(prev => ({ ...prev, ...client }));
  };

  const changeItensCarrinho = itens => {
    setItensCarrinho(itens);
  };

  const changePedidoEmitido = () => {
    setPedidoEmitido(!pedidoEmitido);
  };

  const [qtdAdd, setQtdAdd] = useState(1);

  let newAddItemsSeparadoPorFilial = organizandoObjeto(pedido.items);

  function organizandoObjeto() {
    const uniqueUsedStocks = Array.from(
      pedido.items.reduce((acc, item) => {
        item.branchesUsed?.forEach(stock => acc.add(stock.branchId));
        return acc;
      }, new Set())
    ).map(branchId => {
      return { branchId };
    });

    const itemsSeparatedByBranch = [];

    uniqueUsedStocks.forEach(stock => {
      let itemsForThisStock = []

      let totalValue = 0;

      pedido.items.forEach(item => {
        const branch = item.branchesUsed?.find(branch => branch.branchId === stock.branchId);

        if (branch) {
          const itemCopy = { ...item };

          itemCopy.AMOUNT = branch.quantityUsed;
          itemCopy.CODFILIAL = branch.branchId;
          totalValue += itemCopy.PRCRT * branch.quantityUsed;
          itemsForThisStock.push(itemCopy);
        }
      });

      itemsSeparatedByBranch.push({
        CODFILIAL: stock.branchId,
        valTotal: totalValue,
        data: itemsForThisStock
      });
    });

    return itemsSeparatedByBranch;
  }

  function removeArraysVazios(array) {
    return array.filter(function (item) {
      return Array.isArray(item) && item.length > 0;
    });
  }

  const ufValidate = pedido?.client?.customer_address
    ? pedido.client.customer_address.length > 1
      ? pedido.client.customer_address[1].state
      : pedido.client.customer_address[0].state
    : "";

  return {
    newOrder,
    loading,
    order,
    addedItems,
    emissaoPedido,
    loadingProspect,

    filterPedido,

    pagamento,
    setPagamento,
    setAwaitModfyCart,
    pedido,
    ufValidate,
    paginatedProducts,
    ocultarSemEstoque,
    setOcultarSemEstoque,
    currentPage,
    setCurrentPage,
    isRevenda,
    dataProducts,
    setValServ,
    setSendValServ,
    addValTot,
    valores,
    setItensCarrinho,
    itensCarrinho,
    valArquiteto,
    addValArqu,
    valorRt,
    vendedorComRt,
    addValorRt,
    setPercentResumo,
    setQtdAdd,
    valTot,
    changeCepCliente,

    changeClient,
    client,
    formVisible,
    setFormVisible,
    errosDataProspectAndPartner,
    setErrosDataProspectAndPartner,
    setDiscountValue,
    changeSaldo,
    obsIsOpen,
    setObsIsOpen,
    setClient,
    nfIsOpen,
    setNfIsOpen,
    addFrete,
    cepCliente,
    newAddItemsSeparadoPorFilial,
    awaitModfyCart,
    valRt,
    frete,
    saldo,
    changePedidoEmitido,
    changeType,
    setLoadingProspect,
    percentResumo,
    qtdAdd,
    discountValue,
    arrayShippingFilialSelect,
    type,
    id,
  };
};

export default useLogic;
