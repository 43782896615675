import {
  FIREBASE_REMOTE_CONFIG_SUCCESS,
  GET_FIREBASE_REMOTE_CONFIG_USER_RESTRICT_PAGE,
  GET_FIREBASE_REMOTE_CONFIG_METHOD_LOGIN,
  GET_FIREBASE_REMOTE_CONFIG_FABRICANTES_INVERSORES,
  GET_FIREBASE_REMOTE_CONFIG_SHARED_RESALES_PERMISSION,
  GET_FIREBASE_REMOTE_CONFIG_CONDICAO_PAGAMENTO_SOLAR
} from "./actionTypes";

const initialState = {
  social_login: "",
  user_restric_page: "",
  fabricantes_inversores: [],
  sharedResalesPermission: [],
  condPagamentoSolar: []
};

const firebaseRemoteCofing = (state = initialState, action) => {
  switch (action.type) {
    case FIREBASE_REMOTE_CONFIG_SUCCESS:
      switch (action.payload.actionType) {
        case GET_FIREBASE_REMOTE_CONFIG_METHOD_LOGIN:
          return { ...state, social_login: action.payload.data };

        case GET_FIREBASE_REMOTE_CONFIG_USER_RESTRICT_PAGE:
          return { ...state, user_restric_page: action.payload.data };

        case GET_FIREBASE_REMOTE_CONFIG_FABRICANTES_INVERSORES:
          return { ...state, fabricantes_inversores: action.payload.data };

        case GET_FIREBASE_REMOTE_CONFIG_SHARED_RESALES_PERMISSION:
          return { ...state, sharedResalesPermission: action.payload.data };

        case GET_FIREBASE_REMOTE_CONFIG_CONDICAO_PAGAMENTO_SOLAR:
          return { ...state, condPagamentoSolar: action.payload.data };

        default:
          return state;
      }
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default firebaseRemoteCofing;
