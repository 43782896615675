export const mockTabelaPrecos = [
  { id: 1, name: "(M) TABELA AGOSTO (CÓPIA)" },
  { id: 2, name: "(M) TABELA AGOSTO" },
];

export const mockMicroOrInv = [
  { id: 1, name: "INVERSOR ON GRID", type: "INVN", remoteConfigName: "INVERSORONGRID" },
  { id: 2, name: "INVERSOR OFF GRID", type: "INVF", remoteConfigName: "INVERSOROFFGRID" },
  { id: 3, name: "INVERSOR HÍBRIDO", type: "INVH", remoteConfigName: "INVERSORHIBRIDO" },
  { id: 4, name: "MICRO INVERSOR", type: "MICN ", remoteConfigName: "MICROINVERSOR" },
];

export const mockPotencia = [{ id: 1, name: "POTÊNCIA" }];

export const mockFase = [
  { id: 1, name: "MONOFÁSICO", value: "monofasico" },
  { id: 3, name: "BIFÁSICO", value: "bifasico" },
  { id: 3, name: "TRIFÁSICO", value: "trifasico" },
];

export const mockFaseMicroInversor = [
  { id: 1, name: "MONOFÁSICO", value: "monofasico" },
  { id: 2, name: "BIFÁSICO", value: "bifasico" },
  { id: 3, name: "TRIFÁSICO", value: "trifasico" },
];

export const mockTrifasico = [
  { id: 1, name: "220", value: "220" },
  { id: 2, name: "380", value: "380" },
  { id: 3, name: "800", value: "800" },
];
export const mockBifasico = [
  { id: 1, name: "220/127", value: "220" },
  { id: 2, name: "380/220", value: "380" },
];

export const mockMonofasico = [{ id: 1, name: "220", value: "220" }];

export const mockMonofasicoMicroInversor = [
  { id: 1, name: "220", value: "220" },
];