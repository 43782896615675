import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import {
  addToCart,
  clearNewOrder,
  getNewOrder,
  removeStatus,
  zerarCarrinho,
} from "../../../store/actions";
import "./newOrder.css";

import { dateNow } from "../../../utils";
import ControleRemoto from "./ControleRemoto";
import Frete from "./Frete";
import RegisterUser from "./RegisterUser";
import Resumo from "./Resumo";
import { toast } from "react-toastify";

import Cart from "./Cart";

import ModalAlert from "../../components/modal";
import Status from "../../../components/StatusCar";
import { useProfile } from "../../../hooks";
import { useParams } from "react-router-dom";
import {
  mockFase,
  mockMicroOrInv,
  mockPotencia,
  mockTabelaPrecos,
} from "./FormModule/constants/mock";
import { apiClient } from "../sunbeam-at-your-address/helpers/importer.helper";
import Seguro from "../kit-pronto/Seguro";
import { isInverter } from "../../utils/functions/solarUtils";
import { useDataLayer } from "../../../hooks/DataLayerHooks";
import { isAllowsChange } from "../../../utils/orderUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ModalEditCustomKit = ({ onEditedOrder, setIsOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { newOrder, loading, order, emissaoPedido, status } = useSelector(
    state => {
      return {
        newOrder: state.Order.newOrder.newOrder,
        loading: state.Order.loading,
        order: state.Order.orderActive.order,
        emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
        status: state.KitsNew.status,
      };
    }
  );
  const { pushToDataLayer } = useDataLayer();
  const { id } = useParams();

  const [frete, setFrete] = useState({});
  const [valores, setValores] = useState({});
  const [valorRt, setValorRt] = useState({});
  const [valCashbackNoRetention, setValCashbackNoRetention] = useState({});
  const [valArquiteto, setValArquiteto] = useState({});
  const [discountValue, setDiscountValue] = useState();

  const INSURANCES_NAME = {
    SEGURO_ENG: 'seguroEng',
    SEGURO_RD: 'seguroRd',
  };

  const [ocultarRt, setOcultarRt] = useState(false);
  const [cepCliente, setCepCliente] = useState(null);
  const [pagtoSelected, setPagtoSelected] = useState([]);
  const [client, setClient] = useState({});
  const [saldo, setSaldo] = useState(0);
  const [itensCarrinho, setItensCarrinho] = useState({});
  const [filterPedido, setFilterPedido] = useState({
    Nomecondpagto: "BOLETO A VISTA SOLAR",
    Nometabela_preco: "SOLAR",
    condpagto: "885",
    tabela_preco: "043",
    vendedor2: "",
  });
  const [previsao, setPrevisao] = useState(null);
  const [mockTensaoRede, setMockTensaoRede] = useState([
    { id: 1, name: "220", value: "220" },
  ]);
  const [realPower, setRealPower] = useState(0);
  const [data, setData] = useState({
    tabelaPreco: [mockTabelaPrecos[0]],
    fabInversor: "",
    potencia: [mockPotencia[0]],
    fase: mockFase[0].value,
    tensaoRede: mockTensaoRede[0].value,
    tipoFix: [{ id: 0, name: "" }],
    consumoAtender: 100,
    microOrInv: mockMicroOrInv[0],
    transformer: "nao",
    modLinha: "8",
    potenciaKwp: 0,
  });

  const [pedidoEmitido, setPedidoEmitido] = useState(false);
  const [type, setType] = useState("order");

  const [, setObsInterna] = useState("");
  const [, setObsNF] = useState("");

  const [percentWattpay, setPercentWattpay] = useState();

  const [sendValServ, setSendValServ] = useState(false);
  const [valServ, setValServ] = useState(0);

  const [formVisible, setFormVisible] = useState(
    {
      prospect: false,
      client: true,
      partner: false,
    }
  );
  const [loadingProspect, setLoadingProspect] = useState(false);

  const [parceiros, setParceiros] = useState([]);

  const [errosDataProspectAndPartner, setErrosDataProspectAndPartner] =
    useState({
      name: "",
      email: "",
      ddd: "",
      tel: "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      state: "",
      city: "",
      district: "",
    });

  const valTot = Somar(Object.values(valores));
  const valRt = Somar(Object.values(valorRt));
  const valRtNoRetention = Somar(Object.values(valCashbackNoRetention));

  const [valTotAndRt, setValTotAndRt] = useState({});

  const [cepSelected, setCepSelected] = useState(1000);
  const [getTheHighestForecastDate, setGetTheHighestForecastDate] =
    useState(null);

  const [modules, setModules] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [percentCashBackAll, setPercentCashBackAll] = useState("0");
  const [percentCashBackAllOnBlur, setPercentCashBackAllOnBlur] =
    useState(false);
  const [acrescimo, setAcrescimo] = useState(0);

  const [termOfAgreement, setTermOfAgreement] = useState({
    confirm: false,
    date: "",
    hour: "",
  });
  const [dataEstoque, setDataEstoque] = useState({});
  const [futureSaleValue, setFutureSaleValue] = useState(false);
  const [seguro, setSeguro] = useState([
    {
      type: INSURANCES_NAME.SEGURO_ENG,
      name: "",
      percent: 0.5,
      valor: 0,
      checked: false,
    },
    { type: INSURANCES_NAME.SEGURO_RD, name: "", percent: 1, valor: 0, checked: false },
  ]);

  const [waitingToChangeCartItems] =
    useState(false);

  var estoquesObj = [
    { uf: "PB", estoque: "IPOJUCA", codFilial: "06" },
    { uf: "PR", estoque: "CASCAVEL", codFilial: "02" },
    { uf: "ES", estoque: "VITORIA", codFilial: "05" },
    { uf: "SP", estoque: "SAO PAULO", codFilial: "01" },
  ];

  const filterCheckedSeguro = seguro?.filter(item => item.checked == true);
  const totalSeguro = filterCheckedSeguro?.reduce(
    (total, valor) => total + valor?.valor,
    0
  ) ?? 0;

  var total = newOrder.addedItems.reduce(getTotal, 0) + valRtNoRetention;

  var isEstoqueFilialNumber = estoquesObj?.filter(
    item => item.uf == dataEstoque?.uf
  );

  var isMicroInverterSelected = data?.microOrInv?.name?.includes("MICRO")
    ? "01"
    : isEstoqueFilialNumber[0]?.codFilial || "01";

  function getTotal(total, item) {
    return total + item.produto[0].PRCRT;
  }

  useEffect(() => {
    toast.warning(
      "Atenção: valores de equipamentos e frete poderão ser alterados.",
      { theme: "colored" }
    );
  }, [id]);

  useEffect(() => {
    if (parceiros.length > 0) {
      setFilterPedido(state => {
        return { ...state, vendedor2: parceiros[0].codigo };
      });
    }
  }, [parceiros]);

  //verificar se o retorno da requisição getNewOrder é solar
  const isSolarRequestNewOrder = () => {
    var requestIsSolar = newOrder.tabelaInicial == "043";
    if (!requestIsSolar) {
      toast.warning(
        "ATENÇÃO! Tabela diferente da solar! Saia e entre novamente",
        {
          theme: "colored",
        }
      );
    }
  };

  useEffect(() => {
    if (newOrder?.tabelaInicial) {
      isSolarRequestNewOrder();
    }
  }, [newOrder.tabelaInicial]);

  useEffect(() => {
    var obj = {};

    /* ao digitar o cep busca o estoque por padrao em SP*/
    obj = estoquesObj[3];

    setDataEstoque(obj);
  }, [dispatch]);

  useEffect(() => {
    if (order) {
      if (order.Situacao > 'D') {
        toast.error("A edição de pedidos é permitida apenas para pedidos não faturados.");
        history.push("/dashboard");
      }

      (order?.new_items_orcamento || order?.items_orcamento || []).map(value => {
        if (!isAllowsChange(order?.items_orcamento[0]?.CTPOPER)) {
          toast.warning("Este pedido não possui uma TES para alteração.");
          setIsOpen(false);
        }

        if (!newOrder.addedItems.find(i => i.produto[0].CODPRODUTO == (value.sku || value.Produto))) {
          dispatch(addToCart({ produto: [value] }));
        }
      });

      if (order.C5_XSEGID) {
        const engInsuranceCode = '01';
        const rdInsuranceCode = '02';
        const codeForAllInsurance = '03';

        let newSeguro = seguro;

        if (order.C5_XSEGID.trim() === engInsuranceCode || order.C5_XSEGID.trim() === codeForAllInsurance) {
          newSeguro = newSeguro.map(item =>
            item.type === INSURANCES_NAME.SEGURO_ENG
              ? { ...item, checked: true }
              : item
          );
        }

        if (order.C5_XSEGID.trim() === rdInsuranceCode || order.C5_XSEGID.trim() === codeForAllInsurance) {
          newSeguro = newSeguro.map(item =>
            item.type === INSURANCES_NAME.SEGURO_RD
              ? { ...item, checked: true }
              : item
          );
        }

        setSeguro(newSeguro);
      }

      setObsInterna(order?.ObservacaoInterna);
      setObsNF(order?.ObservacaoNota);
    }
  }, [order]);

  useEffect(() => {
    dispatch(clearNewOrder());
    dispatch(removeStatus());
    setFrete({});
    setValArquiteto({});
    setValores({});
    setValorRt({});
    setValCashbackNoRetention({});
    setOcultarRt(false);
    setFutureSaleValue(false);
    setCepCliente(null);
    setPagtoSelected([]);
    setClient({});
    setSaldo(0);
    setItensCarrinho({});
    /*   setFilterPedido({}); */
    setPedidoEmitido(false);
    setType("order");
    dispatch(getNewOrder("CODEMPRESA=02&CODFILIAL=02", history));
  }, []);

  const [itHasForecast, setItHasForecast] = useState([]);

  useEffect(() => {
    setItHasForecast(newOrder.addedItems.filter(item => {
      const produto = item?.produto[0];

      const isInverter = produto?.nome?.includes("INV");

      const minimumStock = (isInverter ? 10 : 1000) + produto?.estoqueFuturo;

      return (
        (produto?.previsao && produto?.qtdEstoque < produto?.estoqueFuturo) ||
        (produto?.previsao &&
          produto?.leadTime &&
          produto?.qtdEstoque < minimumStock)
      );
    }));
  }, [newOrder.addedItems]);

  const pedido = {
    client,
    type_order: "order",
    product_type: "",
    ruralShipping: false,
    riverShipping: false,
    budget_infos: {
      codigo_cliente: 0,
      tipo_cliente: 0,
    },
    items: retornaItems(),
    itemsValTot: valTot,
    shipping: {
      id: frete?.id || "",
      shipping_selected: {
        delivery_delay: frete?.delivery_delay || "",
        id: frete?.id || "",
        price: frete?.price || "",
        description: frete?.description || "",
        price_percent: frete?.price_percent || "",
        usado_percentual: frete?.usado_percentual || "",
        frete_gratis: frete?.frete_gratis || "",
      },
      price: frete?.price || "",
    },
    filters: filterPedido,
    services: {
      product_type: "",
      ambientes: [],
    },
    discountcashback: saldo,
    ...discountValue,
    CODEMPRESA: "02",
    CODFILIAL: isMicroInverterSelected,
    termOfAgreement: termOfAgreement,
    status: status,
    futureSale: futureSaleValue,
    seguro,

    typeOfKit: "kit-personalizado-normal",
  };

  function retornaItems() {
    let items = [];
    let skuGeneric =
      data.potenciaKwp < 75
        ? "PRKTSOL00000001"
        : data.potenciaKwp > 75 && data.potenciaKwp < 375
          ? "PRKTSOL00000002"
          : "PRKTSOL00000003";

    newOrder.addedItems.map(value => {
      var arraySubTotal = [];

      arraySubTotal.push({ [skuGeneric]: value.produto[0].subTotal });
      function getEstoques(es) {
        let result =
          value.produto.PRDDISPS?.STRETPRDFILADIAS?.find(
            i => i.CODFILPRD === es
          ) || "";
        return result?.ESTOQUE || 0;
      }

      let produto = {
        ...value.produto[0],
        ALIMENTACAO: "",
        ALTURA: "",
        KWP: 2.03,
        ARMAZEM: "",
        BITOLALIQ: "",
        BITOLASUC: "",
        CAPACIDADE: "05",
        CATEGORIA: "405",
        CCONDENSA: "",
        CEVAPORA: "",
        CICLO: "1",
        CODEAN: "",
        CODEMPRESA: "02",
        CODFILIAL: "02",
        CODLEGADO: "",
        CODPRODUTO: value?.produto[0]?.sku ? value.produto[0].sku : skuGeneric,
        CONDPAGTO: "",
        COR: "X",
        CORRENTE: 0,
        DESCRICAO: value.produto[0].nome,
        DESCRICAO_COMPLETA:
          "QTDE MODULO: 05\nMÓDULO: MONO-PERC RISEN 405W (F:30 MM)\nNÚMERO DE CÉLULAS: 120 MONO HALF CELL\nMEDIDAS MOD: 1754 X 1096 X 30 mm\n\n \nQTDE INVERSOR 1: 1\nINVERSOR 1: SOFAR 1600TL-G3\nFASE/TENSÃO: MONOFÁSICO 220V\nMPPT: 1\nQTDE STRINGBOX 1: 1\nSTRINGBOX 1: STRINGBOX SB07A - 1E/1S SECCIONADORA NO INVERSOR\n\n \nQTDE PAR CONECTOR MC4: 2\n\n \nQTDE CABO 6MM-1800V VERMELHO (METROS): 15\nQTDE CABO 6MM-1800V PRETO (METROS): 15\n\n \nQTDE ESTRUTURA 1: 10\nESTRUTURA 1: KIT SUPORTE PARA TELHADO COLONIAL\nQTDE ESTRUTURA 2: 4\nESTRUTURA 2: KIT TERMINAL FINAL 35MM\nQTDE ESTRUTURA 3: 6\nESTRUTURA 3: KIT TERMINAL INTERMEDIÁRIO 39/44MM - PAR\nQTDE ESTRUTURA 4: 4\nESTRUTURA 4: PERFIL DE ALUMÍNIO 3.40M\nQTDE ESTRUTURA 5: 4\nESTRUTURA 5: KIT DE EMENDAS E PARAFUSOS INOX 8X12",
        FASE: "2",
        GARANTEX: 0,
        GARANTIA: 0,
        GRUPO: "INO",
        LARGURA: 0,
        MARCA: "",
        NCM: "",
        ORIGEM: "",
        PESOBRUTO: 0,
        PESOLIQUI: 0,
        PRDDISPS: {
          STRETPRDFILADIAS: [
            {
              CODFILPRD: "02",
              ESTOQUE: 1,
            },
          ],
        },
        PREVISAOC7: "",
        PROFUNDIDADE: 0,
        RUIDO: 0,
        SELOA: "",
        TABELAPRC: "",
        TIPOPROD: "",
        UNIDADEMED: "",
        VOLUME: 0,
        ITEM: "",
        AMOUNT: value.produto[0].qtd ? value.produto[0].qtd : 1,
        VALORRT: 0,
        PRCRT: value.produto[0].PRCRT,
        subtotal: arraySubTotal,
        maskPrcVenda: value.produto[0].precoCliente,
        PERCENT: 0,
        PRCVENDA: value.produto[0].precoCliente,
        ESTOQUE_CD01: getEstoques("01"),
        ESTOQUE_CD02: getEstoques("02"),
        ESTOQUE_CD03: getEstoques("03"),
        ESTOQUE_CD04: getEstoques("04"),
        /* previsao: previsao, */
        precos: {
          cd:
            value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.CODFILPRD ||
            "",
          preco: value.precoVenda,
          diferente: "N",
        },
        PRECODIFER: value.produto[0].precoCliente || "",
        ESTOQUE:
          value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.ESTOQUE || "",
        instalacao: false,
        VALSERV: sendValServ ? valServ : 0,
        previsao: itHasForecast.some(item => item.produto[0].sku == value.produto[0].sku) ? value.produto[0].previsao : null,
        ...itensCarrinho[value.produto[0].nome],
      };

      items.push(produto);
    });

    return items;
  }

  const showInvalidKitToast = () => {
    toast.info(
      "Entre em contato com o comercial para realizar a compra de itens fora do Kit. Os valores podem sofrer alterações",
      { theme: "colored" }
    );
  }


  const totalModulesPotency = () => {
    const filterModules = newOrder.addedItems.filter((item, k) =>
      item?.produto[0]?.nome?.includes("MOD.")
        ? item?.produto[0]?.nome?.includes("MOD.")
        : item?.produto[0]?.nome?.includes("Modulos")
          ? item?.produto[0]?.nome?.includes("Modulos")
          : item?.produto[0]?.nome?.includes("MOD FOTOV")
            ? item?.produto[0]?.nome?.includes("MOD FOTOV")
            : item?.produto[0]?.nome?.includes("MOD. FOTOV")
              ? item?.produto[0]?.nome?.includes("MOD. FOTOV")
              : item?.produto[0]?.nome?.includes("GCL")
    );
    return filterModules.reduce(
      (total, valor) =>
        total + valor?.produto[0]?.potencia * valor?.produto[0]?.qtd,
      0
    );
  }

  const totalPotency = () => {
    const filterInverters = newOrder.addedItems.filter((item, k) =>
      isInverter(item?.produto[0])
    );

    return filterInverters.reduce(
      (total, valor) =>
        total +
        valor?.produto[0]?.potencia *
        (valor?.produto[0]?.qtd ? valor.produto[0].qtd : 1),
      0
    );
  };

  const totalPercentPotency = () => {
    const percent = 30;
    return (percent * totalPotency()) / 100;
  }

  const checkIfItsOutOfKit = () => {
    return Number(totalPercentPotency()) > Number(totalModulesPotency());
  };

  const isInvalidKit = () => {
    return totalPercentPotency() <= 0 || totalModulesPotency() <= 0 || checkIfItsOutOfKit();
  }

  useEffect(() => {
    if (checkIfItsOutOfKit()) {
      showInvalidKitToast();
    };
  }, [newOrder.addedItems, valores]);

  function Somar(array) {
    var total = 0;
    for (var i = 0; i < array.length; i++) {
      total += array[i];
    }
    return total;
  }

  const addValTot = valor => {
    setValores(valor);
  };

  const totalValue = () => {
    const discount =
      discountValue?.typerulediscount == 1
        ? (parseFloat(total) / 100) * Number(discountValue?.discountValue)
        : discountValue?.discountValue;

    var totalDiscount = parseFloat(total) - parseFloat(discount || 0);

    return parseFloat(
      parseFloat(totalDiscount) +
      parseFloat(totalSeguro) +
      parseFloat(frete?.price || 0) -
      parseFloat(saldo)
    );
  };

  useEffect(() => {
    if (frete?.id) {
      pushToDataLayer(
        'add_shipping_info',
        {
          ecommerce: {
            currency: 'BRL',
            value: totalValue(),
            selectedShipping: frete,
            items: newOrder?.addedItems.map(product => {
              const produto = Array.isArray(product.produto) ? product.produto[0] : product.produto;
              return {
                item_id: produto.sku || '',
                item_name: produto.nome || '',
                price: produto.PRCRT || 0,
                quantity: produto.qtd || 0
              }
            })
          }
        },
        { clearEcommerce: true }
      );
    }
  }, [frete]);

  const addFrete = valor => {
    setFrete(valor);
  };

  const changeType = type => {
    setType(type);
  };

  const changeCepCliente = cep => {
    setCepCliente(cep);
  };
  const addValorRt = valor => {
    setValorRt(prevState => {
      return {
        ...prevState,
        [valor.nome]: valor.valCashBack,
      };
    });
  };

  const addValorRtNoRetention = valor => {
    setValCashbackNoRetention(prevState => {
      return {
        ...prevState,
        [valor.nome]: valor.valCashBackNoRentention,
      };
    });
  };

  const addValArqu = valor => {
    setValArquiteto(valor);
  };

  const changeClient = client => {
    setClient(prev => ({ ...prev, ...client }));
  };

  const changePedidoEmitido = () => {
    setPedidoEmitido(!pedidoEmitido);
  };

  useEffect(() => {
    if (emissaoPedido?.data?.success) {
      onEditedOrder();
    }
  }, [emissaoPedido]);

  const renderView = () => {
    return (
      <React.Fragment>
        <div></div>
        {loadingProspect && (
          <div className="carregando-emissao">
            <Spinner />
          </div>
        )}

        {loading && (
          <div className="carregando-emissao">
            <Spinner />
          </div>
        )}

        <React.Fragment>
          <>
            <Row id="carrinho">
              <Cart
                addValTot={addValTot}
                valores={valores}
                setPercentWattpay={setPercentWattpay}
                valorRt={valorRt}
                addValorRt={addValorRt}
                valArquiteto={valArquiteto}
                ocultarRt={ocultarRt}
                addValArqu={addValArqu}
                setItensCarrinho={setItensCarrinho}
                itensCarrinho={itensCarrinho}
                percentCashBackAll={percentCashBackAll}
                setPercentCashBackAll={setPercentCashBackAll}
                setPercentCashBackAllOnBlur={setPercentCashBackAllOnBlur}
                percentCashBackAllOnBlur={percentCashBackAllOnBlur}
                realPower={realPower}
                setRealPower={setRealPower}
                setValTotAndRt={setValTotAndRt}
                valTotAndRt={valTotAndRt}
                acrescimo={acrescimo}
                addValorRtNoRetention={addValorRtNoRetention}
                total={total}
                isMicroInverterSelected={isMicroInverterSelected}
                itHasForecast={itHasForecast}
              />
            </Row>

            <Row id="sentinel" className="mt-4">
              <RegisterUser
                valTot={valTot}
                changeCepCliente={changeCepCliente}
                pagtoSelected={pagtoSelected}
                changeClient={changeClient}
                client={client}
                formVisible={formVisible}
                setFormVisible={setFormVisible}
                errosDataProspectAndPartner={errosDataProspectAndPartner}
                setErrosDataProspectAndPartner={
                  setErrosDataProspectAndPartner
                }
                parceiros={parceiros}
                setParceiros={setParceiros}
                setFrete={setFrete}
              />
            </Row>
            <Row className="d-flex justify-content-between" id="frete">
              <Col md={7}>
                <Frete
                  addFrete={addFrete}
                  cepCliente={cepCliente}
                  data={data}
                  dataEstoque={dataEstoque}
                  setPrevisao={setPrevisao}
                  waitingToChangeCartItems={waitingToChangeCartItems}
                  cepSelected={cepSelected}
                  setCepSelected={setCepSelected}
                  getTheHighestForecastDate={getTheHighestForecastDate}
                  setGetTheHighestForecastDate={
                    setGetTheHighestForecastDate
                  }
                  total={total}
                  seguro={totalSeguro}
                  isMicroInverterSelected={isMicroInverterSelected}
                  itHasForecast={itHasForecast}
                />
                <Seguro
                  seguro={seguro}
                  setSeguro={setSeguro}
                  pedido={pedido}
                />
                <Card style={{ padding: 20, minHeight: 226 }}>
                  <Status status={status} />
                </Card>
              </Col>
              <Col md={5}>
                <Resumo
                  valRt={valRt}
                  frete={frete}
                  saldo={saldo}
                  pedido={pedido}
                  changePedidoEmitido={changePedidoEmitido}
                  changeType={changeType}
                  formVisible={formVisible}
                  percent={percentWattpay}
                  setLoadingProspect={setLoadingProspect}
                  cepSelected={cepSelected}
                  getTheHighestForecastDate={getTheHighestForecastDate}
                  data={data}
                  total={total}
                  seguro={totalSeguro}
                  isMicroInverterSelected={isMicroInverterSelected}
                  discountValue={discountValue}
                  setFutureSaleValue={setFutureSaleValue}
                  isInvalidKit={isInvalidKit}
                />
              </Col>
            </Row>
            <ControleRemoto />
          </>
        </React.Fragment>
      </React.Fragment>
    );
  };

  const handleClickModalConfirm = () => {
    const date = dateNow();
    const hours = new Date().toLocaleTimeString();

    setTermOfAgreement({ confirm: true, date: date, hour: hours });

    setOpenModal(false);
  };

  return (
    <>
      <div className="page-content neworder solar">
        <Container>{renderView()}</Container>
      </div>
      <ModalAlert
        openModal={openModal}
        setOpenModal={setOpenModal}
        footer
        modalAlert
        header="Termo de Acordo"
        textButton="Estou ciente!"
        onClick={handleClickModalConfirm}
      >
        O dimensionamento assim como a escolha de seus componentes é de
        responsabilidade exclusiva da empresa integradora, e deve ser conduzida
        por corpo técnico responsável.
      </ModalAlert>
    </>
  );
};

export default ModalEditCustomKit;