import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Spinner,
  FormGroup,
  Input
} from "reactstrap";
import Breadcrumb from "../../../components/Breadcrumb";
import {
  addEndValido,
  checkCartRuleOnChange,
  clearNewOrder,
  removePromotionsAndCoupons,
  getNewOrder,
  getFilters,
  removeStatus,
  zerarCarrinho,
  addItemsToCart,
} from "../../../store/actions";
//import Component
import Banner from "./Banner";
import "./newOrder.css";

import { dateNow } from "../../../utils";
import ControleRemoto from "./ControleRemoto";
import Frete from "./Frete";
import PedidoEmitido from "./PedidoEmitido";
import RegisterUser from "./RegisterUser";
import Resumo from "./Resumo";
import Saldo from "./Saldo";
import PartnerCompany from "./PartnerCompany";
import CardModules from "../../components/card-modules";
import { FormModule } from "./FormModule";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";

import Cart from "./Cart";

import ModalAlert from "../../components/modal";
import Status from "../../../components/StatusCar";
import { Alert } from "@mui/material";
import { useProfile } from "../../../hooks";
import { useParams } from "react-router-dom";
import {
  mockPotencia,
  mockTabelaPrecos,
} from "./FormModule/constants/mock";
import { apiClient } from "../sunbeam-at-your-address/helpers/importer.helper";
import { consultaCep } from "../../../api";
import Toggle from "../../../components/Toggle";
import Seguro from "../kit-pronto/Seguro";
import { getAdressesFromZipCode } from "../../../api";
import Cupons from "../../../pages/NewOrders/Cupons";
import { useDataLayer } from "../../../hooks/DataLayerHooks";
import { isInverter } from "../../utils/functions/solarUtils";
import ShippingData from "./ShippingData";
/* import SelectSearch from "../../../components/SelectSerach";
 */
const SolarKitPersonalizado = ({ history }) => {
  const dispatch = useDispatch();
  const { newOrder, loading, order, emissaoPedido, status } = useSelector(
    state => {
      return {
        newOrder: state.Order.newOrder.newOrder,
        loading: state.Order.loading,
        order: state.Order.orderActive.order,
        emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
        status: state.KitsNew.status,
      };
    }
  );
  const { pushToDataLayer } = useDataLayer();
  const { userProfile } = useProfile();
  const { token } = useParams();
  const [frete, setFrete] = useState({});
  const [valores, setValores] = useState({});
  const [valorRt, setValorRt] = useState({});
  const [valCashbackNoRetention, setValCashbackNoRetention] = useState({});
  const [valArquiteto, setValArquiteto] = useState({});

  const [subTotalDiscount, setSubTotalDiscount] = useState(0.00);
  const [fullDiscount, setFullDiscount] = useState(0.00);
  const [valoresTabela, setValoresTabela] = useState({});

  const [ocultarSemEstoque, setOcultarSemEstoque] = useState(true);
  const [ocultarRt, setOcultarRt] = useState(false);
  const [nfIsOpen, setNfIsOpen] = useState(false);
  const [obsIsOpen, setObsIsOpen] = useState(false);
  const [cepCliente, setCepCliente] = useState(null);
  const [pagtoSelected, setPagtoSelected] = useState([]);
  const [client, setClient] = useState({});
  const [saldo, setSaldo] = useState(0);
  const [itensCarrinho, setItensCarrinho] = useState({});
  const [filterPedido, setFilterPedido] = useState({
    Nomecondpagto: "BOLETO A VISTA SOLAR",
    Nometabela_preco: "SOLAR",
    condpagto: "885",
    tabela_preco: "043",
    vendedor2: "",
  });
  const [previsao, setPrevisao] = useState(null);
  const [realPower, setRealPower] = useState(0);
  const [data, setData] = useState({
    tabelaPreco: [mockTabelaPrecos[0]],
    fabInversor: "",
    potencia: [mockPotencia[0]],
    fase: "",
    tensaoRede: "",
    tipoFix: [{ id: 0, name: "" }],
    consumoAtender: 100,
    microOrInv: { name: "INVERSOR ON GRID", type: "INVN" },
    transformer: "nao",
    modLinha: "8",
    potenciaKwp: 0,
  });

  const [pedidoEmitido, setPedidoEmitido] = useState(false);
  const [type, setType] = useState("order");

  const [, setObsInterna] = useState("");
  const [, setObsNF] = useState("");

  const [percentWattpay, setPercentWattpay] = useState();

  const [sendValServ, setSendValServ] = useState(false);
  const [valServ, setValServ] = useState(0);

  const [dataLeed, setDataLeed] = useState([]);

  const [formVisible, setFormVisible] = useState({
    prospect: true,
    client: false,
    partner: false,
  });
  const [loadingProspect, setLoadingProspect] = useState(false);

  const [parceiros, setParceiros] = useState([]);

  const [errosDataProspectAndPartner, setErrosDataProspectAndPartner] =
    useState({
      name: "",
      email: "",
      ddd: "",
      tel: "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      state: "",
      city: "",
      district: "",
    });

  const valTot = Somar(Object.values(valores));
  const valRt = Somar(Object.values(valorRt));
  const valRtNoRetention = Somar(Object.values(valCashbackNoRetention));

  const [valTotAndRt, setValTotAndRt] = useState({});

  const [cepSelected, setCepSelected] = useState(1000);
  const [getTheHighestForecastDate, setGetTheHighestForecastDate] =
    useState(null);

  const [modules, setModules] = useState([]);

  const getModulesSortedByPricePerPower = () => {
    if (modules) {
      return modules.sort((a, b) => (a.precoCliente / a.potencia) - (b.precoCliente / b.potencia));
    }

    return [];
  }

  const [moduleSelected, setModuleSelected] = useState({});
  const [loadingSearModule, setLoadingSearchModule] = useState(false);
  const [productsVisible, setProductsVisible] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [loadingModule, setLoadingModule] = useState(false);
  const [percentCashBackAll, setPercentCashBackAll] = useState("0");
  const [percentCashBackAllOnBlur, setPercentCashBackAllOnBlur] =
    useState(false);
  const [acrescimo, setAcrescimo] = useState(0);
  const [deliveryZipCode, setDeliveryZipCode] = useState("");
  const [deliveryZipCodeLoading, setDeliveryZipCodeLoading] = useState(false);
  const [termOfAgreement, setTermOfAgreement] = useState({
    confirm: false,
    date: "",
    hour: "",
  });
  const [dataEstoque, setDataEstoque] = useState({});
  const [changeFormOfPayment, setChangeFormOfPayment] = useState(false);
  const [getKitLoading, setGetKitLoading] = useState(false);
  const [futureSaleValue, setFutureSaleValue] = useState(false);
  const [customerDeliveryAddress, setCustomerDeliveryAddress] = useState({
    city: '',
    state: ''
  });
  const [ruralShippingValue, setRuralShippingValue] = useState(false);
  const [ruralOrRiverShippingValue, setRuralOrRiverShippingValue] = useState(false);
  const [riverShippingValue, setRiverShippingValue] = useState(false);
  const [triangularSale, setTriangularSale] = useState(false);
  const [shippingZipCode, setShippingZipCode] = useState("");
  const [shippingAddress, setShippingAddress] = useState({});
	const [shippingTotalValue, setShippingTotalValue] = useState("");
  const [seguro, setSeguro] = useState([
    {
      type: "seguroEng",
      name: "",
      percent: 0.5,
      valor: 0,
      checked: false,
    },
    { type: "seguroRd", name: "", percent: 1, valor: 0, checked: false },
  ]);
  const [selectedFiname, setSelectedFiname] = useState('');

  const [discounts, setDiscounts] = useState([]);
  const [activePromotionsAndCoupons, setActivePromotionsAndCoupons] = useState(true);

  //constante para evitar o looping de frete
  const [waitingToChangeCartItems, setWaitingToChangeCartItems] =
    useState(false);

  const dataRegions = [
    { sigla: "AC", estado: "ACRE", cidade: "RIO BRANCO", direcao: "NORTE" },
    { sigla: "PA", estado: "PARÁ", cidade: "BELÉM", direcao: "NORTE" },
    {
      sigla: "RO",
      estado: "RONDÔNIA",
      cidade: "PORTO VELHO",
      direcao: "NORTE",
    },
    { sigla: "RR", estado: "RORAIMA", cidade: "BOA VISTA", direcao: "NORTE" },
    { sigla: "TO", estado: "TOCANTINS", cidade: "PALMAS", direcao: "NORTE" },
    {
      sigla: "MA",
      estado: "MARANHÃO",
      cidade: "SÃO LUIZ",
      direcao: "NORDESTE",
    },
    {
      sigla: "PB",
      estado: "PARAÍBA",
      cidade: "JOÃO PESSOA",
      direcao: "NORDESTE",
    },
    {
      sigla: "PE",
      estado: "PERNAMBUCO",
      cidade: "RECIFE",
      direcao: "NORDESTE",
    },
    { sigla: "PI", estado: "PIAUÍ", cidade: "TEREZINA", direcao: "NORDESTE" },
    {
      sigla: "RN",
      estado: "RIO GRANDE DO NORTE",
      cidade: "NATAL",
      direcao: "NORDESTE",
    },
    { sigla: "SE", estado: "SERGIPE", cidade: "ARACAJÚ", direcao: "NORDESTE" },
    {
      sigla: "GO",
      estado: "GOIÁS",
      cidade: "GOIÂNIA",
      direcao: "CENTRO-OESTE",
    },
    {
      sigla: "MS",
      estado: "MATO GROSSO DO SUL",
      cidade: "CAMPO GRANDE",
      direcao: "CENTRO-OESTE",
    },
    {
      sigla: "MT",
      estado: "MATO GROSSO ",
      cidade: "CUIABÁ",
      direcao: "CENTRO-OESTE",
    },
    {
      sigla: "ES",
      estado: "ESPÍRITO SANTO",
      cidade: "VITÓRIA",
      direcao: "SUDESTE",
    },
    {
      sigla: "MG",
      estado: "MINAS GERAIS",
      cidade: "BELO HORIZONTE",
      direcao: "SUDESTE",
    },
    {
      sigla: "RJ",
      estado: "RIO DE JANEIRO",
      cidade: "RIO DE JANEIRO",
      direcao: "SUDESTE",
    },
    {
      sigla: "SP",
      estado: "SÃO PAULO",
      cidade: "SÃO PAULO",
      direcao: "SUDESTE",
    },
    {
      sigla: "RS",
      estado: "RIO GRANDE DO SUL",
      cidade: "PORTO ALEGRE",
      direcao: "SUL",
    },
    {
      sigla: "SC",
      estado: "SANTA CATARINA",
      cidade: "FLORIANÓPOLIS",
      direcao: "SUL",
    },
    { sigla: "AM", estado: "AMAZONAS", cidade: "MANAUS", direcao: "NORTE" },
    { sigla: "AP", estado: "AMAPÁ", cidade: "MACAPÁ", direcao: "NORTE" },
    { sigla: "AL", estado: "ALAGOAS", cidade: "MACEIÓ", direcao: "NORDESTE" },
    { sigla: "BA", estado: "BAHIA", cidade: "SALVADOR", direcao: "NORDESTE" },
    { sigla: "CE", estado: "CEARÁ", cidade: "FORTALEZA", direcao: "NORDESTE" },
    { sigla: "PR", estado: "PARANÁ", cidade: "CURITIBA", direcao: "SUL" },
  ];

  const estoquesUfs = [
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "MA",
    "PA",
    "PB",
    "PE",
    "PI",
    "RN",
    "RR",
    "SE",
    "TO",
  ];

  var availableStocks = [
    { uf: "SP", estoque: "SAO PAULO", codFilial: "01" },
  ];

  var estoquesObj = [
    { uf: "PB", estoque: "IPOJUCA", codFilial: "06" },
    { uf: "PR", estoque: "CASCAVEL", codFilial: "02" },
    { uf: "ES", estoque: "VITORIA", codFilial: "05" },
    { uf: "SP", estoque: "SAO PAULO", codFilial: "01" },
  ];

  const filterCheckedSeguro = seguro?.filter(item => item.checked == true);
  const totalSeguro = filterCheckedSeguro?.reduce(
    (total, valor) => total + valor?.valor,
    0
  ) ?? 0;

  var total = newOrder.addedItems.reduce(getTotal, 0) + valRtNoRetention;

  var isEstoqueFilialNumber = estoquesObj?.filter(
    item => item.uf == dataEstoque?.uf
  );

  var isMicroInverterSelected = data?.microOrInv?.name?.includes("MICRO")
    ? "01"
    : isEstoqueFilialNumber[0]?.codFilial || "01";

  function getTotal(total, item) {
    return total + item.produto.PRCRT;
  }

  useEffect(() => {
    if (parceiros.length > 0) {
      setFilterPedido(state => {
        return { ...state, vendedor2: parceiros[0].codigo };
      });
    }
  }, [parceiros]);

  //verificar se o retorno da requisição getNewOrder é solar
  const isSolarRequestNewOrder = () => {
    var requestIsSolar = newOrder.tabelaInicial == "043";
    if (!requestIsSolar) {
      toast.warning(
        "ATENÇÃO! Tabela diferente da solar! Saia e entre novamente",
        {
          theme: "colored",
        }
      );
    }
  };

  useEffect(() => {
    if (newOrder?.tabelaInicial) {
      isSolarRequestNewOrder();
    }
  }, [newOrder.tabelaInicial]);

  const getModules = async (query, filterType) => {
    setLoadingModule(true);
    try {
      const resp = await apiClient.get(`/api/person-kits/modules${query}`);

      let filteredModules;
      if (filterType === 'finame') {
        filteredModules = resp.filter(item => item.fabricante === 'Byd' || item.fabricante === 'Sengi');
      } else {
        filteredModules = resp.filter(item => item.fabricante);
      }
      setModules(filteredModules);

    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
    setLoadingModule(false);
  };

  useEffect(() => {
    var obj = {};

    /* ao digitar o cep busca o estoque por padrao em SP*/
    obj = estoquesObj[3];

    setDataEstoque(obj);
    getModules(`/02/${isMicroInverterSelected}?type=${data?.microOrInv.type}`);
  }, [dispatch]);

  const getDataLeed = async () => {
    try {
      const resp = await apiClient.get(`/api/solar/lead/${token}`, {
        type: "order",
      });

      setDataLeed(resp);
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  };

  useEffect(() => {
    if (token) getDataLeed();
  }, []);

  useEffect(() => {
    setObsInterna(order?.ObservacaoInterna);
    setObsNF(order?.ObservacaoNota);
  }, [order]);

  useEffect(() => {
    dispatch(clearNewOrder());
    dispatch(removeStatus());
    setFrete({});
    setValArquiteto({});
    setValores({});
    setValoresTabela({});
    setValorRt({});
    setValCashbackNoRetention({});
    setOcultarSemEstoque(true);
    setOcultarRt(false);
    setNfIsOpen(false);
    setFutureSaleValue(false);
    setRuralShippingValue(false);
    setTriangularSale(false);
    setObsIsOpen(false);
    setCepCliente(null);
    setPagtoSelected([]);
    setClient({});
    setSaldo(0);
    setItensCarrinho({});
    setPedidoEmitido(false);
    setType("order");
    dispatch(getNewOrder("CODEMPRESA=02&CODFILIAL=02", history));
    dispatch(getFilters(isMicroInverterSelected));
  }, []);

  useEffect(() => {
    setFullDiscount(newOrder.fullDiscount ? newOrder.fullDiscount : 0.00);
    setSubTotalDiscount(newOrder.subTotalDiscount);
  }, [newOrder.fullDiscount, newOrder.subTotalDiscount, newOrder.frete.shippingDiscount]);
  const [itHasForecast, setItHasForecast] = useState(0);

  useEffect(() => {
    let itHasForecast = newOrder.addedItems.filter((item) => {
      const produto = item?.produto;

      const minimumStock = (isInverter(produto) ? 10 : 1000) + produto?.estoqueFuturo;

      return (
        (produto?.previsao && produto?.qtdEstoque < produto?.estoqueFuturo) ||
        (produto?.previsao &&
          produto?.leadTime &&
          produto?.qtdEstoque < minimumStock)
      );
    });

    setItHasForecast(itHasForecast);
  }, [newOrder.addedItems]);

  let pedido = {
    client,
    type_order: "order",
    product_type: "",
    budget_infos: {
      codigo_cliente: 0,
      tipo_cliente: 0,
    },
    items: retornaItems(),
    itemsValTot: valTot,
    shipping: {
      id: frete?.id || "",
      shippingDiscount: frete?.promotionDiscount,
      shipping_selected: {
        delivery_delay: frete?.delivery_delay || "",
        id: frete?.id || "",
        price: frete?.price || "",
        description: frete?.description || "",
        price_percent: frete?.price_percent || "",
        usado_percentual: frete?.usado_percentual || "",
        frete_gratis: frete?.frete_gratis || "",
      },
      price: frete?.price || "",
    },
    filters: filterPedido,
    services: {
      product_type: "",
      ambientes: [],
    },
    discounts: discounts,
    discountcashback: saldo,
    fullDiscount,
    CODEMPRESA: "02",
    CODFILIAL: isMicroInverterSelected,
    termOfAgreement: termOfAgreement,
    status: status,
    futureSale: futureSaleValue,
    triangularSale: triangularSale,
    seguro,
    ruralShipping: ruralShippingValue,
    riverShipping: riverShippingValue,

    typeOfKit: "kit-personalizado-normal",
  };

  const largeAccountCode = ['Z42', 'Z43', 'Z44'];

  if (triangularSale) {
    pedido = {
      ...pedido,
      shippingZipCode,
      shippingAddress,
      shippingTotalValue
    };
  }

  function retornaItems() {
    let items = [];
    let skuGeneric =
      data.potenciaKwp < 75
        ? "PRKTSOL00000001"
        : data.potenciaKwp > 75 && data.potenciaKwp < 375
          ? "PRKTSOL00000002"
          : "PRKTSOL00000003";

    newOrder.addedItems.map(value => {
      var arraySubTotal = [];

      arraySubTotal.push({ [skuGeneric]: value.produto.subTotal });
      function getEstoques(es) {
        let result =
          value.produto.PRDDISPS?.STRETPRDFILADIAS?.find(
            i => i.CODFILPRD === es
          ) || "";
        return result?.ESTOQUE || 0;
      }

      let produto = {
        ...value.produto,
        ALIMENTACAO: "",
        ALTURA: "",
        KWP: 2.03,
        ARMAZEM: "",
        BITOLALIQ: "",
        BITOLASUC: "",
        CAPACIDADE: "05",
        CATEGORIA: "405",
        CCONDENSA: "",
        CEVAPORA: "",
        CICLO: "1",
        CODEAN: "",
        CODEMPRESA: "02",
        CODFILIAL: "02",
        CODLEGADO: "",
        CODPRODUTO: value?.produto?.sku ? value.produto.sku : skuGeneric,
        CONDPAGTO: "",
        COR: "X",
        CORRENTE: 0,
        DESCRICAO: value.produto.nome,
        DESCRICAO_COMPLETA:
          "QTDE MODULO: 05\nMÓDULO: MONO-PERC RISEN 405W (F:30 MM)\nNÚMERO DE CÉLULAS: 120 MONO HALF CELL\nMEDIDAS MOD: 1754 X 1096 X 30 mm\n\n \nQTDE INVERSOR 1: 1\nINVERSOR 1: SOFAR 1600TL-G3\nFASE/TENSÃO: MONOFÁSICO 220V\nMPPT: 1\nQTDE STRINGBOX 1: 1\nSTRINGBOX 1: STRINGBOX SB07A - 1E/1S SECCIONADORA NO INVERSOR\n\n \nQTDE PAR CONECTOR MC4: 2\n\n \nQTDE CABO 6MM-1800V VERMELHO (METROS): 15\nQTDE CABO 6MM-1800V PRETO (METROS): 15\n\n \nQTDE ESTRUTURA 1: 10\nESTRUTURA 1: KIT SUPORTE PARA TELHADO COLONIAL\nQTDE ESTRUTURA 2: 4\nESTRUTURA 2: KIT TERMINAL FINAL 35MM\nQTDE ESTRUTURA 3: 6\nESTRUTURA 3: KIT TERMINAL INTERMEDIÁRIO 39/44MM - PAR\nQTDE ESTRUTURA 4: 4\nESTRUTURA 4: PERFIL DE ALUMÍNIO 3.40M\nQTDE ESTRUTURA 5: 4\nESTRUTURA 5: KIT DE EMENDAS E PARAFUSOS INOX 8X12",
        FASE: "2",
        GARANTEX: 0,
        GARANTIA: 0,
        GRUPO: "INO",
        LARGURA: 0,
        MARCA: "",
        NCM: "",
        ORIGEM: "",
        PESOBRUTO: 0,
        PESOLIQUI: 0,
        PRDDISPS: {
          STRETPRDFILADIAS: [
            {
              CODFILPRD: "02",
              ESTOQUE: 1,
            },
          ],
        },
        PREVISAOC7: "",
        PROFUNDIDADE: 0,
        RUIDO: 0,
        SELOA: "",
        TABELAPRC: "",
        TIPOPROD: "",
        UNIDADEMED: "",
        VOLUME: 0,
        ITEM: "",
        AMOUNT: value.produto.qtd ? value.produto.qtd : 1,
        VALORRT: 0,
        PRCRT: value.produto.PRCRT,
        subtotal: arraySubTotal,
        maskPrcVenda: value.produto.precoCliente,
        promotionDiscount: value.promotionDiscount,
        PERCENT: 0,
        PRCVENDA: value.produto.precoCliente,
        ESTOQUE_CD01: getEstoques("01"),
        ESTOQUE_CD02: getEstoques("02"),
        ESTOQUE_CD03: getEstoques("03"),
        ESTOQUE_CD04: getEstoques("04"),
        /* previsao: previsao, */
        precos: {
          cd:
            value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.CODFILPRD ||
            "",
          preco: value.precoVenda,
          diferente: "N",
        },
        PRECODIFER: value.produto.precoCliente || "",
        ESTOQUE:
          value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.ESTOQUE || "",
        instalacao: false,
        VALSERV: sendValServ ? valServ : 0,
        previsao: itHasForecast.some(item => item.produto.sku == value.produto.sku) ? value.produto.previsao : null,
        ...itensCarrinho[value.produto.nome],
      };

      items.push(produto);
    });

    return items;
  }

  const showInvalidKitToast = () => {
    toast.info(
      `Entre em contato com o comercial ou faça seu pedido através de "Compra fora Kit"`,
      { theme: "colored" }
    );
  }

  const totalModulesPotency = () => {
    const filterModules = newOrder.addedItems.filter((item, k) =>
      item?.produto?.nome?.includes("MOD.")
        ? item?.produto?.nome?.includes("MOD.")
        : item?.produto?.nome?.includes("Modulos")
          ? item?.produto?.nome?.includes("Modulos")
          : item?.produto?.nome?.includes("MOD FOTOV")
            ? item?.produto?.nome?.includes("MOD FOTOV")
            : item?.produto?.nome?.includes("MOD. FOTOV")
              ? item?.produto?.nome?.includes("MOD. FOTOV")
              : item?.produto?.nome?.includes("GCL")
    );
    return filterModules.reduce(
      (total, valor) =>
        total + valor?.produto?.potencia * valor?.produto?.qtd,
      0
    );
  }

  const totalPotency = () => {
    const filterInverters = newOrder.addedItems.filter((item, k) =>
      isInverter(item?.produto)
    );

    return filterInverters.reduce(
      (total, valor) =>
        total +
        valor?.produto?.potencia *
        (valor?.produto?.qtd ? valor.produto.qtd : 1),
      0
    );
  };

  const totalPercentPotency = () => {
    const percent = 30;
    return (percent * totalPotency()) / 100;
  }

  const checkIfItsOutOfKit = () => {
    return Number(totalPercentPotency()) > Number(totalModulesPotency());
  };

  const isInvalidKit = () => {
    return totalPercentPotency() <= 0 || totalModulesPotency() <= 0 || checkIfItsOutOfKit();
  }

  useEffect(() => {
    setDiscounts(newOrder?.discounts ? newOrder.discounts : [])
  }, [newOrder?.discounts]);

  useEffect(() => {
    if (checkIfItsOutOfKit()) {
      showInvalidKitToast();
    };
  }, [newOrder.addedItems, valores]);

  function Somar(array) {
    var total = 0;
    for (var i = 0; i < array.length; i++) {
      total += array[i];
    }
    return total;
  }

  const addValTot = valor => {
    setValores(valor);
  };

  const addValoresTabela = valor => {
    setValoresTabela(valor);
  };

  const totalValue = () => {
    var totalDiscount = parseFloat(total) - parseFloat(fullDiscount || 0);
    return parseFloat(
      parseFloat(totalDiscount) +
      parseFloat(totalSeguro) +
      parseFloat(frete?.price || 0) -
      parseFloat(saldo)
    );
  };

  useEffect(() => {
    if (frete?.id) {
      pushToDataLayer(
        'add_shipping_info',
        {
          ecommerce: {
            currency: 'BRL',
            value: totalValue(),
            selectedShipping: frete,
            items: newOrder?.addedItems.map(product => {
              const produto = Array.isArray(product.produto) ? product.produto[0] : product.produto;
              return {
                item_id: produto.sku || '',
                item_name: produto.nome || '',
                price: produto.PRCRT || 0,
                quantity: produto.qtd || 0
              }
            })
          }
        },
        { clearEcommerce: true }
      );
    }
  }, [frete]);

  const addFrete = valor => {
    setFrete(valor);
  };

  const changeType = type => {
    setType(type);
  };

  const changeCepCliente = cep => {
    setCepCliente(cep);
  };

  useEffect(() => {
    if (!activePromotionsAndCoupons) {
      dispatch(removePromotionsAndCoupons());

      const removeShippingPromotionsAndCoupons = () => {
        const newFreteValue = frete;
        newFreteValue.promotionDiscount = 0;
        addFrete(newFreteValue);
      }
      removeShippingPromotionsAndCoupons();

    } else {
      getPromotionsAndDiscounts();
    }
  }, [activePromotionsAndCoupons]);

  const addValorRt = valor => {
    setValorRt(prevState => {
      return {
        ...prevState,
        [valor.nome]: valor.valCashBack,
      };
    });
  };

  const addValorRtNoRetention = valor => {
    setValCashbackNoRetention(prevState => {
      return {
        ...prevState,
        [valor.nome]: valor.valCashBackNoRentention,
      };
    });
  };

  const addValArqu = valor => {
    setValArquiteto(valor);
  };

  const changeSaldo = saldo => {
    setSaldo(Number(saldo));
  };

  const changeClient = client => {
    setClient(prev => ({ ...prev, ...client }));
  };

  const changePedidoEmitido = () => {
    setPedidoEmitido(!pedidoEmitido);
  };

  const searchDefaultStock = () => {
    const defaultStockIndex = 3;
    setDataEstoque(estoquesObj[defaultStockIndex]);
  }

  const initialModuleSelect = () => {
    const sortModules = getModulesSortedByPricePerPower();
    setModuleSelected({
      nome: sortModules[0]?.nome,
      potencia: sortModules[0]?.potencia,
      compimentoModulo: sortModules[0]?.comprimento,
      precoCliente: sortModules[0]?.precoCliente,
      previsao: sortModules[0]?.previsao || null,
      largura: sortModules[0]?.largura,
      fornecedor: sortModules[0]?.fornecedor,
      sku: sortModules[0]?.sku,
      qtdEstoque: sortModules[0]?.qtdEstoque,
      estoqueFuturo: sortModules[0]?.estoqueFuturo,
      leadTime: sortModules[0]?.leadTime,
    });
  };

  useEffect(() => {
    if (modules.length > 0) {
      initialModuleSelect();
    }
  }, [modules]);

  const resetAllInfos = () => {
    dispatch(zerarCarrinho());
    dispatch(removeStatus());

    setPercentCashBackAll("0");
    setProductsVisible(false);
    setValTotAndRt({});
  };

  const changeModules = async (event) => {
    const isChecked = event.target.checked;
    setSelectedFiname(isChecked);

    if (isChecked) {
      await getModules(`/02/${isMicroInverterSelected}?type=${data?.microOrInv.type}`, 'finame');
    } else {
      await getModules(`/02/${isMicroInverterSelected}?type=${data?.microOrInv.type}`);
    }
  };

  useEffect(() => {
    getPromotionsAndDiscounts();
  }, [customerDeliveryAddress, formVisible, client.customerType, valoresTabela, activePromotionsAndCoupons]);

  useEffect(() => {
    if (!formVisible.client) {
      dispatch(removePromotionsAndCoupons());
    }
  }, [formVisible]);



  const getPromotionsAndDiscounts = () => {
    if (formVisible.client && customerDeliveryAddress && Object.keys(valoresTabela).length > 0 && activePromotionsAndCoupons) {
      setFrete({
        ...frete,
        promotionDiscount: 0
      });
      dispatch(removePromotionsAndCoupons());
      dispatch(checkCartRuleOnChange(pedido));
    }
  }


  useEffect(() => {
    const customerDeliveryAddressUpdate = client?.customer_address?.length > 0
      ? pedido.client.customer_address.length > 1
        ? pedido.client.customer_address[1]
        : pedido.client.customer_address[0]
      : null;

    if (
      customerDeliveryAddressUpdate?.city &&
      customerDeliveryAddressUpdate?.state &&
      (
        customerDeliveryAddressUpdate.city != customerDeliveryAddress.city ||
        customerDeliveryAddressUpdate.state != customerDeliveryAddressUpdate.state
      )
    ) {
      setCustomerDeliveryAddress({
        city: customerDeliveryAddressUpdate.city,
        state: customerDeliveryAddressUpdate.state
      });
    }
  }, [client?.customer_address]);

  useEffect(() => {
    if (frete?.price && discounts) {
      const shippingsPromotions = discounts.filter(discount => isShippingPromotion(discount.applyToShipping));

      let discountValue = 0;
      shippingsPromotions.forEach(discount => {
        discountValue += Number(getDiscountValue(frete.price, discount.valueDiscount, discount.typer));
      });

      setFrete({
        ...frete,
        promotionDiscount: discountValue
      });
    }
  }, [discounts]);

  const isShippingPromotion = applyToShipping => {
    return applyToShipping == 1;
  }

  const getDiscountValue = (price, valueDiscount, typeRuleDiscount) => {
    const moneyDiscount = 0;
    return typeRuleDiscount == moneyDiscount ? valueDiscount : valueDiscount * (price / 100);
  }

  useEffect(() => {
    if (emissaoPedido?.data?.success) {

      pushToDataLayer(
        type == 'budget' ? 'solicitar_orcamento' : 'purchase',
        {
          ecommerce: {
            currency: 'BRL',
            value: totalValue(),
            transaction_id: emissaoPedido.data.CODPEDIDO,
            shipping: emissaoPedido.data.FRETE,
            vendedor1: emissaoPedido.data.VENDEDOR1,
            vendedor2: emissaoPedido.data.VENDEDOR2,
            vendedor3: emissaoPedido.data.VENDEDOR3,
            items: newOrder?.addedItems.map(product => {
              const produto = Array.isArray(product.produto) ? product.produto[0] : product.produto;
              return {
                item_id: produto.sku || '',
                item_name: produto.nome || '',
                price: produto.PRCRT || 0,
                quantity: produto.qtd || 0
              }
            })
          }
        },
        { clearEcommerce: true }
      );
    }
  }, [emissaoPedido]);

  const renderView = () => {
    return (
      <React.Fragment>
        <Breadcrumb title="Página Inicial" breadcrumbItem="Emissão do pedido" />
        <Row>
          <Col md={12}>
            <div className="mb-4">
              {/* import Banner */}
              <Banner />
            </div>
          </Col>
        </Row>

        <div></div>
        {loadingProspect && (
          <div className="carregando-emissao">
            <Spinner />
          </div>
        )}

        {loading && (
          <div className="carregando-emissao">
            <Spinner />
          </div>
        )}
        {/* VERIFICA SE O PEDIDO FOI EMITIDO  */}
        {!emissaoPedido?.data?.success ? (
          <React.Fragment>
            <Card>
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="title-card-modules">MÓDULOS DISPONÍVEIS</span>
                <span className="title-card-modules">
                  ESTOQUE:{" "}
                  <select
                    className="form-select"
                    aria-label="Filter select"
                    style={{ marginLeft: 10 }}
                    onChange={e => changeStock(e.target.value)}
                    value={dataEstoque.uf}
                  >
                    {availableStocks.map((item, k) => (
                      <option
                        key={k}
                        value={item.uf}>
                        {item.estoque}
                      </option>
                    ))}
                  </select>
                </span>
              </div> */}
              <div className='d-flex justify-content-between align-items-end'>
                <span className="title-card-modules">MÓDULOS DISPONÍVEIS</span>
                <FormGroup check className="me-4 mt-2"> 
                  <Input
                    type="checkbox"
                    checked={selectedFiname}
                    className="ocultar-rt"
                    onChange={e => changeModules(e)}
                  />
                  <Label check className="ocultar">
                    Mostrar somente produtos Finame   
                  </Label>
                </FormGroup>
              </div>
              <CardBody>
                <div className="box-grid">
                  {loadingModule ? (
                    <>
                      <div className="loading-module" />
                      <div className="loading-module">
                        <Spinner />
                      </div>
                    </>
                  ) : modules.length > 0 ? (
                    getModulesSortedByPricePerPower()
                      .map((item, k) => (
                        <CardModules
                          key={k}
                          item={item}
                          moduleSelected={moduleSelected}
                          setModuleSelected={setModuleSelected}
                          dataEstoque={dataEstoque}
                        />
                      ))
                  ) : (
                    <>
                      <div />
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Nenhum módulo econtrado
                      </div>
                    </>
                  )}
                </div>
                <hr />
                <FormModule
                  pedido={pedido}
                  moduleSelected={moduleSelected}
                  loadingSearModule={loadingSearModule}
                  addValTot={addValTot}
                  dataLeed={dataLeed}
                  setLoadingSearchModule={setLoadingSearchModule}
                  setProductsVisible={setProductsVisible}
                  data={data}
                  setData={setData}
                  setPercentCashBackAll={setPercentCashBackAll}
                  acrescimo={acrescimo}
                  setAcrescimo={setAcrescimo}
                  setRealPower={setRealPower}
                  getModules={getModules}
                  setValTotAndRt={setValTotAndRt}
                  setFilterPedido={setFilterPedido}
                  setChangeFormOfPayment={setChangeFormOfPayment}
                  setGetKitLoading={setGetKitLoading}
                  setWaitingToChangeCartItems={setWaitingToChangeCartItems}
                  resetAllInfosCarrinho={resetAllInfos}
                  setDataEstoque={setDataEstoque}
                  isMicroInverterSelected={isMicroInverterSelected}
                  setPagtoSelected={setPagtoSelected}
                  addValoresTabela={addValoresTabela}
                  largeAccountCode={largeAccountCode}
                />
              </CardBody>
            </Card>

            <>
              <hr style={{ borderTop: "1.5px solid #D5DCE5" }} />

              {productsVisible && (
                <Row className="mt-4" id="carrinho">
                  <Cart
                    addValTot={addValTot}
                    valores={valores}
                    loadingSearModule={loadingSearModule}
                    setPercentWattpay={setPercentWattpay}
                    valorRt={valorRt}
                    addValorRt={addValorRt}
                    valArquiteto={valArquiteto}
                    ocultarRt={ocultarRt}
                    addValArqu={addValArqu}
                    setItensCarrinho={setItensCarrinho}
                    itensCarrinho={itensCarrinho}
                    percentCashBackAll={percentCashBackAll}
                    setPercentCashBackAll={setPercentCashBackAll}
                    setPercentCashBackAllOnBlur={setPercentCashBackAllOnBlur}
                    percentCashBackAllOnBlur={percentCashBackAllOnBlur}
                    realPower={realPower}
                    setRealPower={setRealPower}
                    setValTotAndRt={setValTotAndRt}
                    valTotAndRt={valTotAndRt}
                    moduleSelected={moduleSelected}
                    acrescimo={acrescimo}
                    changeFormOfPayment={changeFormOfPayment}
                    addValorRtNoRetention={addValorRtNoRetention}
                    subTotalDiscount={subTotalDiscount}
                    total={total}
                    isMicroInverterSelected={isMicroInverterSelected}
                    valoresTabela={valoresTabela}
                    addValoresTabela={addValoresTabela}
                    itHasForecast={itHasForecast}
                    triangularSale={triangularSale}
                  />
                </Row>
              )}

              <Row id="sentinel" className="mt-4">
                <RegisterUser
                  valTot={valTot}
                  changeCepCliente={changeCepCliente}
                  pagtoSelected={pagtoSelected}
                  changeClient={changeClient}
                  client={client}
                  formVisible={formVisible}
                  setFormVisible={setFormVisible}
                  errosDataProspectAndPartner={errosDataProspectAndPartner}
                  setErrosDataProspectAndPartner={
                    setErrosDataProspectAndPartner
                  }
                  dataLeed={dataLeed}
                  parceiros={parceiros}
                  setParceiros={setParceiros}
                  setFrete={setFrete}
                  triangularSale={triangularSale}
                />
              </Row>
              <Row>
                <Saldo changeSaldo={changeSaldo} />
              </Row>
              <Row className="mt-3">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={11}>
                        <p
                          className="mb-0 d-flex align-items-center"
                          style={{ fontWeight: "bold" }}
                        >
                          <i
                            className="ph-notepad"
                            style={{
                              color: "#FF601A",
                              marginRight: 20,
                              fontSize: 25,
                            }}
                          ></i>
                          Adicionar Observação Interna:
                        </p>
                      </Col>
                      <Col md={1}>
                        <a
                          href="#"
                          style={{
                            width: "fit-content",
                            marginLeft: "auto",
                            display: "block",
                          }}
                          onClick={event => {
                            event.preventDefault();
                            setObsIsOpen(!obsIsOpen);
                          }}
                        >
                          {obsIsOpen ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </a>
                      </Col>
                    </Row>
                    <Row
                      hidden={!obsIsOpen}
                      style={{
                        marginTop: 30,
                        paddingTop: 30,
                        borderTop: "1px solid #d4d4d4",
                      }}
                    >
                      <textarea
                        className="form-control"
                        onChange={e => {
                          setClient({
                            ...client,
                            obs: e.target.value,
                          });

                        }}
                        value={client.obs}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Row>
              <Row className="mt-3">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={11}>
                        <p
                          className="mb-0 d-flex align-items-center"
                          style={{ fontWeight: "bold" }}
                        >
                          <i
                            className="ph-notepad"
                            style={{
                              color: "#FF601A",
                              marginRight: 20,
                              fontSize: 25,
                            }}
                          ></i>{" "}
                          Adicionar Observação de Nota Fiscal:
                        </p>
                        <small
                          className="text-muted"
                          style={{ marginLeft: "45px" }}
                        >
                          Adicionar dados do recebedor
                        </small>
                      </Col>
                      <Col md={1}>
                        <a
                          href="#"
                          style={{
                            width: "fit-content",
                            marginLeft: "auto",
                            display: "block",
                          }}
                          onClick={event => {
                            event.preventDefault();
                            setNfIsOpen(!nfIsOpen);
                          }}
                        >
                          {nfIsOpen ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </a>
                      </Col>
                    </Row>
                    <Row
                      hidden={!nfIsOpen}
                      style={{
                        marginTop: 30,
                        paddingTop: 30,
                        borderTop: "1px solid #d4d4d4",
                      }}
                    >
                      <textarea
                        className="form-control"
                        onChange={e => {
                          setClient({
                            ...client,
                            obsNot: e.target.value,
                          });
                        }}
                        value={client.obsNot}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Row>
              <Row className="mt-3" id="notas">
                <Cupons
                  enabled={formVisible.client}
                  disabledText="Cupom desabilitado em orçamentos com Prospect"
                  discounts={discounts}
                  activePromotionsAndCoupons={activePromotionsAndCoupons}
                  setActivePromotionsAndCoupons={setActivePromotionsAndCoupons}
                />
              </Row>
              {triangularSale && (
                <ShippingData 
                  shippingZipCode={shippingZipCode}
                  setShippingZipCode={setShippingZipCode}
                  shippingTotalValue={shippingTotalValue}
                  setShippingTotalValue={setShippingTotalValue}
                  setShippingAddress={setShippingAddress}
                />
              )}
              <Row className="d-flex justify-content-between" id="frete">
                <Col md={7}>
                  <Frete
                    addFrete={addFrete}
                    cepCliente={cepCliente}
                    data={data}
                    dataEstoque={dataEstoque}
                    setPrevisao={setPrevisao}
                    getKitLoading={getKitLoading}
                    waitingToChangeCartItems={waitingToChangeCartItems}
                    cepSelected={cepSelected}
                    setCepSelected={setCepSelected}
                    getTheHighestForecastDate={getTheHighestForecastDate}
                    setGetTheHighestForecastDate={
                      setGetTheHighestForecastDate
                    }
                    total={total}
                    seguro={totalSeguro}
                    shippingTotalValue={shippingTotalValue}
                    isMicroInverterSelected={isMicroInverterSelected}
                    discount={fullDiscount}
                    itHasForecast={itHasForecast}
                  />
                  {!triangularSale && (
                    <Seguro
                      seguro={seguro}
                      setSeguro={setSeguro}
                      pedido={pedido}
                    />
                  )}
                  {/*  <Arquiteto valArq={valArq} /> */}
                  {userProfile?.integrador ? (
                    <div />
                  ) : (
                    !formVisible.partner && <Card style={{ padding: 20 }}>
                      <PartnerCompany
                        parceiros={parceiros}
                        pedido={pedido}
                        setParceiros={setParceiros}
                      />
                    </Card>
                  )}{" "}
                  <Card style={{ padding: 20, minHeight: 226 }}>
                    <Status status={status} />
                  </Card>
                </Col>
                <Col md={5}>
                  <Resumo
                    valRt={valRt}
                    frete={frete}
                    saldo={saldo}
                    pedido={pedido}
                    changePedidoEmitido={changePedidoEmitido}
                    changeType={changeType}
                    formVisible={formVisible}
                    token={token}
                    percent={percentWattpay}
                    setLoadingProspect={setLoadingProspect}
                    cepSelected={cepSelected}
                    getTheHighestForecastDate={getTheHighestForecastDate}
                    data={data}
                    total={total}
                    seguro={totalSeguro}
                    isMicroInverterSelected={isMicroInverterSelected}
                    setFutureSaleValue={setFutureSaleValue}
                    fullDiscount={fullDiscount}
                    isInvalidKit={isInvalidKit}
                    setRuralShippingValue={setRuralShippingValue}
                    setRiverShippingValue={setRiverShippingValue}
                    setRuralOrRiverShippingValue={setRuralOrRiverShippingValue}
                    ruralOrRiverShippingValue={ruralOrRiverShippingValue}
                    ruralShippingValue={ruralShippingValue}
                    riverShippingValue={riverShippingValue}
                    triangularSale={triangularSale}
                    setTriangularSale={setTriangularSale}
                    shippingZipCode={shippingZipCode}
                    shippingTotalValue={shippingTotalValue}
                    largeAccountCode={largeAccountCode}
                  />
                </Col>
              </Row>
              <ControleRemoto />
            </>
          </React.Fragment>
        ) : (
          <PedidoEmitido
            changePedidoEmitido={changePedidoEmitido}
            type={type}
          />
        )}
      </React.Fragment>
    );
  };

  const handleClickModalConfirm = () => {
    const date = dateNow();
    const hours = new Date().toLocaleTimeString();

    setTermOfAgreement({ confirm: true, date: date, hour: hours });

    setOpenModal(false);
  };

  return (
    <>
      <div className="page-content neworder solar">
        <Container>{renderView()}</Container>
      </div>
      <ModalAlert
        openModal={openModal}
        setOpenModal={setOpenModal}
        footer
        modalAlert
        header="Termo de Acordo"
        textButton="Estou ciente!"
        onClick={handleClickModalConfirm}
      >
        O dimensionamento assim como a escolha de seus componentes é de
        responsabilidade exclusiva da empresa integradora, e deve ser conduzida
        por corpo técnico responsável.
      </ModalAlert>
    </>
  );
};

export default SolarKitPersonalizado;