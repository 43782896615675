import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CardBody,
  Col,
  Row,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Container,
} from "reactstrap";

import { Form } from 'react-bootstrap';

import { Typeahead } from "react-bootstrap-typeahead";
import { returnLogo } from "../../utils";
import { CaretDown, CaretRight, X } from "phosphor-react";
import './style.css';
import { getCapacities, getCategories, getPaymentConditions, getProductsList, getStarterOrderFilters } from "../../store/actions";
import ProductsList from "./products";
import { toast } from "react-toastify";

const CatalogProducts = () => {

  const { products, filters, loading, loadingDetails } = useSelector(state => ({
    products: state.CatalogProducts?.products,
    filters: state.CatalogProducts?.filters,
    loading: state.CatalogProducts?.loading,
    loadingDetails: state.CatalogProducts?.loadingDetails,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStarterOrderFilters('CODEMPRESA=01&CODFILIAL=02'));
  }, [dispatch]);

  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [showMoreManufacturers, setShowMoreManufacturers] = useState(false);
  const [showMoreCapacities, setShowMoreCapacities] = useState(false);
  const [manufacturersOnDisplay, setManufacturersOnDisplay] = useState([]);
  const [hideOutOfStock, setHideOutOfStock] = useState(true);

  const initialManufacturers = [
    { CODIGO: "LG", DESCR: "LG" },
    { CODIGO: "DA", DESCR: "DAIKIN" },
    { CODIGO: "TC", DESCR: "TCL" },
    { CODIGO: "GR", DESCR: "GREE" },
    { CODIGO: "EL", DESCR: "ELGIN" },
    { CODIGO: "MI", DESCR: "MIDEA" },
    { CODIGO: "CA", DESCR: "CARRIER" },
    { CODIGO: "FU", DESCR: "FUJITSU" },
    { CODIGO: "HI", DESCR: "HITACHI" },
    { CODIGO: "SM", DESCR: "SPRINGER MIDEA" },
    { CODIGO: "SP", DESCR: "SPRINGER" },
    { CODIGO: "TR", DESCR: "TRANE" },
  ];

  useEffect(() => {
    setManufacturersOnDisplay(showMoreManufacturers ? initialManufacturers : getManufacturerFormat());
  }, [showMoreManufacturers]);

  const getManufacturerFormat = () => {
    initialManufacturers.map(item => {
      filters.manufacturers.map((itemTwo, index) => {
        if (item.CODIGO == itemTwo.CODIGO) {
          filters.manufacturers.splice(index, 1);
        }
      });
    });
    const concat = initialManufacturers.concat(filters.manufacturers);
    return concat;
  };

  function clearCheckboxes() {
    let capacidade = document.getElementsByName("capacities");
    for (let cap of capacidade) {
      cap.checked = false;
    }
    let ciclo = document.getElementsByName("cycles");
    for (let cic of ciclo) {
      cic.checked = false;
    }
    let voltagem = document.getElementsByName("voltages");
    for (let vol of voltagem) {
      vol.checked = false;
    }
    let fabricante = document.getElementsByName("manufacturers");
    for (let fab of fabricante) {
      fab.checked = false;
    }
    setSelectedFilters({
      ...selectedFilters,
      capacities: [],
      cycles: [],
      manufacturers: [],
      voltages: []
    });
  }

  const [selectedFilters, setSelectedFilters] = useState({
    table: "",
    paymentCondition: "",
    group: "",
    category: "",
    capacities: [],
    cycles: [],
    voltages: [],
    manufacturers: [],
    description: "",
    sku: "",
    manufacturerCode: "",
    liquidGauge: "",
    suctionGauge: ""
  });

  useEffect(() => {
    if (selectedFilters?.table?.length > 0) {
      setSelectedFilters({
        ...selectedFilters,
        paymentCondition: ""
      });
      dispatch(getPaymentConditions(selectedFilters.table[0].id));
    }
  }, [selectedFilters?.table]);

  useEffect(() => {
    if (selectedFilters?.group?.length > 0) {
      dispatch(getCategories(selectedFilters.group[0].CODIGO));
    }
  }, [selectedFilters?.group]);

  useEffect(() => {
    if (selectedFilters?.category?.length > 0) {
      dispatch(getCapacities(
        selectedFilters.group[0].CODIGO,
        selectedFilters.category[0].CODIGO
      ));
    }
  }, [selectedFilters?.category]);

  const onClickCheckbox = (e, filter) => {
    let val = selectedFilters?.[filter]?.length > 0 ? [...selectedFilters?.[filter]] : [];
    if (e.target.checked) {
      val.push(e.target.value);
    } else {
      val = val.filter(v => v !== e.target.value);
    }
    setSelectedFilters({ ...selectedFilters, [filter]: val });
  }

  const onClickGetProducts = () => {
    if (!selectedFilters.table[0]) {
      return toast.info('Selecione uma tabela para continuar');
    }

    if (!selectedFilters.paymentCondition[0]) {
      return toast.info('Selecione a condição de pagamento');
    }

    if (!selectedFilters.group[0]) {
      return toast.info('Selecione o grupo dos produtos');
    }

    if (!selectedFilters.category[0]) {
      return toast.info('Selecione a categoria');
    }

    dispatch(getProductsList({
      capacidade: selectedFilters.capacities?.join(','),
      categoria: selectedFilters.category[0]?.CODIGO,
      condpagto: selectedFilters.paymentCondition[0]?.id,
      fabricante: selectedFilters.manufacturers?.join(','),
      grupos: selectedFilters.group[0]?.CODIGO,
      ciclo: selectedFilters.cycles?.join(','),
      tabela_preco: selectedFilters.table[0]?.id,
      voltagem: selectedFilters.voltages?.join(','),
      estoque: '-1000',
      sku: selectedFilters.sku,
      descricao: selectedFilters.description,
      bitolaliq: selectedFilters.liquidGauge,
      bitolasuc: selectedFilters.suctionGauge,
      codfabric: selectedFilters.manufacturerCode,
    }));
  }

  return (
    <React.Fragment>
      <Row className="page-content" id="filter">
        <Container>
          <Col>
            <Card>
              <CardBody className="filter">
                <Row className="mt-4">
                  <Col md={5}>
                    <FormGroup>
                      <Label className="label-titulo">Tabela:</Label>
                      <Typeahead
                        clearButton
                        id="select-tabela"
                        labelKey="name"
                        onChange={table => {
                          setSelectedFilters({
                            ...selectedFilters,
                            table
                          })
                        }}
                        options={filters?.tables}
                        placeholder="Escolha a tabela de preço:"
                        selected={selectedFilters?.table}
                      />
                    </FormGroup>
                  </Col>
                    <Col md={5}>
                      <FormGroup>
                        <Label className="label-titulo">Pagamento:</Label>
                        <Form.Control
                          as="select"
                          selected={selectedFilters?.paymentCondition?.name}
                          onChange={e => {
                            const paymentCondition = filters?.paymentConditions?.filter(option => option.name === e.target.value);

                            setSelectedFilters({
                              ...selectedFilters,
                              paymentCondition
                            });
                          }}
                        >
                          <optgroup label="Pagamento Online">
                            {filters?.paymentConditions
                              ?.filter(option => option?.tplib?.toUpperCase() == 'N')
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map(option => (
                                <option key={option.id} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                          </optgroup>

                          <optgroup className="mt-2" label="Pagamento Offline - Precisa de De Acordo assinado">
                            {filters?.paymentConditions
                              ?.filter(option => option?.tplib?.toUpperCase() == 'S')
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map(option => (
                                <option key={option.id} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                          </optgroup>
                        </Form.Control>
                      </FormGroup>
                    </Col>
                </Row>
                <hr className="linha" />
                <Row>
                  <Col md={6}>
                    <h4 className="subtitulo">Dados do ambiente:</h4>
                  </Col>
                  <Col md={6}>
                    <Button
                      color="light"
                      style={{
                        color: "#0067B4",
                        backgroundColor: "#FFF",
                        borderColor: "#F5F5F5",
                        borderWidth: "2px",
                        display: "flex",
                        width: "fit-content",
                        marginLeft: "auto",
                      }}
                      onClick={() => {
                        clearCheckboxes();
                        setSelectedFilters(
                          {
                            table: "",
                            paymentCondition: "",
                            group: "",
                            category: "",
                            capacities: [],
                            cycles: [],
                            voltage: [],
                            manufacturers: [],
                            description: "",
                            sku: "",
                            manufacturerCode: "",
                            liquidGauge: "",
                            suctionGauge: ""
                          }
                        )
                      }}
                    >
                      Limpar Filtros{" "}
                      <X className="ocultar" size={20} weight="bold" />
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={4}>
                    <FormGroup>
                      <Label className="label-titulo">Grupo:</Label>
                      <Typeahead
                        clearButton
                        id="select-grupo"
                        labelKey="DESCR"
                        onChange={(group) => {
                          setSelectedFilters({
                            ...selectedFilters,
                            group: group
                          })
                        }}
                        options={filters?.groups}
                        placeholder="Escolha o grupo de produtos:"
                        selected={selectedFilters.group}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label className="label-titulo">Categoria:</Label>
                      <Typeahead
                        clearButton
                        id="select-categoria"
                        labelKey={(category) => category?.DESCR?.trim() != "" ? category.DESCR : category.CODIGO}
                        onChange={(category) => {
                          setSelectedFilters({
                            ...selectedFilters,
                            category: category
                          })
                        }}
                        options={filters?.categories}
                        placeholder="Escolha a categoria do produto:"
                        selected={selectedFilters.category}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <FormGroup>
                      <Row className="d-flex align-items-center mb-3">
                        <Col md={2}>
                          <Label className="label-titulo mb-0">Capacidade:</Label>
                        </Col>
                      </Row>
                      <Row>
                        {filters?.capacities?.map((value, index) => (
                          <div
                            style={{ width: "20%", marginTop: 25 }}
                            key={index}
                            hidden={index < 10 ? false : !showMoreCapacities}
                          >
                            <Input
                              type="checkbox"
                              value={("00" + value.CODIGO).slice(-2)}
                              name="capacities"
                              aria-label={value.DESCR}
                              id={`cap-${value.CODIGO}`}
                              onClick={(e) => onClickCheckbox(e, 'capacities')}
                            />{" "}
                            <Label check className=" ocultar">
                              {value.DESCR}
                            </Label>
                          </div>
                        ))}
                      </Row>
                      {filters?.capacities?.length > 8 ? (
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            setShowMoreCapacities(!showMoreCapacities);
                          }}
                          className="ver-todos"
                        >
                          Ver todas as opções
                          <CaretRight size={18} weight="bold" />
                        </a>
                      ) : (
                        <div />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <div style={{ width: "40%" }}>
                    <FormGroup>
                      <Row className="d-flex align-items-center mb-3">
                        <Col md={2}>
                          <Label className="label-titulo mb-0">Ciclo:</Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} style={{ marginTop: 25 }}>
                          <Input
                            type="checkbox"
                            name="cycles"
                            aria-label="Frio"
                            value="F"
                            id={`cic-F`}
                            onClick={(e) => onClickCheckbox(e, 'cycles')}
                          />{" "}
                          <Label check className="ocultar">
                            Frio
                          </Label>
                        </Col>
                        <Col md={6} style={{ marginTop: 25 }}>
                          <Input
                            type="checkbox"
                            name="cycles"
                            aria-label="Quente"
                            value="Q"
                            id={`cic-Q`}
                            onClick={(e) => onClickCheckbox(e, 'cycles')}
                          />{" "}
                          <Label check className="ocultar">
                            Quente
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                  <div style={{ width: "40%" }}>
                    <FormGroup>
                      <Row className="d-flex align-items-center mb-3">
                        <Col md={3}>
                          <Label className="label-titulo mb-0">Voltagem:</Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} style={{ marginTop: 25 }}>
                          <Input
                            type="checkbox"
                            name="voltages"
                            value={1}
                            aria-label="110/MONOFASICO"
                            id={`vol-1`}
                            onClick={(e) => onClickCheckbox(e, 'voltages')}
                          />{" "}
                          <Label check className=" ocultar">
                            110/MONOFASICO
                          </Label>
                        </Col>
                        <Col md={6} style={{ marginTop: 25 }}>
                          <Input
                            type="checkbox"
                            name="voltages"
                            value={2}
                            aria-label="220/MONOFASICO"
                            id={`vol-2`}
                            onClick={(e) => onClickCheckbox(e, 'voltages')}
                          />{" "}
                          <Label check className=" ocultar">
                            220/MONOFASICO
                          </Label>
                        </Col>
                        <Col md={6} style={{ marginTop: 25 }}>
                          <Input
                            type="checkbox"
                            name="voltages"
                            value={3}
                            aria-label="220/TRIFASICO"
                            id={`vol-3`}
                            onClick={(e) => onClickCheckbox(e, 'voltages')}
                          />{" "}
                          <Label check className=" ocultar">
                            220/TRIFASICO
                          </Label>
                        </Col>
                        <Col md={6} style={{ marginTop: 25 }}>
                          <Input
                            type="checkbox"
                            name="voltages"
                            value={4}
                            aria-label="380/TRIFASICO"
                            id={`vol-4`}
                            onClick={(e) => onClickCheckbox(e, 'voltages')}
                          />{" "}
                          <Label check className=" ocultar">
                            380/TRIFASICO
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <FormGroup>
                      <Row className="d-flex align-items-center mb-3">
                        <Col md={2}>
                          <Label className="label-titulo mb-0">Fabricante:</Label>
                        </Col>
                      </Row>
                      <Row>
                        {manufacturersOnDisplay?.map((value, index) => (
                          <Col md={2} key={index}>
                            <div className="checkbox">
                              <input
                                type="checkbox"
                                name="manufacturers"
                                value={value.CODIGO.trim()}
                                aria-label={value.DESCR.trim()}
                                id={`fab-${value.CODIGO.trim()}`}
                                onClick={e => { onClickCheckbox(e, 'manufacturers') }}
                              />
                              <div className="box">
                                <img src={returnLogo(value.CODIGO)} />
                                <p className="text-muted">{value.DESCR}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </FormGroup>
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setShowMoreManufacturers(!showMoreManufacturers);
                      }}
                      className="ver-todos"
                    >
                      Ver todas as opções
                      <CaretRight size={18} weight="bold" />
                    </a>
                  </Col>
                </Row>
                <Label
                  className="label-titulo mb-4 search-advanced"
                  onClick={() => setAdvancedSearch(state => !state)}
                >
                  Buscar Avançada{" "}
                  <CaretDown
                    style={{
                      transform: advancedSearch ? "rotate(180deg)" : "rotate(0deg)",
                      marginLeft: 10,
                    }}
                    size={20}
                    weight="bold"
                  />
                </Label>
                <Row
                  style={{
                    height: advancedSearch ? 160 : 0,
                    overflow: "hidden",
                    transition: "height 0.5s ease",
                  }}
                >
                  <Row style={{ marginBottom: 10 }}>
                    <Col md={4}>
                      <Label>Descrição</Label>
                      <Input
                        className="titulo-input"
                        placeholder="Descrição"
                        value={selectedFilters?.description}
                        onChange={e =>
                          setSelectedFilters({
                            ...selectedFilters,
                            description: e.target.value
                          })
                        }
                      />
                    </Col>{" "}
                    <Col md={4}>
                      <Label>Sku</Label>
                      <Input
                        className="titulo-input"
                        placeholder="SKUs separados por vírgula"
                        value={selectedFilters?.sku}
                        onChange={e =>
                          setSelectedFilters({
                            ...selectedFilters,
                            sku: e.target.value
                          })
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <Label>Código Fabricante</Label>
                      <Input
                        className="titulo-input"
                        placeholder="Código Fabricante"
                        value={selectedFilters?.manufacturerCode}
                        onChange={e =>
                          setSelectedFilters({
                            ...selectedFilters,
                            manufacturerCode: e.target.value
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Label>Bitola Liquida</Label>
                      <select
                        onChange={e =>
                          setSelectedFilters({
                            ...selectedFilters,
                            liquidGauge: e.target.value
                          })
                        }
                        className="form-select form-control"
                      >
                        <option value="">Todos</option>
                        <option value="0,25">0,25</option>
                        <option value="0,75">0,75</option>
                        <option value="00">00</option>
                        <option value="1,4">1,4</option>
                        <option value="1/2">1/2</option>
                        <option value="1/4">1/4</option>
                        <option value="1/4 Pol">1/4 Pol</option>
                        <option value="1/4 e 1/2">1/4 e 1/2</option>
                        <option value="1/4 e 3/8">1/4 e 3/8</option>
                        <option value="1/4 e 5/8">1/4 e 5/8</option>
                        <option value="1/4 pol">1/4 pol</option>
                        <option value="1/4">1/4"</option>
                        <option value="1/4">1/4" - 3/8"</option>
                        <option value="1/8">1/8</option>
                        <option value="11">11</option>
                        <option value="17,11">17,11</option>
                        <option value="3,8">3,8</option>
                        <option value="3/4">3/4</option>
                        <option value="3/4 até 10m">3/4 até 10m</option>
                        <option value="3/8">3/8</option>
                        <option value="3/8">3/8</option>
                        <option value="3/8 e 5/8">3/8 e 5/8</option>
                        <option value="3/8 pol">3/8 pol</option>
                        <option value="3/8">3/8"</option>
                        <option value="5/8 até 15m">5/8 até 15m</option>
                        <option value="6,35">6,35</option>
                        <option value="6,35 (1/4)">6,35 (1/4)</option>
                        <option value="6,35mm">6,35mm</option>
                        <option value="6,4(1/4)">6,4(1/4)</option>
                        <option value="7,2">7,2</option>
                        <option value="7/8">7/8</option>
                        <option value="9,52">9,52</option>
                      </select>
                    </Col>
                    <Col md={6}>
                      <Label>Bitola Sucção</Label>
                      <select
                        onChange={e =>
                          setSelectedFilters({
                            ...selectedFilters,
                            suctionGauge: e.target.value
                          })
                        }
                        name="tabela_preco"
                        className="form-select form-control"
                      >
                        <option value="">Todos</option>
                        <option value="0,375">0,375</option>
                        <option value="0,5">0,5</option>
                        <option value="00">00</option>
                        <option value="1,2">1,2</option>
                        <option value="1/2">1/2</option>
                        <option value="1/2 até 15 m">1/2 até 15 m</option>
                        <option value="1/2 até 15m">1/2 até 15m</option>
                        <option value="1/2 pol">1/2 pol</option>
                        <option value="1/2">1/2"</option>
                        <option value="1/4">1/4</option>
                        <option value="1/4 e 1/2">1/4 e 1/2</option>
                        <option value="1/4 e 3/8">1/4 e 3/8</option>
                        <option value="1/4 e 5/8">1/4 e 5/8</option>
                        <option value="1/8">1/8</option>
                        <option value="12,7">12,7</option>
                        <option value="12,7 (1/2)">12,7 (1/2)</option>
                        <option value="15,87">15,87</option>
                        <option value="15,88 (5/8">15,88 (5/8</option>
                        <option value="15,88 (5/8)">15,88 (5/8)</option>
                        <option value="3,4">3,4</option>
                        <option value="3/4">3/4</option>
                        <option value="3/4   7/8">3/4 7/8</option>
                        <option value="3/4  5/8">3/4 5/8</option>
                        <option value="3/4 5/8 7/8">3/4 5/8 7/8</option>
                        <option value="3/4 até 10m">3/4 até 10m</option>
                        <option value="3/4 até 20m">3/4 até 20m</option>
                        <option value="3/4 até 30m">3/4 até 30m</option>
                        <option value="3/4 até 7,5">3/4 até 7,5</option>
                        <option value="3/7">3/7</option>
                        <option value="3/8">3/8</option>
                        <option value="3/8 Pol">3/8 Pol</option>
                        <option value="3/8 e 5/8">3/8 e 5/8</option>
                        <option value="3/8 pol">3/8 pol</option>
                        <option value="3/8">3/8"</option>
                        <option value="5/3">5/3</option>
                        <option value="5/8">5/8</option>
                        <option value="5/8  3/4">5/8 3/4</option>
                        <option value="5/8 ate 10m">5/8 ate 10m</option>
                        <option value="5/8 até 10m">5/8 até 10m</option>
                        <option value="5/8 até 7,5">5/8 até 7,5</option>
                        <option value="5/8 pol">5/8 pol</option>
                        <option value="5/8">5/8"</option>
                        <option value="7/8">7/8</option>
                        <option value="7/8 até 15m">7/8 até 15m</option>
                        <option value="7/8 até 30m">7/8 até 30m</option>
                        <option value="7/8">7/8"</option>
                        <option value="9,52 (3/8)">9,52 (3/8)</option>
                        <option value="9,53">9,53</option>
                        <option value="9,53mm">9,53mm</option>
                      </select>
                    </Col>
                  </Row>
                </Row>

                <div className="ocultar">
                  <Input
                    type="checkbox"
                    checked={hideOutOfStock}
                    onChange={() => setHideOutOfStock(!hideOutOfStock)}
                  />{" "}
                  <label>Ocultar produtos sem estoque</label>
                </div>

                <Button
                  className="mt-2 buscar-produto"
                  onClick={onClickGetProducts}
                  disabled={loading}
                >
                  BUSCAR PRODUTOS
                </Button>
                <ProductsList
                  products={products}
                  loading={loading}
                  loadingDetails={loadingDetails}
                  hideOutOfStock={hideOutOfStock}
                />
              </CardBody>
            </Card>
          </Col>
        </Container>
      </Row>
    </React.Fragment>
  );
};
export default CatalogProducts;