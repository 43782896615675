import React from "react";
import { CardBody, Col, Row, Card } from "reactstrap";
import './style.css'
const ProductItem = ({ product, isSelected, isDisabled, onSelect }) => {
  const formato = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency: "BRL",
  };
  return (
    <Card
      className={`mb-0 mt-4 ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}  
      onClick={!isDisabled ? onSelect : null}  
      style={{ 
        border: isSelected ? "2px solid #007bff" : "1px solid #ddd",
        opacity: isDisabled ? 0.5 : 1, 
        cursor: isDisabled ? 'not-allowed' : 'pointer' 
      }}  
    >
      <CardBody>
        <Row>
          {product?.details?.fotos ? (
            <Col md={2}> 
              <img src={product.details.fotos[1]} style={{ width: "100%" }} alt="Produto" />
            </Col>
          ) : (
            <div />
          )}
          <Col md={6}>
            <h5>{product.DESCRICAO}</h5>
            <Row>
              <Col className="mt-2 d-flex align-items-center">
                <div>
                  <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                    Cód. Fabricante: /
                  </p>
                  <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                    SKU: {product.CODPRODUTO}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <div md={2}>
                <div>
                  <p>Tabela (R$)</p>
                  <p>{`R$ ${Number(
                    product?.PRCVENDA?.STRETPRCFILADIAS[0]?.PRCVENDA
                  ).toLocaleString("pt-br", formato)}`}</p>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default ProductItem;