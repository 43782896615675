const formatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  // minimumFractionDigits: 0,
  signDisplay: "auto",
});

const formateCurrency = value => {
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return ``;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return `R$ ${numberString}`;
};

const displayCurreny = value => {
  return `R$ ${value}`;
};

const formatCurrencyInput = value => {
  if (value) {
    const [intPart, decimalPart] = value.split(".");

    const format = {
      minimumFractionDigits: 0,
      style: 'currency',
      currency: 'BRL'
    };

    const formattedInt = Number(intPart).toLocaleString("pt-BR", format);

    return decimalPart !== undefined ? `${formattedInt},${decimalPart}` : formattedInt;
  }
};

const sanitizeCurrencyInput = value => {
  let formatedValue = value.replace(/[^\d,]/g, "").replace(",", ".");

  const alreadyHasComma = formatedValue.includes(",");

  if (alreadyHasComma) {
    formatedValue = formatedValue.replace(/,/g, ".");
  }

  const [intPart, decimalPart] = formatedValue.split(".");

  if (decimalPart && decimalPart.length > 2) {
    formatedValue = `${intPart}.${decimalPart.slice(0, 2)}`;
  }

  return formatedValue;
}

export { formatter, formateCurrency, displayCurreny, formatCurrencyInput, sanitizeCurrencyInput };
