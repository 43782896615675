import React, { useState, useEffect, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CardBody,
  Col,
  Row,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner
} from "reactstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { getVendedor as getVendedorApi } from "../../api/index";
import {
  getNewOrder,
  changeGroup,
  changeCategory,
  changePagto,
  getOrderProducts,
  zerarCarrinho,
  atualizaCarrinho,
  ocultarRT,
  setPagtoSelected,
  setFilterPedido,
  setTabela,
  updateZipcodeModalIsOpen,
} from "../../store/actions";
import { mask, returnLogo, scrollToDiv } from "../../utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CaretDown, CaretRight, X, XCircle } from "phosphor-react";
import SelectSearch from "../../components/SelectSerach";
import ModalUpdateZipcode from "../UpdateZipcode";
import "./newOrder.css";
import { Alert } from "@mui/material";
import { useDataLayer } from "../../hooks/DataLayerHooks";
import { Form } from "react-bootstrap";

const OrderFilter = ({
  pagamento,
  setPagamento,
  pedido,
  history
}) => {
  const { id } = useParams();
  const catSelector = createRef();
  const gruSelector = createRef();
  const dispatch = useDispatch();
  const { newOrder, order, ocultarRt, filterPedido, parceiros, tabela, defaultZipcode } =
    useSelector(state => ({
      newOrder: state.Order.newOrder.newOrder,
      order: state.Order.orderActive.order,
      ocultarRt: state.Order.newOrder.newOrder.filters.ocultarRt,
      filterPedido: state.Order.newOrder.newOrder.filters.filterPedido,
      parceiros: state.Order.newOrder.newOrder.filters.parceiros,
      tabela: state.Order.newOrder.newOrder.filters.tabela,
      defaultZipcode: state.Layout.defaultZipcode
    }));

  const { pushToDataLayer } = useDataLayer();

  const [form, setForm] = useState({
    capacidade: "",
    categoria: "",
    condpagto: "",
    fabricante: "",
    grupos: "",
    ciclo: "",
    tabela_preco: "",
    voltagem: "",
    estoque: "-1000",
    descricao: "",
    bitolaliq: "",
    bitolasuc: "",
    codfabric: "",
    cepentrega: order?.CEPEntrega,
  });
  const [filters, setFilters] = useState({
    vendedores: [{ codigo: "", label: "" }],
    tabela: [],
    condPagto: [],
    grupos: [],
    categorias: [],
    capacidade: [],
    voltagem: [],
    fabricantes: [],
  });
  const [initial, setInitial] = useState([]);

  const [isLoading] = useState(false);
  const [capVisible, setCapVisible] = useState(false);
  const [capSelected, setCapSelected] = useState([]);
  const [fabSelected, setFabSelected] = useState([]);
  const [cicSelected, setCicSelected] = useState([]);
  const [volSelected, setVolSelected] = useState([]);

  const [grupo, setGrupo] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [count, setCount] = useState(0);
  const [varEscolha, setVarEscolha] = useState([]);
  const [arrayFab, setArrayFab] = useState();
  const [fabVisible, setFabVisible] = useState("first");
  const [serachAdvanced, setSeachAdvanced] = useState(false);

  const fabs = [
    { CODIGO: "LG", DESCR: "LG" },
    { CODIGO: "DA", DESCR: "DAIKIN" },
    { CODIGO: "TC", DESCR: "TCL" },
    { CODIGO: "GR", DESCR: "GREE" },
    { CODIGO: "EL", DESCR: "ELGIN" },
    { CODIGO: "MI", DESCR: "MIDEA" },
    { CODIGO: "CA", DESCR: "CARRIER" },
    { CODIGO: "FU", DESCR: "FUJITSU" },
    { CODIGO: "HI", DESCR: "HITACHI" },
    { CODIGO: "SM", DESCR: "SPRINGER MIDEA" },
    { CODIGO: "SP", DESCR: "SPRINGER" },
    { CODIGO: "TR", DESCR: "TRANE" },
  ];
  const [scrollButton, setScrollButton] = useState(null);
  const filterBy = () => true;

  const [isLoadingVendedores, setIsLoadingVendedores] = useState(false);

  const vendedorComRt = newOrder.comRt == '1';

  const changeVendedores = inputValue => {
    setIsLoadingVendedores(true);
    setFilters({ ...filters, vendedores: [] });

    getVendedorApi(inputValue)
      .then(res => {
        let data = [];
        res.map(value => {
          data.push({
            codigo: value.CODIGO,
            nome: value.NOME,
            label: `${value.CODIGO} - ${value.NREDUZ.trim()}`,
            nreduz: value.NREDUZ,
            cnpj: value.CPFCNPJ,
          });
        });

        setFilters({ ...filters, vendedores: data });
        setIsLoadingVendedores(false);
      })
      .catch(console.log);
  };

  function getProducts(e) {
    if (form?.tabela_preco === "" || form?.condpagto === "") {
      toast.error("Favor preencher Tabela/Pagamento", { theme: "colored" });
    } else if (
      form?.grupos === "" &&
      form?.categoria === "" &&
      form?.capacidade === ""
    ) {
      toast.error(
        "Favor selecionar ao menos um dos seguintes filtros: Grupo, Categora ou Capacidade",
        { theme: "colored" }
      );
    } else {
      e.preventDefault();

      //verifica se esta selecionado quente e frio, caso esteja ele envia o ciclo com string vazia
      var ciclo = "";
      if (form?.ciclo?.length == 3 || form?.ciclo?.length == "") {
        ciclo = "";
      } else {
        ciclo = cicSelected[0]?.id;
      }

      pushToDataLayer(
        'select_item',
        {
          ecommerce: {
            currency: 'BRL',
            items: {
              item_grupo: form?.grupos,
              item_categoria: form?.categoria,
              item_fabricante: form?.fabricante?.split(','),
              item_capacidade: form?.capacidade?.split(',')
            }
          }
        },
        { clearEcommerce: true }
      );

      dispatch(getOrderProducts({ ...form, ciclo }));

      setScrollButton(state => !state);
    }
  }

  useEffect(() => {
    if (document && window && scrollButton != null) {
      scrollToDiv("sentinel", 200);
    }
  }, [scrollButton]);

  function clearCheckboxes() {
    let capacidade = document.getElementsByName("capacidade");
    for (let cap of capacidade) {
      cap.checked = false;
    }
    let ciclo = document.getElementsByName("ciclo");
    for (let cic of ciclo) {
      cic.checked = false;
    }
    let voltagem = document.getElementsByName("voltagem");
    for (let vol of voltagem) {
      vol.checked = false;
    }
    let fabricante = document.getElementsByName("fabricante");
    for (let fab of fabricante) {
      fab.checked = false;
    }
    form.capacidade = "";
    form.fabricante = "";
    form.ciclo = "";
    form.voltagem = "";

    setCapSelected([]);
    setCicSelected([]);
    setFabSelected([]);
    setVolSelected([]);
  }

  useEffect(() => {
    if (
      !(
        newOrder.tabela &&
        newOrder.condPagto &&
        newOrder.grupos &&
        newOrder.categorias
      )
    )
      dispatch(getNewOrder());
  }, []);

  useEffect(() => {
    if (newOrder) {
      let tab = [];
      Object.keys(newOrder.tabela || {}).map(function (key) {
        tab.push({ codigo: key, label: newOrder.tabela[key].trim() });
      });

      let pag = [];
      let pagto = Object.values(newOrder.condPagto || []);
      let pag_keys = Object.keys(newOrder.condPagto || []);

      for (let i = pagto.length; i >= 0; i--) {
        Object.keys(pagto[i] || {}).map(function (key) {
          pag.push({
            codigo: key,
            label: pagto[i][key].name?.trim(),
            type: pag_keys[i],
          });
        });
      }

      let gru = [];
      Object.values(newOrder.grupos || {}).map(value => {
        gru.push({ codigo: value.CODIGO.trim(), label: value.DESCR.trim() });
      });

      let cat = [];
      Object.values(newOrder.categorias || []).map(value => {
        cat.push({ codigo: value.CODIGO.trim(), label: value.DESCR.trim() });
      });

      let cap = Object.values(newOrder.capacidade || []);
      let volt = Object.values(newOrder.voltagem || []);
      let fab = Object.values(newOrder.fabricantes || []);
      setFilters({
        ...filters,
        tabela: tab,
        condPagto: pag,
        grupos: gru,
        categorias: cat,
        capacidade: cap,
        voltagem: volt,
        fabricantes: fab,
      });

      if (count <= 5) {
        setInitial({
          tabela: tab,
          condPagto: pag,
          grupos: gru,
          categorias: cat,
          capacidade: cap,
          voltagem: volt,
          fabricantes: fab,
        });

        if (order) {
          if (tabela.length < 1 && tab[0]?.codigo) {
            let result = tab.find(i => i.codigo == order.TabelaPreco);
            if (result) dispatch(setTabela([result]));
          }

          if (pagamento.length < 1 && pag[0]?.codigo) {
            let result = pag.find(i => i.codigo == order.CondicaoPagamento);
            if (result) setPagamento([result]);
          }
          
          setForm({
            ...form,
            cepentrega: order.CEPEntrega
          });
        }

        if (grupo.length < 1 && gru[0]?.codigo) {
          let result = gru.find(i => i.codigo == "INV");
          if (result) setGrupo([result]);
        }

        if (categoria.length < 1 && cat[0]?.codigo) {
          let result = cat.find(i => i.codigo == "HIW");
          if (result) setCategoria([result]);
        }
        setForm({
          ...form,
          tabela_preco: newOrder.tabelaInicial,
          condpagto: "500",
          grupos: "INV",
          categoria: "HIW",
        });
      }

      setCount(count + 1);
    }
  }, [newOrder, order]);

  useEffect(() => {
    let table = "";
    if (tabela.length > 0) {
      table = tabela[0].codigo;
      dispatch(changePagto(table));
    }
    setForm({ ...form, tabela_preco: table });
  }, [tabela]);

  useEffect(() => {
    let pagto = "";
    if (pagamento.length > 0) {
      pagto = pagamento[0]?.codigo || "";
      pedido.filters.Nomecondpagto = pagamento[0]?.label || "";
      pedido.filters.condpagto = pagamento[0]?.codigo || "";
      pedido.filters.Nometabela_preco = tabela[0]?.label || "";
      pedido.filters.tabela_preco = tabela[0]?.codigo || "";
      pedido.filters.vendedor2 = parceiros[0]?.codigo || "";
      dispatch(
        setFilterPedido({
          ...filterPedido,
          Nomecondpagto: pagamento[0]?.label || "",
          condpagto: pagamento[0]?.codigo || "",
          Nometabela_preco: tabela[0]?.label || "",
          tabela_preco: tabela[0]?.codigo || "",
          vendedor2: parceiros[0]?.codigo || "",
        })
      );
    }
    setForm({ ...form, condpagto: pagto });

    dispatch(setPagtoSelected(pagamento));
  }, [pagamento, parceiros, history?.location?.pathname]);

  useEffect(() => {
    let gru = "";
    if (grupo.length > 0) {
      gru = grupo[0].codigo;
      if (count > 6) {
        setCategoria([]);
      }
      setFilters({
        ...filters,
        capacidade: initial.capacidade,
      });
      dispatch(changeGroup(gru));
    } else {
      if (filters.length > 0) {
        setFilters({
          ...filters,
          capacidade: initial.capacidade,
          categorias: initial.categorias,
        });
      }
    }
    // if(catSelector.current)
    /* catSelector.current.clear(); */
    clearCheckboxes();
    setForm({
      ...form,
      grupos: gru,
      categoria: "",
    });
  }, [grupo]);

  useEffect(() => {
    let cat = "";
    if (categoria.length > 0) {
      cat = categoria[0].codigo;
      if (grupo[0] && grupo[0].codigo) {
        let paramns = {
          group: grupo[0].codigo,
          category: cat,
        };

        dispatch(changeCategory(paramns));
      } else {
        setCategoria([]);
      }
    } else {
      if (filters.length > 0) {
        setFilters({
          ...filters,
          capacidade: initial.capacidade,
        });
      }
    }
    clearCheckboxes();
    setForm({ ...form, categoria: cat });
  }, [categoria]);
  //manter a ordem dos fabricantes ao clicar em ver todas as opçoes
  const formatDataArray = () => {
    fabs.map(item => {
      filters.fabricantes.map((itemTwo, index) => {
        if (item.CODIGO == itemTwo.CODIGO) {
          filters.fabricantes.splice(index, 1);
        }
      });
    });

    const concat = fabs.concat(filters.fabricantes);

    setArrayFab(concat);
  };

  if (!pagamento || pagamento.length === 0) {
    if (filters && filters.condPagto && filters.condPagto.length > 0) {
      pedido.filters.Nomecondpagto = pagamento[0]?.label || "";
      pedido.filters.condpagto = pagamento[0]?.codigo || "";
    }
  }

  if (!tabela || tabela.length === 0) {
    if (filters && filters.tabela && filters.tabela.length > 0) {
      pedido.filters.Nometabela_preco = tabela[0]?.label || "";
      pedido.filters.tabela_preco = tabela[0]?.codigo || "";
    }
  }

  useEffect(() => {
    if (fabVisible == "first") {
      setArrayFab(fabs);
    } else {
      formatDataArray();
    }
  }, [fabVisible]);
  
  return (
    <></>
  );
};

export default OrderFilter;
