import React, { useState, useEffect } from "react";
import { CardBody, Card, Input, FormGroup, Label, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearNewOrder, getFreteValue } from "../../store/actions";
import { filiais } from "../../utils/filiais";

const Frete = ({
  valTot,
  addFrete,
  newAddItemsSeparadoPorFilial,
  awaitModfyCart,
  setAwaitModfyCart,
}) => {
  const dispatch = useDispatch();
  const { frete, newOrder } = useSelector(state => ({
    frete: state.Order.newOrder.newOrder.frete,
    newOrder: state.Order.newOrder.newOrder,
  }));

  const address = frete.address;
  const [valor, setValor] = useState(0);
  const [, setBtnFreteActive] = useState(false);
  const [, setCep] = useState("");
  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };

  useEffect(() => {
    dispatch(clearNewOrder());
    setValor(0);
    setBtnFreteActive(false);
    setCep("");
  }, []);
  useEffect(() => {
    if (address?.zipcode && valTot > 0) {
      if (awaitModfyCart == "close") {
        return;
      }

      dispatch(
        getFreteValue(
          valTot,
          "01",
          "01",
          "",
          newAddItemsSeparadoPorFilial,
          false
        )
      );
      addFrete({});
      setValor(0);
      let frete = document.getElementsByName("frete");
      for (let fre of frete) {
        fre.checked = false;
      }
    }
  }, [address?.zipcode, valTot, awaitModfyCart]);

  return (
    <Card>
      <CardBody>
        <h4
          style={{
            fontSize: 23,
            fontWeight: "bold",
            color: "#202833",
            marginBottom: 35,
          }}
        >
          Calcular frete e prazo de entrega
        </h4>
        <p className="text-muted">
          <small>
            Partindo de:{" "}
            {newAddItemsSeparadoPorFilial.map((item, k) =>
              newAddItemsSeparadoPorFilial.length == k + 1
                ? filiais[Number(item.CODFILIAL)]
                : filiais[Number(item.CODFILIAL)] + ", "
            )}
          </small>
        </p>
        <p style={{ margin: "9px 0 0 0" }}>
          CEP:{" "}
          {frete?.address?.zipcode ? (
            <span>{frete?.address?.zipcode}</span>
          ) : (
            <small className="text-muted">Nenhum cep informado</small>
          )}
        </p>
        <p style={{ margin: "9px 0 0 0" }}>
          {frete.address ? (
            <span>
              {frete.address.address} - {frete.address.city}/
              {frete.address.state}
            </span>
          ) : (
            <span />
          )}
        </p>

        {frete.success ? (
          <div>
            <hr style={{ margin: "25px 0" }} />
            <h4
              style={{
                fontSize: 23,
                fontWeight: "bold",
                color: "#202833",
                marginBottom: 15,
                display: "flex",
                flexDirection: "column",
              }}
            >
              Selecione a modalidade{" "}
            </h4>
            <div
              onChange={e => {
                setValor(e.target.value);
              }}
            >
              {frete.entrega.map((value, index) => (
                <FormGroup check className="d-flex mt-3" key={index}>
                  <Input
                    type="radio"
                    value={value.price}
                    name="frete"
                    className="modalidade"
                    onChange={() => addFrete(value)}
                  />
                  <Label
                    className={valor == value.price ? "label-prazo-active" : ""}
                    style={{ marginLeft: 10, fontWeight: 400 }}
                  >
                    {value.description}{" "}
                    {value.usado_percentual > 0 ? (
                      <span style={{ margin: "0 5px" }}>%</span>
                    ) : (
                      ""
                    )}{" "}
                    - *Prazo até {value.delivery_delay} dia(s) úteis -{" "}
                    {Number(
                      value?.priceSomado ? value?.priceSomado : value.price
                    ).toLocaleString("pt-br", formato)}
                  </Label>
                </FormGroup>
              ))}
              <span
                style={{ fontSize: 12, fontWeight: "normal", color: "#495057" }}
              >
                *Dias Úteis Após Emissão da NF-e
              </span>
            </div>
          </div>
        ) : null}
        {frete.loading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 121,
            }}
          >
            <Spinner size="sm" />
            <span style={{ color: "#555b62" }}>Buscando fretes</span>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Frete;
