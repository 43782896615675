import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AddedItem from "../AddedItem";
import { useSelector } from "react-redux";
import { formato } from "../../../utils";

const CartProducts = ({
  setValServ,
  setSendValServ,
  addValTot,
  valores,
  setItensCarrinho,
  itensCarrinho,
  valArquiteto,
  addValArqu,
  valorRt,
  addValorRt,
  setPercentResumo,
  setQtdAdd,
  valTot,
}) => {
  const { newOrder } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
    };
  });
  return (
    <Row className="mt-4" id="carrinho">
      <Col>
        <h4
          className="mb-4"
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "#2D567B",
          }}
        >
          Produtos Adicionados
        </h4>

        {newOrder.addedItems.map(item => (
          <AddedItem
            key={item.cod}
            item={item}
            setValServ={setValServ}
            setSendValServ={setSendValServ}
            addValTot={addValTot}
            valores={valores}
            setItensCarrinho={setItensCarrinho}
            itensCarrinho={itensCarrinho}
            valArquiteto={valArquiteto}
            addValArqu={addValArqu}
            valorRt={valorRt}
            addValorRt={addValorRt}
            setPercentResumo={setPercentResumo}
            setQtdAdd={setQtdAdd}
          />
        ))}

        <Card
          style={{
            borderRadius: "0 0  15px 15px",
            backgroundColor: "#f2f5ff",
            zIndex: 100,
            marginTop: -15,
          }}
        >
          <CardBody>
            <Row className="d-flex justify-content-end">
              <Col md={4} className="d-flex justify-content-between">
                <h4
                  className="mb-0"
                  style={{
                    color: "rgb(0, 86, 150)",
                    fontWeight: "bold",
                  }}
                >
                  Subtotal
                </h4>
                <h4
                  className="mb-0"
                  style={{
                    color: "rgb(0, 86, 150)",
                    fontWeight: "bold",
                  }}
                >
                  {Number(valTot).toLocaleString("pt-br", formato)}
                </h4>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CartProducts;
