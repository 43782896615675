import { OrderActionTypes } from "./actionTypes";

export const orderApiSuccess = (actionType, data) => ({
  type: OrderActionTypes.API_RESPONSE_SUCCESS,

  payload: { actionType, data },
});

export const orderApiFail = (actionType, error) => ({
  type: OrderActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// export const getOrdersList = filters => ({
//   type: OrderActionTypes.GET_ORDER_LIST,
//   payload: filters,
// });

export const getOrderActive = (orderNum, company, filial) => ({
  type: OrderActionTypes.GET_ORDER_ACTIVE,
  payload: { orderNum, company, filial },
});

export const getDocuments = () => ({
  type: OrderActionTypes.GET_DOCUMENTS,
});

export const getPayments = () => ({
  type: OrderActionTypes.GET_PAYMENT,
});

export const getGfe = () => ({
  type: OrderActionTypes.GET_GFE,
});

export const getSlips = () => ({
  type: OrderActionTypes.GET_SLIPS,
});

export const getProducts = () => ({
  type: OrderActionTypes.GET_PRODUCTS,
});

export const clearOrderError = () => ({
  type: OrderActionTypes.CLEAR_ORDER_ERROR,
});

export const getNewOrder = (data, history) => ({
  type: OrderActionTypes.GET_NEW_ORDER,
  payload: { data: data, history: history },
});

export const getFilters = filial => ({
  type: OrderActionTypes.GET_FILTERS,
  payload: filial
})

export const changeGroup = group => ({
  type: OrderActionTypes.CHANGE_GROUP,
  payload: group,
});

export const changeCategory = category => ({
  type: OrderActionTypes.CHANGE_CATEGORY,
  payload: category,
});

export const changePagto = tabela => ({
  type: OrderActionTypes.CHANGE_PAGTO,
  payload: tabela,
});

export const checkCartRuleOnChange = pedido => ({
  type: OrderActionTypes.CART_RULE_UPDATE,
  payload: pedido,
});


export const getOrderProducts = filter => ({
  type: OrderActionTypes.GET_ORDER_PRODUCTS,
  payload: filter,
});

export const getProductDetails = sku => ({
  type: OrderActionTypes.GET_PRODUCT_DETAILS,
  payload: sku,
});

export const addToCart = (item, pedido) => ({
  type: OrderActionTypes.ADD_ITEM_TO_CART,
  payload: {
    item: item,
    pedido: pedido,
  },
});

export const addItemsToCart = (items) => ({
  type: OrderActionTypes.ADD_ITEMS_TO_CART,
  payload: {
    items: items,
  },
});

export const addToCartWithoutKit = (item, pedido) => ({
  type: OrderActionTypes.ADD_ITEM_TO_CART_WITHOUT_KIT,
  payload: {
    item: item,
    pedido: pedido,
  },
});

export const editQtdKits = (name, qtd, stock) => ({
  type: OrderActionTypes.EDIT_QTD_KITS,
  payload: {
    name: name,
    qtd: qtd,
    stock: stock,
  },
});

export const editQtdWithoutKit = (name, qtd, stock) => ({
  type: OrderActionTypes.EDIT_QTD_WITHOUT_KIT,
  payload: {
    name: name,
    qtd: qtd,
    stock: stock,
  },
});

export const editPrecoCliente = (name, acrescimo) => ({
  type: OrderActionTypes.EDIT_PRECOCLIENTE_KITS,
  payload: {
    name: name,
    acrescimo: acrescimo,
  },
});

export const editPrecoClienteWithoutKit = (name, acrescimo) => ({
  type: OrderActionTypes.EDIT_PRECOCLIENTE_WITHOUT_KIT,
  payload: {
    name: name,
    acrescimo: acrescimo,
  },
});

export const removeAllItems = () => ({
  type: OrderActionTypes.REMOVE_ITEM_TO_CART,
});

export const removeFromCart = item => ({
  type: OrderActionTypes.REMOVE_ITEM_FROM_CART,
  payload: item,
});

export const removeFromCartKits = item => ({
  type: OrderActionTypes.REMOVE_ITEM_FROM_CART_KITS,
  payload: item,
});

export const removeFromCartWithoutKit = item => ({
  type: OrderActionTypes.REMOVE_ITEM_FROM_CART_WITHOUT_KIT,
  payload: item,
});

export const addEndValido = endereco => ({
  type: OrderActionTypes.ADD_ENDERECO_VALIDO,
  payload: endereco,
});

export const getFreteValue = (
  valor,
  company,
  filial,
  date,
  newAddItemsSeparadoPorFilial,
  cancelRequestDuplicate
) => ({
  type: OrderActionTypes.GET_FRETE_VALUE,
  payload: {
    valor: valor,
    company: company,
    filial: filial,
    date: date,
    newAddItemsSeparadoPorFilial: newAddItemsSeparadoPorFilial,
    cancelRequestDuplicate: cancelRequestDuplicate,
  },
});

export const getCustomer = obj => ({
  type: OrderActionTypes.GET_CUSTOMER,
  payload: obj,
});

export const getSaldo = () => ({
  type: OrderActionTypes.GET_SALDO,
});

export const emitirPedido = pedido => ({
  type: OrderActionTypes.EMITIR_PEDIDO,
  payload: pedido,
});

export const editarPedido = pedido => ({
  type: OrderActionTypes.EDITAR_PEDIDO,
  payload: pedido,
});

export const zerarCarrinho = () => ({
  type: OrderActionTypes.ZERAR_CARRINHO,
});

export const getItemTable = item => ({
  type: OrderActionTypes.GET_ITEM_TABLE,
  payload: item,
});

export const getCuponsNotUsed = () => ({
  type: OrderActionTypes.GET_CUPONS_NOT_USED,
});

export const verificarCupom = cupom => ({
  type: OrderActionTypes.VERIFICAR_CUPOM,
  payload: cupom,
});

export const cancelOrder = order => ({
  type: OrderActionTypes.CANCEL_ORDER,
  payload: order,
});

export const clearCustomer = () => ({
  type: OrderActionTypes.CLEAR_CUSTOMER,
});

export const resetFreteValue = () => ({
  type: OrderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType: OrderActionTypes.GET_FRETE_VALUE_RESET },
});

export const getArquitetos = () => ({
  type: OrderActionTypes.GET_ARQUITETOS,
});

export const addArquiteto = data => ({
  type: OrderActionTypes.ADD_ARQUITETO,
  payload: data,
});

export const atualizaCarrinho = (pgto, pedido) => ({
  type: OrderActionTypes.ATUALIZA_CARRINHO,
  payload: { pgto, pedido },
});

export const addProductBySku = product => ({
  type: OrderActionTypes.ADD_PRODUCT_BY_SKU,
  payload: product,
});

export const clearNewOrder = () => ({
  type: OrderActionTypes.CLEAR_NEW_ORDER,
});

export const removeOrderActive = () => ({
  type: OrderActionTypes.REMOVE_ORDER_ACTIVE,
});

export const verfyEmailExists = data => ({
  type: OrderActionTypes.VERIFY_EMAIL_EXISTS,
  payload: data,
});

export const resetAllDataOrder = () => ({
  type: OrderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType: OrderActionTypes.RESET_ALL_DATA },
});

export const ocultarRT = data => ({
  type: OrderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType: OrderActionTypes.OCULTAR_RT, data },
});

export const setPagtoSelected = data => ({
  type: OrderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType: OrderActionTypes.PAGTO_SELECTED, data },
});

export const setFilterPedido = data => ({
  type: OrderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType: OrderActionTypes.FILTER_PEDIDO, data },
});

export const setParceiro = data => ({
  type: OrderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType: OrderActionTypes.PARCEIROS, data },
});

export const setTabela = data => ({
  type: OrderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType: OrderActionTypes.TABELA, data },
});

export const removePromotionsAndCoupons = () => ({
  type: OrderActionTypes.REMOVE_PROMOTIONS_AND_COUPONS,
});