import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  Button,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import RoundAlert from "../../../components/RoundAlert";
import SvgIcon from "../../../components/SvgIcon";
import { useSelector, useDispatch } from "react-redux";
import { formatInvertDate } from "../../../utils";
import { cancelOrder } from "../../../store/actions";
import { useSolar } from "../../../hooks";
import { isUnitless } from "@mui/material/styles/cssUtils";
import { filiais } from "../../../utils/filiais";
import { useHistory } from "react-router-dom";
import { apiClient } from "../../../api/apiCore";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { CheckCircle, Info } from "phosphor-react";
import ButtonPedidoRemessa from "./ButtonPedidoRemessa";
import { useDataLayer } from "../../../hooks/DataLayerHooks";
import { useColors } from "../../../hooks/useColors";
import ModalTriangularShipping from "./ModalTriangularShipping";

const Status = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSolar = useSolar();

  const { colorDefault } = useColors();

  const { pushToDataLayer } = useDataLayer();
  const [motivo, setMotivo] = useState("43");
  const [modalOpen, setModalOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [cancelFase, setCancelFase] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [isOpenGerarRemessa, setIsOpenGerarRemessa] = useState(false);
  const [loadingGerarRemessa, setLoadingGerarRemessa] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { order, orderActive } = useSelector(state => ({
    order: state.Order.orderActive.order,
    orderActive: state.Order.orderActive,
  }));
  const sendTrackingOfwhats = `Olá ${order?.cliente?.N_REDZ
    }, Você já pode acompanhar a entrega do seu pedido #${order?.NumPedido
    } , clique no link https://clube.adias.com.br/tracker/${order?.NumPedido
    }/${order?.CpfCnpjCliente?.trim()}?x=clube`;

  const trackBack = `https://clube.adias.com.br/tracker/${order?.Nota
    }/${order?.CpfCnpjCliente?.trim()}?x=clube`;

  const getDeAcordoLink = (numPedido, cpfCnpj) => {
    if (isSolar) {
      return 'https://deacordo.adiassolar.com.br/action.php?action=find_order&cod_pedido=' + numPedido + '&cpfcnpj=' + cpfCnpj;
    }

    return 'http://deacordo.mpsdistribuidora.com.br/action.php?action=find_order&cod_pedido=' + numPedido + '&cpfcnpj=' + cpfCnpj;
  }

  var accordingText =
    `Olá ${order?.cliente?.N_REDZ}, 
  seu pedido #${order?.NumPedido ? order.NumPedido : ''} está disponivel para ser assinado, 
  clique no link ${getDeAcordoLink(order?.NumPedido ? order.NumPedido : '', order?.CpfCnpjCliente ? order?.CpfCnpjCliente?.trim() : '')} imprima o pedido, 
  assine e nos envie por e-mail assim que possível. Obrigado e parabéns pela Compra!`;

  var viewRequest = `Olá ${order?.cliente?.N_REDZ}, recebemos o seu pedido #${order?.NumPedido
    } para visualizá-lo basta acessar o link http://deacordo.adias.com.br/action.php?action=find_order&cod_pedido=${order?.NumPedido
    }&cpfcnpj=${order?.CpfCnpjCliente?.trim()} agradecemos pela preferência.`;

  const valores = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    S: 6,
    F: 7,
    G: 8,
    H: 9,
    I: 10,
    J: 11,
    K: 12,
    L: 13,
  };

  function selectDataFase(valor) {
    var situacaoAtual = order?.Situacao;

    if (
      ((situacaoAtual == "J" && valor == "J") ||
        (situacaoAtual == "H" && valor == "J")) &&
      order?.entrega
    ) {
      return <p dangerouslySetInnerHTML={{ __html: order.entrega }} />;
    }

    const lastEntry = getLastStatus(valor);

    if (!lastEntry) {
      if (order?.gfe) {
        if (valor == "H") {
          return getStatusExbition(
            formatInvertDate(order.gfe.GW1_DTSAI),
            order.gfe.GW1_HRSAI
          );
        }
      }

      if (
        (situacaoAtual == "K" && valor == "J") ||
        (situacaoAtual == "L" && valor == "J")
      ) {
        return getStatusExbition(
          formatInvertDate(
            order.fila_historico[order.fila_historico.length - 1].dtent
          ),
          order.fila_historico[order.fila_historico.length - 1].hrent
        );
      }

      if (valores[valor] < valores[situacaoAtual]) {
        return formatInvertDate(order.DataEmissao);
      }

      return null;
    }

    return getStatusExbition(
      formatInvertDate(lastEntry?.dtsai?.trim() != "" ? lastEntry.dtsai : lastEntry.dtent),
      lastEntry?.hrsai?.trim() != "" ? lastEntry.hrsai : lastEntry.hrent
    );
  }

  const getLastStatus = valor => {
    const filteredHistoryByStatus = order?.fila_historico?.filter(item => item.situacao === valor) || [];

    if (!filteredHistoryByStatus || filteredHistoryByStatus.length === 0) {
      return null;
    }

    let sortedHistoryByLastDate = filteredHistoryByStatus[0];

    filteredHistoryByStatus.forEach(status => {
      const statusDate = status.dtsai ? status.dtsai : status.dtent;
      const statusTime = status.hrsai ? status.hrsai : status.hrent;

      const sortedHistoryDate = sortedHistoryByLastDate.dtsai ? sortedHistoryByLastDate.dtsai : sortedHistoryByLastDate.dtent;
      const sortedHistoryTime = sortedHistoryByLastDate.hrsai ? sortedHistoryByLastDate.hrsai : sortedHistoryByLastDate.hrent;

      if (statusDate > sortedHistoryDate || (statusDate == sortedHistoryDate && statusTime > sortedHistoryTime)) {
        sortedHistoryByLastDate = status;
      }
    });

    return sortedHistoryByLastDate;
  }

  const getStatusExbition = (date, hour) => {
    return (<>
      <p className="text-muted mb-0 font-size-13">
        {date}
      </p>
      <p className="text-muted mb-0 font-size-13">
        {hour}
      </p>
    </>)
  }

  const motivosCancelamento = JSON.parse(
    orderActive?.motivosCancelamento || "[]"
  );

  const bloqueio =
    order?.fila_historico?.length > 0
      ? order?.fila_historico[(order?.fila_historico?.length || 1) - 1]
        .desc_mot_bloq
      : "";

  const toggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  function sendMessage(phone, text) {
    text = window.encodeURIComponent(text);

    window.open(
      "https://api.whatsapp.com/send?phone=" + phone + "&text=" + text,
      "_blank"
    );
    //Obs.. use "_system", no lugar de blank, caso você esteja usando Phonegap / Cordova / Ionic ou qualquer um baseado em webview;
  }

  // useEffect(() => {
  //   if (motivosCancelamento.length > 0 && motivo !== "") {
  //     setMotivo(motivosCancelamento[0].value);
  //   }
  // }, [motivosCancelamento]);

  const gerarPedidoRemessa = async () => {
    setLoadingGerarRemessa(true);
    const obj = {
      NumPedido: order.NumPedido,
      CodFilial: order.CodFilial,
      PedCli: order.XPEDCLI,
      CodEmpresa: "02",
    };
    try {
      const resp = await apiClient.create(`/api/orders/future/storeShipping`, obj);
      console.log("resp", resp);
      var numPedido = resp.NUMPED;
      toast.success("Pedido Remessa gerado com sucesso!", { theme: "colored" });
      history.push(`/solar/pedidos/${numPedido}/${order.CodFilial}/order`);
    } catch (err) {
      toast.error(err, { theme: "colored" });
    }
    setLoadingGerarRemessa(false);
    setIsOpenGerarRemessa(false);
  };

  const getPaymentLink = (orderId, cpfCnpj) => {
    if (isSolar) {
      return `https://pagamento.adiassolar.com.br/hpp/pedido/${orderId}/cpfcnpj/${cpfCnpj}`;
    }

    return `https://pagamento.mpsdistribuidora.com.br/hpp/pedido/${orderId}/cpfcnpj/${cpfCnpj}`;
  }

  const onConfirm = () => {
    gerarPedidoRemessa();
  };
  const onClose = () => {
    setIsOpenGerarRemessa(state => !state);
  };

  const generateDataLayer = () => {
    const items = getItemsToDataLayer();
    pushToDataLayer(
      'cancelar_pedido',
      {
        ecommerce: {
          currency: 'BRL',
          value: order.total,
          transaction_id: order.NumPedido,
          shipping: order.C5_FRETE,
          items
        }
      },
      { clearEcommerce: true }
    );
  }

  const getItemsToDataLayer = () => {
    if (isSolar && order?.items?.length > 0) {
      return order.items.map(product => ({
        item_id: product.Produto,
        item_name: product.DescProd,
        price: product.Valor,
        quantity: product.Qtdven,
      }))
    } else {
      return order?.items_orcamento?.map(product => ({
        item_id: product.Produto,
        item_name: product.DescProd,
        item_brand: product.Fabricante,
        item_category: product.Categoria,
        price: product.Valor,
        quantity: product.Qtdven
      }));
    }
  }

  return (
    <React.Fragment>
      <div className="sessao-titulo d-flex justify-content-between align-items-center mb-4">
        <div className="titulos">
          <h2 className="numero-pedido">Pedido {order?.NumPedido || 0}</h2>
          <p className="texto-pedido mb-0">
            {filiais[Number(order?.CodFilial || 0)]}
          </p>
        </div>
        <div className="menu d-flex align-items-center">
          <a href="#" className="menu-link">
            Página Inicial
          </a>
          <a href="#" className="menu-link-icone d-flex align-items-center">
            {" "}
            <i className="ph-caret-right"></i>
          </a>
          <a href="#" className="menu-link">
            Consultar Pedidos
          </a>
        </div>
      </div>
      <Card>
        <CardBody>
          <Row className="mb-4">
            <Col lg={12}>
              <div className="py-3">
                <ul className="list-unstyled track-order-list flex-wrap">
                  <li
                    className={valores[order?.Situacao] > 1 ? "completed" : ""}
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="clipboard-text-2"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line">
                      <i className="mdi mdi-circle-outline text-white check-icon" style={{ backgroundColor: "#13b013" }}></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">Análise do Pedido</span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("A")}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={valores[order?.Situacao] > 4 ? "completed" : ""}
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="credit-card-2"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line">
                      <i className="mdi mdi-circle-outline check-icon"></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">Analise de Crédito</span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("C")}
                        </p>
                      </div>
                    </div>
                  </li>
                  {isSolar && (
                    <li
                      className={valores[order?.Situacao] > 6 ? "completed" : ""}
                    >
                      <div className="order-icon mb-3">
                        <SvgIcon
                          name="cursor-grab"
                          className="icon icon-lg display-4"
                        />
                      </div>
                      <div className="progress-line">
                        <i className="mdi mdi-circle-outline check-icon"></i>
                      </div>
                      <div className="overflow-hidden mt-4">
                        <span className="step-title">Em produção</span>
                        <div>
                          <p className="text-muted mb-0 font-size-13">
                            {selectDataFase("S") ? selectDataFase("S") : selectDataFase("F")}
                          </p>
                        </div>
                      </div>
                    </li>
                  )}
                  <li
                    className={valores[order?.Situacao] > 7 ? "completed" : ""}
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="file-text-2"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line">
                      <i className="mdi mdi-circle-outline check-icon"></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">Faturamento</span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("F")}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={valores[order?.Situacao] > 8 ? "completed" : ""}
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="shipment"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line">
                      <i className="mdi mdi-circle-outline check-icon"></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">Expedição</span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("G")}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={valores[order?.Situacao] > 9 ? "completed" : ""}
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="truck-2"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line">
                      <i className="mdi mdi-circle-outline check-icon"></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">Em Trânsito</span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("H")}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={
                      valores[order?.Situacao] >= 11 ? "completed" : ""
                    }
                  >
                    <div className="order-icon mb-3">
                      <SvgIcon
                        name="package-2"
                        className="icon icon-lg display-4"
                      />
                    </div>
                    <div className="progress-line" style={{ flexGrow: 0 }}>
                      <i className="mdi mdi-circle-outline check-icon"></i>
                    </div>
                    <div className="overflow-hidden mt-4">
                      <span className="step-title">
                        {order?.Situacao == "L"
                          ? "Devolução Total"
                          : order?.Situacao == "K"
                            ? "Devolução Parcial"
                            : order?.Situacao == "H"
                              ? "Previsão de Entrega"
                              : "Entregue"}
                      </span>
                      <div>
                        <p className="text-muted mb-0 font-size-13">
                          {selectDataFase("J")}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row className="mt-3 d-flex align-items-center">
            {bloqueio ? (
              <Col md={9} className="d-flex align-items-center">
                <RoundAlert className="botao-cancelado">
                  <i className="mdi mdi-information-outline font-size-18 me-2" />{" "}
                  Pedido Bloqueado - {bloqueio}
                </RoundAlert>
              </Col>
            ) : (
              <Col md={9} />
            )}
            <Col md={3} className="text-end">
              <Button
                className={`btn ${isSolar ? "ver-mais-solar" : "ver-mais"}`}
                onClick={() => setModalOpen(!modalOpen)}
              >
                VER ATIVIDADE
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row>
        <Col md={12}>
          <div className="button-items float-end flex-wrap">
            {valores[order?.Situacao] <= 5 ? (
              <Dropdown isOpen={dropDownOpen} toggle={toggle}>
                <DropdownToggle
                  className={`btn btn-rounded bg-white shadow margin-left-3 px-3 ${isSolar ? " botoes-itens-solar" : " botoes-itens"}`}
                  caret
                >
                  Compartilhar Links
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className={isSolar ? " botoes-itens-solar" : " botoes-itens"}
                    onClick={() =>
                      window.open(getPaymentLink(order?.NumPedido || "", order?.CpfCnpjCliente || ""))
                    }
                  >
                    Link de pagamento
                  </DropdownItem>
                  <DropdownItem
                    className={isSolar ? " botoes-itens-solar" : " botoes-itens"}
                    onClick={() =>
                      sendMessage(
                        order?.DDI + order?.cliente.DDD + order?.cliente.TEL,
                        accordingText
                      )
                    }
                  >
                    De acordo
                  </DropdownItem>
                  <DropdownItem
                    className={isSolar ? " botoes-itens-solar" : " botoes-itens"}
                    onClick={() =>
                      sendMessage(
                        order?.DDI + order?.cliente.DDD + order?.cliente.TEL,
                        viewRequest
                      )
                    }
                  >
                    Link do Pedido
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : null}
            {valores[order?.Situacao] <= 5 ? (
              <a
                rel="noreferrer"
                className={`btn btn-rounded bg-white shadow me-3 px-3 botoes-itens ${isSolar ? " botoes-itens-solar" : " botoes-itens"}`}
                target="_blank"
                href={
                  isSolar
                    ? `https://pagamento.adiassolar.com.br/hpp/pedido/${order?.NumPedido || ""
                    }/cpfcnpj/${order?.CpfCnpjCliente?.trim()}`
                    : `https://pagamento.mpsdistribuidora.com.br/hpp/pedido/${order?.NumPedido || ""
                    }/cpfcnpj/${order?.CpfCnpjCliente?.trim()}`
                }
              >
                <i className="ph-currency-circle-dollar icones-status"></i>
                Pagamentos
              </a>
            ) : null}

            {valores[order?.Situacao] <= 5 ? (
              <a
                href={
                  isSolar
                    ? `http://deacordo.adiassolar.com.br/action.php?action=find_order&cod_pedido=${order?.NumPedido
                    }&cpfcnpj=${order?.CpfCnpjCliente?.trim()}`
                    : `http://deacordo.mpsdistribuidora.com.br/action.php?action=find_order&cod_pedido=${order?.NumPedido
                    }&cpfcnpj=${order?.CpfCnpjCliente?.trim()}`
                }
                target="_blank"
                rel="noreferrer"
                className={`btn btn-rounded bg-white shadow mx-3 px-3 botoes-itens ${isSolar ? " botoes-itens-solar" : " botoes-itens"}`}
              >
                <i className="ph-handshake icones-status"></i>
                De Acordo
              </a>
            ) : null}
            {/* {valores[order?.Situacao] > 6 ? (
              <button
                onClick={() =>
                  sendMessage(
                    order?.DDI + order?.cliente?.DDD + order?.cliente?.TEL,
                    sendTrackingOfwhats
                  )
                }
                className="btn btn-rounded bg-white shadow mx-3 px-3 botoes-itens"
              >
                <i className="ph-whatsapp-logo icones-status"></i>
                Enviar Rastreamento WhatsApp
              </button>
            ) : null} */}
            {/* {valores[order?.Situacao] > 6 ? (
              <a
                href={trackBack}
                target="_blank"
                rel="noreferrer"
                className="btn btn-rounded bg-white shadow mx-3 px-3 botoes-itens"
              >
                <i className="ph-truck-bold icones-status"></i>
                Rastrear
              </a>
            ) : null} */}

            {valores[order?.Situacao] <= 5 && (
              <button
                onClick={() => setCancelOpen(!cancelOpen)}
                className={`btn btn-rounded bg-white shadow me-3 px-3 botoes-itens ${isSolar ? " botoes-itens-solar" : " botoes-itens"}`}
              >
                <i className="ph-x icones-status icones-status"></i>
                Cancelar Pedido
              </button>
            )}
            {valores[order?.Situacao] > 7 ? (
              <a
                href={`https://adias.freshdesk.com/support/tickets/new`}
                target="_blank"
                rel="noreferrer"
                className={`btn btn-rounded bg-white shadow me-3 px-3 ${isSolar ? " botoes-itens-solar" : " botoes-itens"}`}
              >
                <i className="ph-megaphone icones-status"></i>
                Pós Vendas
              </a>
            ) : null}

            <ButtonPedidoRemessa onClose={onClose} />

            <ModalTriangularShipping
              setOpenModal={setOpenModal}
              openModal={openModal}>
            </ModalTriangularShipping>

            <button
              onClick={() => history.goBack()}
              className={`btn btn-rounded bg-white shadow mx-3 px-3 ${isSolar ? " botoes-itens-solar" : " botoes-itens"}`}
            >
              <i className="ph-bold ph-arrow-fat-left icones-status"></i>
              Voltar
            </button>
          </div>
        </Col>
      </Row>
      <Modal size="lg" isOpen={modalOpen} className="modal-atividade">
        <ModalHeader
          toggle={() => setModalOpen(!modalOpen)}
          style={{ borderWidth: 0 }}
        />
        <Row className="p-3">
          <Col>
            <h4 className="titulo-modal">
              <i className="ph-package icone"></i>Histórico do Pedido
            </h4>
            <div className="historico-pedido">
              {(order?.fila_historico || []).map((value, k) => (
                <div key={value.id} className={`nodeHistorico`}>
                  <p style={{ marginBottom: 0, color: '#4d98f7db' }}>
                    <strong>{isSolar && value.situacao == 'S' ? 'Em produção' : value.descricao}</strong>
                  </p>
                  <p
                    className="text-danger w-75 text-opacity-75 fw-bolder mb-1"
                    style={{ marginBottom: 0, fontSize: 10 }}
                  >
                    {value.motivo_blq}
                  </p>
                  {k === 0
                    ? formatInvertDate(value.dtent) && (
                      <p style={{ marginBottom: 0, fontSize: 12 }} className="text-dark text-opacity-50">
                        <strong>
                          {formatInvertDate(value.dtent)} - {value.hrent}
                        </strong>
                      </p>
                    )
                    : formatInvertDate(value.dtsai) && (
                      <p style={{ marginBottom: 0, fontSize: 12 }} className="text-dark text-opacity-50">
                        <strong>
                          {formatInvertDate(value.dtsai)} - {value.hrsai}
                        </strong>
                      </p>
                    )}
                </div>
              ))}
            </div>
          </Col>
          <Col>
            <h4 className="titulo-modal">
              <i className="ph-truck icone"></i> Histórico de Transporte
            </h4>
            <div className="historico-transporte">
              {(orderActive?.GfeOcorrencias || []).map(value => (
                <div className="nodeTransporte" key={value.GWD_NROCO}>
                  <p style={{ marginBottom: 0 }}>
                    <strong>{value.GU5_DESC}</strong>
                  </p>
                  <p
                    className="text-bold w-75 text-opacity-75 fw-bolder mb-1"
                    style={{ marginBottom: 0, fontSize: 12, color: colorDefault, textTransform: 'uppercase' }}
                  >
                    {value.GWD_DSSOLU}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {formatInvertDate(value.GWD_DTOCOR)} - {value.GWD_HROCOR}
                  </p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Modal>
      <ConfirmationModal
        loading={loadingGerarRemessa}
        message="Tem certeza que deseja gerar pedido remessa?"
        isOpen={isOpenGerarRemessa}
        onConfirm={onConfirm}
        onClose={onClose}
        icon={<Info size={72} color="#269fec" />}
        textButtonConfirm="Confimar"
        colorButtonConfirm="info"
        colorBg="#269fec"
      />
      {(order?.Situacao || "A") < "F" && (
        <Modal
          size="md"
          isOpen={cancelOpen}
          toggle={() => {
            setCancelOpen(!cancelOpen);
            setCancelFase(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setCancelOpen(!cancelOpen);
              setCancelFase(false);
            }}
            style={{ borderWidth: 0 }}
          >
            Selecione o motivo para o cancelamento
          </ModalHeader>
          {cancelFase ? (
            <ModalBody>
              <p>
                Tem certeza de deseja cancelar esse pedido? Essa ação não poderá
                ser desfeita.
              </p>
            </ModalBody>
          ) : (
            <ModalBody>
              <select
                className="form-control"
                value={motivo}
                onChange={e => {
                  setMotivo(e.target.value);
                }}
              >
                {motivosCancelamento.map(
                  (value, key) =>
                    value.value && (
                      <option key={key} value={value.value}>
                        {value.text}
                      </option>
                    )
                )}
              </select>
            </ModalBody>
          )}
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                setCancelOpen(!cancelOpen);
                setCancelFase(false);
              }}
            >
              Fechar
            </Button>
            <Button
              color="success"
              onClick={() => {
                if (cancelFase) {
                  setCancelOpen(!cancelOpen);
                  generateDataLayer();
                  dispatch(
                    cancelOrder({
                      pedido: String(order?.NumPedido)?.padStart(6, "0") || 0,
                      filial: order?.CodFilial || 0,
                      motivo: motivo,
                      empresa: isSolar ? "02" : "01",
                    })
                  );
                  setCancelFase(false);
                } else {
                  setCancelFase(true);
                }
              }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default Status;
