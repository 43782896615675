import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Table,
  Spinner,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Carousel,
  CarouselIndicators,
  CarouselControl,
  CarouselItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBudget } from "../../store/budget/action";
import { formatInvertDate, formato } from "../../utils";
import { formatDate } from "../../utils/dateUtils";
import "./orcamento.css";
import ModalCancel from "./ModalCancel";
import { apiClient2 } from "../../api/apiCore2";
import { getOrderChildren, getProductDetails } from "../../store/actions";

import { pdfDefinition } from "./pdfDefinition";
import { useSolar } from "../../hooks";
import { toast } from "react-toastify";
import ModalRegisterClient from "./ModalRegisterClient";
import { apiClient } from "../../api/apiCore";
import SideCard from "../../components/SideCard";
import ModalAdias from "../../components/Modal";
import { CircularProgress } from "@material-ui/core";
import { filiais } from "../../utils/filiais";
import { useDataLayer } from "../../hooks/DataLayerHooks";
import ViewEventModal from "../Events/Events/ViewEventModal";

const Orcamento = () => {
  const dispatch = useDispatch();
  const { pushToDataLayer } = useDataLayer();
  const { id, codFilial } = useParams();
  const [detailsProductItem, setDetailsProductItem] = useState(null);
  const detailsProductVisible = [
    "_modulo",
    "_nomeEstrutura4",
    "_nomeEstrutura2",
    "_nomeEstrutura3",
    "_nomeEstrutura",
    "_nomeInversor",
  ];
  const history = useHistory();

  const { active, findedItems } = useSelector(state => {
    return {
      findedItems: state.Order.newOrder.newOrder.findedItems,
      active: state.Budget.active
    };
  });
  const [budgetIsValid, setBudgetIsValid] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [openModalRegisterClient, setOpenModalRegisterClient] = useState(false);
  const [loadingMakeOrder, setLoadingMakeOrder] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [filiaisArray, setFiliaisArray] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [outros, setOutros] = useState(0);

  const [codPag, setCodPag] = useState([]);
  const isSolar = useSolar();

  const dontDisplayChildBudget = ['744', '714', '704', '705', '513', '503'];
  const numberTES =
    active.data?.orcamentos_filhos?.length > 0 &&
    String(active.data?.orcamentos_filhos[0]?.items[0]?.TES);

  const toggleModalCancel = () => {
    setOpenModalCancel(true);
  };
  var dataActive = active?.data;
  var formatCpfCnpj = active?.data?.cliente?.cpfcnpj?.replace(/[^0-9]+/g, "");

  var rgData =
    formatCpfCnpj?.length > 11
      ? active.data.orcamento.InsEst || ""
      : active.data.cliente?.rg || "";
  useEffect(() => {
    dispatch(getBudget(id, codFilial));
  }, [dispatch, id, reloadData, codFilial]);

  const getSubtotal = () => {
    if (isSolar) {
      return Number(active.data.orcamento.total) - Number(active.data.orcamento.CJ_FRETE) - outros;
    }

    if (active?.data?.orcamento?.items || active?.data?.orcamento?.new_items) {
      if (active?.data?.orcamento?.new_items) {
        let val = 0;

        active?.data?.orcamento?.new_items.map(value => {
          val =
            Number(val) + Number(value?.PRCRT ? value?.PRCRT : value?.subTotal);
        });

        return val;
      } else {
        let val = 0;

        active.data.orcamento.items.map(value => {
          val =
            Number(val) +
            Number(
              (value?.PRCRT ? value?.PRCRT : 
                (value?.Valor ? value?.Valor :
                  (value.total - value.CJ_FRETE)
                )
              )
            );
        });

        return val;
      }
    }
  };

  const getSubtotalFilhos = orcamento => {
    if (isSolar) {
      return Number(orcamento.total) - Number(orcamento.CJ_FRETE) - outros;
    }

    if (orcamento?.new_items) {
      let val = 0;

      orcamento?.new_items.map(value => {
        val =
          Number(val) + Number(value?.PRCRT ? value?.PRCRT : value?.subTotal);
      });

      return val;
    } else {
      let val = 0;

      orcamento.items.map(value => {
        val = Number(val) + Number(value?.PRCRT ? value?.PRCRT : value?.Valor);
      });

      return val;
    }
  };
  //

  const isValideOrcamento = () => {
    const format = formatDate(active.data.orcamento.Validade, "YYYY-MM-DD");
    var dataAtual = "";
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    dataAtual = ano + "-" + mes + "-" + dia;

    if (format == dataAtual) {
      return setBudgetIsValid(true);
    }
    if (format > dataAtual) {
      setBudgetIsValid(true);
    } else {
      setBudgetIsValid(false);
    }
  };

  useEffect(() => {
    if (active.fetch) {
      isValideOrcamento();
    }
  }, [active.fetch]);

  const makeOrder = async () => {
    if (active?.data?.orcamento.prospect) {
      return setOpenModalRegisterClient(true);
    } 
    else {
      setOpenModalRegisterClient(false);
    }
    setLoadingMakeOrder(true);
    const res = await apiClient.create(`api/orcamentos/efetiva/${id}`, {
      CODEMPRESA: isSolar ? "02" : "01",
      CODFILIAL: codFilial,
    });

    const eventDataLayer = 'efetivar_pedido';
    pushToDataLayer(
      eventDataLayer, 
      {
        id_pedido: res.pedido,
        value: totalToShow()
      },
      { clearEcommerce: true }
    );

    if (!res.erro) {
      toast.success("Orçamento efetivado em pedido com sucesso!", {
        theme: "colored",
      });

      history.push(
        `${isSolar ? "/solar/" : "/"}pedidos/${res.pedido}/${codFilial}/order`
      );
    } else {
      toast.error("Ocorreu um erro ao tentar efetivar o pedido", {
        theme: "colored",
      });
    }
    setLoadingMakeOrder(false);
  };

  const getPagto = async tabela => {
    tabela = { tabela: isSolar ? "043" : active?.data?.orcamento?.Tabela };
    const resp = await apiClient2.create(`/api/order/condicaopagto`, tabela);
    const newCod = resp.filter(item => {
      return item?.CODPAG == active?.data?.orcamento?.CondPag;
    });

    setCodPag(newCod[0]?.DESCRPAG);
  };

  useEffect(() => {
    if (active?.data?.orcamento?.CondPag) {
      getPagto();
    }
  }, [active?.data?.orcamento?.CondPag]);

  const getImagesProducts = () => {
    let products = active?.data?.sku_base;
    /* for (var i = 0; i < products.length; i++) { */
    if (products) {
      dispatch(getProductDetails(products));
    }
    /*    } */
  };

  useEffect(() => {
    if (active?.data?.orcamento?.items) {
      getImagesProducts();
    }
  }, [active?.data?.orcamento?.items]);

  /////////////////////MODAL
  const produto = {};

  const [item, setItem] = useState({
    atributos: [],
    atributosEspeciais: [],
    fotos: [],
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (findedItems[active?.data?.sku_base]) {
      setItem(findedItems[active.data.sku_base]);
    }
  }, [findedItems, active]);

  useEffect(() => {
    getProductDetailsPDF(item);
  }, [item.atributos, item.atributosEspeciais, item.fotos]);

  function getProductDetailsPDF(item) {
    let array = [];

    item?.atributos
      ?.sort((a, b) => a.Codattr.localeCompare(b.Codattr))
      .map(value => {
        if (!value.Nome) {
          return null;
        } else if (detailsProductVisible.indexOf(value.Codattr) != -1) {
          return array.push([
            {
              text: value.Nome,
              bold: true,
              style: "normalFont",
            },

            {
              text: value.Valor,
              bold: true,
              style: "normalFont",
            },
          ]);
        }
      });

    setDetailsProductItem(array);
  }
  const VerifyExistsNewItems = () => {
    var qtd = "";
    if (active?.data?.orcamento?.new_items) {
      return active?.data?.orcamento?.new_items.map(
        value => (
          (qtd = value?.qtd > 1 ? Number(value?.qtd) : Number(value?.AMOUNT)),
          (
            <tr key={value.Item}>
              {/*   <th scope="row">
               {active?.data?.sku_base && (
              <img
                className="img-product"
                src={findedItems[active?.data?.sku_base]?.fotos[0]}
              />
            )} 
          </th> */}
              <td>{value.nome ? value.nome : value.DESCRICAO}</td>
              {!isSolar && <td>{value?.Produto}</td>}
              {isSolar && <td>{value.previsao ? value.previsao : ""}</td>}
              <td>{qtd}</td>

              {/*     <td>
                {Number(
                  value.PRCRT ? Number(value.PRCRT) / qtd : value.PRCVENDA
                ).toLocaleString("pt-br", formato)}
              </td>

              <td>
                {Number(
                  value.PRCRT
                    ? value.PRCRT
                    : value.subtotal[active?.data?.sku_base]
                ).toLocaleString("pt-br", formato)}
              </td> */}
            </tr>
          )
        )
      );
    } else {
      return active?.data?.orcamento?.items.map(value => (
        <tr key={value.Item}>
          <th scope="row" style={{ textAlign: "center" }}>
            {value?.foto && <img className="img-product" src={value.foto[1]} />}
          </th>{" "}
          <td>
            {findedItems[active.data.sku_base]?.atributos?.length > 0
              ? findedItems[active.data.sku_base]?.atributos?.find(
                  a => a.Codattr === "_NomeCientifico"
                ).Valor
              : value?.DescProd}
          </td>
          {!isSolar && <td>{value?.Produto}</td>}
          {isSolar && value.previsao ? <td>{value.previsao}</td> : null}
          <td>
            {value?.qtd > 1
              ? Number(value?.qtd)
              : Number(value?.Qtdven)
              ? Number(value?.Qtdven)
              : 1}
          </td>
          {!isSolar && (
            <td>
              {Number(value?.PrecoVend)
                ? Number(value.PrecoVend).toLocaleString("pt-br", formato)
                : Number(value.total - value.CJ_FRETE).toLocaleString(
                    "pt-br",
                    formato
                  )}
            </td>
          )}
          {!isSolar && (
            <td>
              {Number(value?.Valor)
                ? Number(value?.Valor).toLocaleString("pt-br", formato)
                : Number(value.total - value.CJ_FRETE).toLocaleString(
                    "pt-br",
                    formato
                  )}
            </td>
          )}
        </tr>
      ));
    }
  };

  const VerifyExistsNewItemsFilhos = orcamento => {
    var qtd = "";

    return orcamento?.items.map(value => (
      <tr key={value.Item}>
        <th scope="row" style={{ textAlign: "center" }}>
          {value?.foto && <img className="img-product" src={value?.foto[1]} />}
        </th>{" "}
        <td>
          {findedItems[orcamento.sku_base]?.atributos?.length > 0
            ? findedItems[orcamento.sku_base]?.atributos?.find(
                a => a.Codattr === "_NomeCientifico"
              ).Valor
            : value?.DescProd}
        </td>
        {!isSolar && <td>{value?.Produto}</td>}
        {isSolar && value.previsao ? <td>{value.previsao} </td> : null}
        <td>{value?.qtd > 1 ? Number(value?.qtd) : Number(value.Qtdven)}</td>
        {!isSolar && (
          <td>{Number(value.PrecoVend).toLocaleString("pt-br", formato)}</td>
        )}
        {!isSolar && (
          <td>{Number(value.Valor).toLocaleString("pt-br", formato)}</td>
        )}
      </tr>
    ));
  };
  const cpfMask = v => {
    //Remove tudo o que não é dígito
    v = v.replace(/\D/g, "");
    //CPF

    //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2");

    //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d)/, "$1.$2");

    //Coloca um hífen entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return v;
  };

  const cnpjMask = v => {
    //CNPJ
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

    return v;
  };

  const Rg = v => {
    v = v.replace(/\D/g, ""); //Substituí o que não é dígito por "", /g é [Global][1]
    v = v.replace(/(\d{1,2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
    // \d{1,2} = Separa 1 grupo de 1 ou 2 carac. (\d{3}) = Separa 1 grupo de 3 carac. (\d{1}) = Separa o grupo de 1 carac.
    // "$1.$2.$3-$4" = recupera os grupos e adiciona "." após cada.

    return v;
  };

  function formatarCEP(str) {
    var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

    return str.replace(re, "$1$2-$3");
  }
  var AllFiliais = [];
  useEffect(() => {
    if (active.data?.orcamentos_filhos?.length > 0) {
      getAllFiliais();
    } else {
      setFiliaisArray([]);
    }
  }, [active.data.orcamentos_filhos]);

  function getAllFiliais() {
    active.data.orcamentos_filhos?.map(orcamento =>
      AllFiliais.push(filiais[Number(orcamento.CodFilial)])
    );
    setFiliaisArray(AllFiliais.join());
  }

  useEffect(() => {
    if (active.data?.orcamentos_filhos?.length > 0) {
      sumToValorTotal();
    }
  }, [active.data.orcamentos_filhos]);

  function sumToValorTotal() {
    var ValorTotal = 0;
    active.data.orcamentos_filhos?.map(
      orcamento =>
        (ValorTotal +=
          Number(orcamento.CJ_FRETE) + Number(getSubtotalFilhos(orcamento)))
    );
    setTotalValue(ValorTotal);
  }

  var totalToShow = () => {
    if (isSolar) {
      return active?.data?.orcamento?.total ? Number(active.data.orcamento.total) : 0;
    }
    
    return active.data?.orcamentos_filhos?.length > 0 &&
    !dontDisplayChildBudget.includes(numberTES)
      ? totalValue +
        Number(
          Number(active?.data.orcamento?.CJ_FRETE) +
            Number(outros) +
            Number(getSubtotal())
        )
      : Number(
          Number(active?.data.orcamento?.CJ_FRETE) +
            Number(outros) +
            Number(getSubtotal())
        );
  }

  /*  const getTotalShipping = () => {
    if (active?.data?.orcamentos_filhos.length > 0) {
      let total = active?.data?.orcamentos_filhos.reduce(
        (acumulador, produto) => {
          return acumulador + produto.CJ_FRETE;
        },
        0
      );
      return active?.data?.orcamento?.CJ_FRETE + total;
    } else {
      return active?.data?.orcamento?.CJ_FRETE;
    }
  }; */

  const getValTotSeguro = () => {
    const items = active.data.orcamento.items;
    const valTot = items.reduce(
      (acumulador, valorAtual) => acumulador + parseFloat(valorAtual.ValServ),
      0
    );
    setOutros(valTot);
  };

  useEffect(() => {
    if (active?.data?.orcamento) {
      getValTotSeguro();
    }
  }, [active]);

  // Obtém o valor do frete do orçamento
  let valorFrete = Number(active.data.orcamento?.CJ_FRETE);

  // Verifica se existe o frete rural e subtrai 500 se for o caso
  if (active.data.orcamento?.shipping?.rural) {
    valorFrete -= 500;
  }
  if (active.data.orcamento?.shipping?.fluvial) {
    valorFrete -= 500;
  }

  const valorFreteFormatado = valorFrete.toLocaleString("pt-br", formato);

  return (
    <React.Fragment>
      <div className="page-content orcamento">
        <Container>
          {active.loading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <Spinner />
            </div>
          )}
          {active.fetch && (
            <React.Fragment>
              <Card>
                <CardBody className="card-info-orcamento">
                  <div className="engloba-logo-texto">
                    <div>
                      {active?.data.dadosNota?.url_logo_orcamento && (
                        <img
                          src={active?.data.dadosNota?.url_logo_orcamento}
                          className="logo-adias"
                        />
                      )}
                      <div>
                        <p className="adias">
                          {active?.data?.dadosNota?.nome_empresa}
                        </p>
                        {active?.data?.vendedor?.CPFCNPJ && (
                          <p>CNPJ: {active?.data?.vendedor?.CPFCNPJ}</p>
                        )}
                      </div>
                    </div>

                    <div className="info-card">
                      <p className="telefone">
                        {active?.data?.dadosNota?.telefone}
                      </p>
                      <p>{active?.data?.dadosNota?.email}</p>
                      <p>{active?.data?.dadosNota?.site}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="card">
                <CardBody className="card-orcamento">
                  <p />
                  <h3 className="titulo-orcamento">Orçamento: #{id}</h3>
                  <p className="data-orcamento">
                    {formatInvertDate(active.data.orcamento.DtEmissao)}
                  </p>
                </CardBody>
              </Card>

              {active.data.orcamento.OrcStat == "C" && (
                <Alert color="danger">
                  <span className="title-alert">Orçamento Cancelado!</span>
                  <br />
                  <p className="sub-title-alert">
                    Este orçamento foi cancelado.
                  </p>
                </Alert>
              )}
              {!budgetIsValid && (
                <Alert color="warning">
                  <span className="title-alert">Orçamento Vencido!</span>
                  <br />
                  <p className="sub-title-alert">
                    Este orçamento está vencido.
                  </p>
                </Alert>
              )}
              <Card>
                <CardBody>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th colSpan={4} className="tabela-titulo">
                          DADOS DO CLIENTE
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Cliente:</th>
                        <td>{active.data.cliente?.nome || ""}</td>
                        <th scope="row">Telefone:</th>
                        <td>
                          {`(${active.data.cliente?.ddd})` +
                            " " +
                            active.data.cliente?.telefone || ""}
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Endereço:</th>
                        <td>{active.data.orcamento?.EndEnt || ""}</td>
                        <th scope="row">Bairro:</th>
                        <td>{active.data.orcamento?.BaiEnt || ""}</td>
                      </tr>

                      <tr>
                        <th scope="row">CEP:</th>
                        <td>
                          {formatarCEP(active.data.orcamento?.CepEnt) || ""}
                        </td>
                        <th scope="row">Cidade:</th>
                        <td>{active.data.orcamento?.MunEnt || ""}</td>
                      </tr>

                      <tr>
                        <th scope="row">Estado:</th>
                        <td>{active.data.orcamento?.UFEnt || ""}</td>
                        <th scope="row">Vendedor:</th>
                        <td>{active.data.vendedor?.CODIGO || ""}</td>
                      </tr>

                      <tr>
                        <th scope="row">CPF/CNPJ:</th>
                        <td>
                          {active.data.cliente?.cpfcnpj.length <= 14
                            ? cpfMask(active.data.cliente?.cpfcnpj)
                            : cnpjMask(active.data.cliente?.cpfcnpj) || ""}
                        </td>
                        <th scope="row">RG/IE:</th>
                        <td>{rgData}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th colSpan={6} className="tabela-titulo">
                          PRODUTOS (1) -{" "}
                          {filiais[Number(active.data?.orcamento?.CodFilial)]}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {!isSolar && <th scope="row">Imagem</th>}
                        <th scope="row">Descrição</th>
                        {!isSolar && <th scope="row">Sku</th>}
                        {isSolar && <th scope="row">Previsão:</th>}
                        <th scope="row">QTD:</th>
                        {!isSolar && <th scope="row">Valor Unit.</th>}
                        {!isSolar && <th scope="row">Valor Total</th>}
                      </tr>

                      {VerifyExistsNewItems()}
                      <tr>
                        <th
                          scope="row"
                          colSpan={isSolar ? 2 : 5}
                          className="subtitulo-tabela"
                        >
                          Subtotal
                        </th>
                        <td>
                          {Number(getSubtotal()).toLocaleString(
                            "pt-br",
                            formato
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          colSpan={isSolar ? 2 : 5}
                          className="subtitulo-tabela"
                        >
                          Frete
                        </th>
                        <td>
                          {valorFreteFormatado}
                        </td>
                      </tr>
                      {active.data.orcamento?.shipping?.rural ? (
                        <tr>
                          <th
                            scope="row"
                            colSpan={isSolar ? 2 : 5}
                            className="subtitulo-tabela"
                          >
                            Área Rural
                          </th>
                          <td>
                            R$ 500,00
                          </td>
                        </tr>
                      ) : ('')}
                      {active.data.orcamento?.shipping?.fluvial ? (
                        <tr>
                          <th
                            scope="row"
                            colSpan={isSolar ? 2 : 5}
                            className="subtitulo-tabela"
                          >
                            Área Fluvial
                          </th>
                          <td>
                            R$ 500,00
                          </td>
                        </tr>
                      ) : ('')}
                      <tr>
                        <th
                          scope="row"
                          colSpan={isSolar ? 2 : 5}
                          className="subtitulo-tabela"
                        >
                          Outros
                        </th>
                        <td>
                          {Number(outros).toLocaleString("pt-br", formato)}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          colSpan={isSolar ? 2 : 5}
                          className="subtitulo-tabela"
                        >
                          Total
                        </th>

                        <td>
                          {Number(
                            Number(active.data.orcamento.CJ_FRETE) +
                              Number(outros) +
                              Number(getSubtotal())
                          ).toLocaleString("pt-br", formato)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  {active.data?.orcamentos_filhos &&
                    !dontDisplayChildBudget.includes(numberTES) &&
                    active.data.orcamentos_filhos?.map((orcamento, k) => (
                      <Table key={k} bordered responsive>
                        <thead>
                          <tr>
                            <th colSpan={6} className="tabela-titulo">
                              <span colSpan={6}>
                                PRODUTOS ({k + 1 + 1}) -{" "}
                                {filiais[Number(orcamento.CodFilial)]}
                              </span>
                              <span className="tabela-orcamento">
                                Orcamento: #{orcamento?.Orcamento}
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {!isSolar && <th scope="row">Imagem</th>}
                            <th scope="row">Descrição</th>
                            {!isSolar && <th scope="row">Sku</th>}
                            {isSolar && <th scope="row">Previsão:</th>}
                            <th scope="row">QTD:</th>
                            {!isSolar && <th scope="row">Valor Unit.</th>}
                            {!isSolar && <th scope="row">Valor Total</th>}
                          </tr>
                          {VerifyExistsNewItemsFilhos(orcamento)}
                          <tr>
                            <th
                              scope="row"
                              colSpan={isSolar ? 2 : 5}
                              className="subtitulo-tabela"
                            >
                              Subtotal
                            </th>
                            <td>
                              {Number(
                                getSubtotalFilhos(orcamento)
                              ).toLocaleString("pt-br", formato)}
                            </td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              colSpan={isSolar ? 2 : 5}
                              className="subtitulo-tabela"
                            >
                              Frete
                            </th>
                            <td>
                              {Number(orcamento.CJ_FRETE).toLocaleString(
                                "pt-br",
                                formato
                              )}
                            </td>
                          </tr>{" "}
                          <tr>
                            <th
                              scope="row"
                              colSpan={isSolar ? 2 : 5}
                              className="subtitulo-tabela"
                            >
                              Outros
                            </th>
                            <td>
                              {Number(outros).toLocaleString("pt-br", formato)}
                            </td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              colSpan={isSolar ? 2 : 5}
                              className="subtitulo-tabela"
                            >
                              Total
                            </th>

                            <td>
                              {Number(
                                Number(orcamento.CJ_FRETE) +
                                  Number(outros) +
                                  Number(getSubtotalFilhos(orcamento))
                              ).toLocaleString("pt-br", formato)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    ))}
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th colSpan={5} className="tabela-titulo">
                          INFORMAÇÕES ADICIONAIS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">* Valor total:</th>
                        <th scope="row">
                          {" "}
                          {totalToShow().toLocaleString("pt-br", formato)}
                        </th>
                      </tr>

                      <tr>
                        <th scope="row">* Prazo de Entrega:</th>
                        <th scope="row">
                          {Number(active.data.prazoEntrega)} dias úteis após a
                          data de faturamento do pedido.
                        </th>
                      </tr>
                      <tr>
                        <th scope="row">* Validade da prosposta:</th>
                        <th scope="row">
                          Este orçamento é válido até dia{" "}
                          {formatInvertDate(active.data.orcamento.Validade)}
                        </th>
                      </tr>
                      <tr>
                        <th scope="row">* Cond. de pagamento:</th>

                        <th scope="row">{codPag}</th>
                      </tr>
                      {!active.data?.orcamentos_filhos?.length && (
                        <tr>
                          <th scope="row">* Frete:</th>
                          <th scope="row">
                            {Number(
                              active.data.orcamento.CJ_FRETE
                            ).toLocaleString("pt-br", formato)}
                          </th>
                        </tr>
                      )}

                      <tr>
                        <th scope="row">* Faturado e entregue por:</th>
                        <th scope="row">
                          {!isSolar
                            ? `MPS DISTRIBUIDORA MERCANTIL LTDA - ${
                                filiais[
                                  Number(active.data?.orcamento?.CodFilial)
                                ]
                              }, ${filiaisArray} - VIA MKTPLACE`
                            : "ADIAS SOLAR"}
                        </th>
                      </tr>
                      <tr>
                        <th scope="row">* Disponibilidade:</th>
                        <th scope="row">
                          Este orçamento não garante disponibilidade de estoque.
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th colSpan={5} className="tabela-titulo">
                          DETALHES DO PRODUTO
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {item?.atributos
                        ?.sort((a, b) => a.Codattr.localeCompare(b.Codattr))
                        .map(value => {
                          if (!value.Nome) {
                            return null;
                          } else {
                            return (
                              <tr key={value.Codattr}>
                                {detailsProductVisible.indexOf(value.Codattr) !=
                                  -1 && (
                                  <>
                                    <th scope="row"> {value.Nome}</th>
                                    {typeof value.Valor == "string" &&
                                    value.Valor.includes("https") ? (
                                      <th scope="row">
                                        <a
                                          style={{
                                            background: isSolar
                                              ? "#FF601A"
                                              : "#2D567B",
                                            color: "#fff",
                                            padding: "8px 10px",
                                            borderRadius: 3,
                                          }}
                                          target="_blank"
                                          rel="noreferrer"
                                          href={value.Valor}
                                        >
                                          visualizar PDF
                                        </a>
                                      </th>
                                    ) : (
                                      <th scope="row">{value.Valor}</th>
                                    )}
                                  </>
                                )}
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </Table>
                  <div className="mb-2">
                      {(active?.data?.orcamento?.ObservacaoNota && active.data.orcamento.ObservacaoNota.trim() !== "") && (
                        <SideCard
                          type={isSolar ? "solar" : "ar"}
                          title="Observações da Nota Fiscal"
                        >
                          <p
                            style={{ cursor: "pointer", marginBottom: "0" }}
                            onClick={() => setIsModalOpen(prev => !prev)}
                          >
                            <>
                              <SideCard type="solar" title="Observações da Nota Fiscal">
                                {active.data.orcamento.ObservacaoNota.split('|').map((linha, index) => (
                                  <div key={index}>
                                    {linha}
                                    <br />
                                  </div>
                                ))}
                              </SideCard>
                            </>
                          </p>
                          {item?.fotos?.length > 0 && (
                            <ModalAdias
                              isModalOpen={isModalOpen}
                              setIsModalOpen={setIsModalOpen}
                              produto={produto}
                              produtoDetails={item}
                            />
                          )}
                        </SideCard>
                      )}
                    {(active?.data?.orcamento?.ObsInt && active?.data.orcamento.ObsInt.trim() !== "") && (
                        <SideCard type="solar" title="Observações Internas">
                          {active.data.orcamento.ObsInt.split('|').map((linha, index) => (
                            <div key={index}>
                              {linha}
                              <br />
                            </div>
                          ))}
                        </SideCard>
                    )}
                  </div>
                  <div className="condicoes">
                    <h3 className="titulo-condicoes">
                      {" "}
                      Condiçoes de Fornecimento
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: active?.data?.de_acordo,
                      }}
                    ></p>
                  </div>
                  <div className="engloba-finalizacao-tudo">
                    <h4 className="disposicao mt-5 pt-5">
                      Colocamo-nos à inteira disposição para quaisquer informações
                      adicionais
                    </h4>
                    <div className="engloba-finalizacao-assinatura">
                      <div className="finalizacao">
                        <p>Atenciosamente,</p>
                        <p>{active?.data?.dadosNota?.nome_empresa}</p>
                        <p>{active?.data?.dadosNota?.email}</p>
                        <p>{active?.data?.dadosNota?.site}</p>
                      </div>
                      <div className="campo-assinatura">
                        <hr className="linha-assinatura" />
                        <p className="subtitulo-assinatura">
                          {active.data.cliente?.nome || ""}
                        </p>
                        <p className="declaro-email">
                          Declaro que li e concordo com a proposta acima.
                        </p>
                        <p className="declaro-email">
                          {active.data.cliente?.email || ""}
                        </p>
                      </div>
                    </div>{" "}
                    {active.data.orcamento.OrcStat != "C" && (
                      <div className="buttons-container">
                        <>
                          {active.data.orcamento.OrcStat == "A" && budgetIsValid && (
                            <button
                              className="btn btn-color"
                              onClick={makeOrder}
                              style={{
                                backgroundColor: isSolar && "#FF601A",
                                minWidth: 100,
                              }}
                              disabled={loadingMakeOrder}
                            >
                              {loadingMakeOrder ? (
                                <div className="container-loading-button">
                                  <CircularProgress color="#fff" size={21} />
                                </div>
                              ) : (
                                "EFETIVAR EM PEDIDO"
                              )}
                            </button>
                          )}
                          <button
                            style={{
                              marginLeft: 15,
                              backgroundColor: isSolar && "#E0E0E0",
                              color: isSolar && "#787878",
                            }}
                            className="btn cancel"
                            onClick={toggleModalCancel}
                          >
                            CANCELAR ORÇAMENTO
                          </button>
                        </>
                        <div style={{ display: "flex" }}>
                          {/*  <button
                            style={{ marginLeft: 15 }}
                            className="btn whats d-flex align-items-center"
                            onClick={() =>
                              sendMessage(
                                "55" +
                                  active?.data?.cliente?.DDD_CEL +
                                  active?.data?.cliente?.CEL,
                                `Olá Hebert, seu orçamento já está disponível para consulta, clique no link para visualizá-lo https://clube.adias.com.br/orcamentopdf/${id}/0/02/01`
                              )
                            }
                          >
                            <i
                              style={{ marginRight: 3 }}
                              className="ph-whatsapp-logo"
                            />
                            WHATSAPP
                          </button>
                          <a
                            style={{ marginLeft: 15 }}
                            className="btn email d-flex align-items-center"
                            href={`https://clube.adias.com.br/orcamentos/email/${id}/${active.data.cliente.email}/0/02/01`}
                          >
                            <i
                              style={{ marginRight: 3 }}
                              className="ph-envelope-fill"
                            />
                            E-MAIL
                          </a> */}

                          <button
                            style={{
                              marginLeft: 15,
                              backgroundColor: isSolar && "#ffc82a",
                            }}
                            className="btn imprimir d-flex align-items-center"
                            disabled={loadingPdf}
                            onClick={() => {
                              setLoadingPdf(true);
                              pdfDefinition(
                                dataActive,
                                codPag,
                                id,
                                "imprimir_sem_valores",
                                detailsProductItem,
                                totalToShow(),
                                filiaisArray,
                                "no-download-sem-valores",
                                setLoadingPdf,
                                numberTES,
                                dontDisplayChildBudget,
                                outros,
                                getSubtotal,
                                getSubtotalFilhos,
                                rgData
                              );
                            }}
                          >
                            <i
                              style={{ marginRight: 3 }}
                              className="ph-printer-fill"
                            ></i>
                            {loadingPdf
                              ? "Carregando..."
                              : "IMPRIMIR SEM VALORES"}
                          </button>
                          {!isSolar && (
                            <button
                              style={{
                                marginLeft: 15,
                                backgroundColor: isSolar && "#ffc82a",
                              }}
                              className="btn imprimir d-flex align-items-center"
                              disabled={loadingPdf}
                              onClick={() => {
                                setLoadingPdf(true);
                                pdfDefinition(
                                  dataActive,
                                  codPag,
                                  id,
                                  "imprimir",
                                  detailsProductItem,
                                  totalToShow(),
                                  filiaisArray,
                                  isSolar ? "download-sem-valores" : "",
                                  setLoadingPdf,
                                  numberTES,
                                  dontDisplayChildBudget,
                                  outros,
                                  getSubtotal,
                                  getSubtotalFilhos,
                                  rgData
                                );
                              }}
                            >
                              <i
                                style={{ marginRight: 3 }}
                                className="ph-printer-fill"
                              ></i>
                              {loadingPdf ? "Carregando..." : "IMPRIMIR"}
                            </button>
                          )}
                          <button
                            style={{
                              marginLeft: 15,
                              backgroundColor: isSolar && "#ffc82a",
                            }}
                            className="btn dowload  d-flex align-items-center"
                            disabled={loadingPdf}
                            onClick={() => {
                              setLoadingPdf(true);
                              pdfDefinition(
                                dataActive,
                                codPag,
                                id,
                                "download",
                                detailsProductItem,
                                totalToShow(),
                                filiaisArray,
                                isSolar ? "download-sem-valores" : "",
                                setLoadingPdf,
                                numberTES,
                                dontDisplayChildBudget,
                                outros,
                                getSubtotal,
                                getSubtotalFilhos,
                                rgData
                              );
                            }}
                          >
                            <i
                              style={{ marginRight: 3 }}
                              className="ph-download-simple-fill"
                            ></i>
                            {loadingPdf ? "Carregando..." : "DOWNLOAD"}
                          </button>
                          {isSolar &&
                            active?.data?.orcamento.prospect &&
                            active?.data?.lead &&
                            active?.data?.lead.length > 8 &&
                            active.data.orcamento.items.length == 1 && (
                              <a
                                style={{
                                  marginLeft: 15,
                                  backgroundColor: "#FF601A",
                                }}
                                className="btn cancel  d-flex align-items-center"
                                target="_blank"
                                rel="noreferrer"
                                href={`/solar/pdf/${id}`}
                              >
                                <i
                                  style={{ marginRight: 3 }}
                                  className="ph-file-pdf-fill"
                                ></i>
                                PDF Detalhado
                              </a>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </React.Fragment>
          )}
        </Container>
      </div>
      <ModalCancel
        openModalCancel={openModalCancel}
        setOpenModalCancel={setOpenModalCancel}
        active={active}
        id={id}
        setReloadData={setReloadData}
        codFilial={codFilial}
        totalValue={totalToShow()}
      />

      <ModalRegisterClient
        openModalRegisterClient={openModalRegisterClient}
        setOpenModalRegisterClient={setOpenModalRegisterClient}
        id={id}
        data={active}
        codFilial={codFilial}
      />
    </React.Fragment>
  );
};

export default Orcamento;
