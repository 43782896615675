import styled from "styled-components";
import wave from '../../assets/images/svg/wave-adias.svg';
import logo from "../../assets/images/logos/adias/solar_laranja.svg";

export const TelaToda = styled.div`
  position: relative;

background-color: #87afc2;
background-image: linear-gradient(56deg, #87afc2 40%, #ffffff 56%, #ffffff 64%, #ffc4a3 81%);

  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const WaveImg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh; /* Altura total da tela */
  width: 100%; /* Ajuste para a largura desejada, aqui 30% da tela */
  background-image: url(${wave});
  background-size: cover; /* Faz a imagem cobrir toda a área disponível */
  background-repeat: no-repeat;
    border: none; /* Remove qualquer borda */
  margin: 0;
  padding: 0;
`;

export const LogoImg = styled.div`
  position: fixed;
  top: 2px;
  right: 2px;
  width: 280px;
  height: 160px;
  background: url(${logo}) transparent no-repeat;
  background-size: contain;
  border: 1px solid transparent!important
`;


export const LogoDiv = styled.div`
  img {
    min-width: 130px;
  }
`;
export const Card = styled.div`
  width: 470px;
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0px 3.69417px 9.78589px #e0e5eb;
  padding: 70px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

export const LinhaCard = styled.hr`
  margin-top: 30px;
`;

export const TituloCard = styled.h1`
  font-size: 24px;
  margin-left: -1px;
  margin-top: 30px;
  color: #FFFF;
`;

export const SubtituloCard = styled.p`
  margin-left: -1px;
  font-size: 14px;
  color: #FFF;
  margin-bottom: 50px;
`;

export const InputLabel = styled.label`
  font-weight: 600;
  margin-bottom: 3px;
  color: #0F172A;
`;

export const Formulario = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d2d2d2;
  padding: 10px;
  border-radius: 11px;
  background-color: #FFF;

  @media (max-width: 600px) {
    width: 100%;
  }
`;


export const CardLogin = styled.div`
  max-width: 550px;
  background: rgba(255, 96, 26, 0.77);
  margin-top: 166px;
  margin-left: 154px; 
  margin-bottom: 1.25rem;
  border-radius: 12px;
  box-shadow: 0px 2.37502px 6.29143px #e0e5eb;

  .input-form {
    font-size: 1.5em; 
    width: 100%;
  }

  p {
      font-size: 1.2em;
  }

  h1 {
    font-size: 2.1em; 
  }

  span {
  font-size: 1.2em;
  }

  .input-user {
    width: 100%;
  }

  @media (max-width: 384px) {
    margin: 20px auto;
    max-width: 57%;
    padding: 20px;
    margin-left: 8px;
    margin-right: 8px;

    h1 {
      font-size: 1.8em;
    }

    p {
      font-size: 1em;
    }

    .input-user {
      padding: 0.4em;
      width: 85%; 
    }
    
    button {
      width: 87%;
    }

    span {
      font-size: 1em;
    }
  }

  @media (max-width: 768px) {
    margin: 50px auto; 
    transform: scale(1);
    max-width: 100%; 
    margin-top: 134px;
    margin-left: 8px;
    margin-right: 8px;
  }
    @media (min-width: 1024px) { 
    padding: 40px; 
    

    p {
      font-size: 1.2em; 
    }



    input {
      font-size: 1.1em; 
    }
  }

  @media (min-width: 400px) and (max-width: 768px) {
      .input-user {
      padding: 0.4em;
      width: 100%;
    }
  }
`;
export const Icone = styled.p`
  font-size: 22px;
  padding-right: 10px;
  border-right: 1px solid #d2d2d2;
  display: flex;
  margin-bottom: 0;
  color: #a8a8a8;
`;

export const IconEye = styled.div`
  position: absolute;
  right: 0;
  padding: 6px;
  font-size: 22px;
  display: flex;
  margin-bottom: 0;
  color: #a8a8a8;
  cursor: pointer;

  :hover {
    .ph-eye::before {
      color: #2D567B !important;
    }
    .ph-eye-slash::before {
      color: #2D567B !important;
    }
  }
`;

export const InputUser = styled.input`
  border: none;
  font-size: 16px;
  outline: none;
  margin-left: 20px;
  background: #FFF
  width: 100%;
`;

export const InputSenha = styled.label`
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 30px;
  font-size: 16px;
  color: #0F172A;
`;
export const CheckboxSenha = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const Input = styled.input`
  margin-right: 4px;
`;

export const DividerContainer = styled.div`
  display: flex
`;

export const DividerLine = styled.div`
  margin:25px auto;
  width: 100px;
  height:1px;
  background-color:#FFF;
  line-height:1px;
  text-align:center;
`;

export const DividerText = styled.span`
  margin: 0 10px; /* Espaço ao redor do texto */
  align-items: center;
  display: flex;
  color: #FFF;
`;

export const Memorizar = styled.p`
  color: #FFF;
  margin-bottom: 0;
  margin-right: 27px;
`;

export const LinkRecuperar = styled.a`
  display: flex;
  align-items: center;
  color: #FFF;
  margin-right: 4px;
  font-weight: bold;
`;

export const LinkCriarConta = styled.a`
  display: flex;
  align-items: end;
  color: #FFF;
  margin-right: 4px;
  margin-left: 10px;
  width: 126px;
  font-weight: bold;
  justify-content: flex-end;
`;

export const BotaoEntrar = styled.button`
  padding: 28px;
  background-color: rgb(45, 86, 123);
  color: rgb(255, 255, 255);
  border: none;
  margin-top: 50px;
  font-size: 24px;
  border-radius: 13px;
  margin-bottom: 6px;
  width: 100%;
  height: 43px;
  transition: all 0.5 linear;
  &:hover {
    background-color: #7EACC4;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BotaoCriar = styled.button`
  padding: 10px 47px;
  background-color: #FF601A;
  color: #fff;
  border: none;
  margin-top: 30px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  text-transform: uppercase;
  height: 43px;
  transition: all 0.5 linear;
  &:hover {
    background-color: #FF9E73;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CriarConta = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 20;
  margin-left: 53px;
  font-size: 13px;
  color: #6d7989;
  cursor: pointer;
`;

export const LinkCriar = styled.a`
  margin-left: 5px;
  color: #24bdff;
  display: flex;
  align-items: center;
  cursor: pointer !important;
`;

export const LinhaRedes = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const TextoRedes = styled.p`
  margin-bottom: 0;
  color: #bdbdbd;
`;

export const TextoConta = styled.p`
  font-size: 24px;
  width: 200px;
  display: flex;
  text-align: start;
  font-weight: 600;
  color: #2D567B;
`;

export const ColCriarConta = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
    padding: 5px; 
    background-color: rgb(255, 224, 211);
    border-radius: 15px 0 0 15px;
`;

export const TextoParceiro = styled.p`
  font-size: 13px;
  padding: 6px;
  font-weight: 500;
  color: #2D567B;
  border-radius: 4px;
  background-color: #BBCAD2;
`;

export const Hr = styled.hr`
  width: 106px;
  color: #bdbdbd;
`;
export const RedesSociais = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const BtnSocial = styled.button`
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 50px;
  margin: 5px;
  min-height: 50px;
  margin-top: 15px;
  color: ${props => props.color};
  border: 2px solid ${props => props.color};
`;

export const BarraLateral = styled.div`
  position: fixed;
  max-width: 316px;
  min-width: 22vw;
  min-height: 100vh;
  right: 0;
  top: 0;
  background-color: #7EACC4;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ImgSofa = styled.img`
  width: 100%;
  transform: scale(1.5) translateX(-100px);
`;

export const DivBotoes = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BotaoVoltar = styled.button`
  border: 1px solid #2D567B;
  background-color: #fff;
  color: #2D567B;
  margin-top: 40px;
  padding: 13px 43px;
  border-radius: 7px;
  color: #2D567B;
  font-weight: bold;
`;

export const BotaoEnviar = styled.button`
  border: 1px solid #2D567B;
  background-color: #2D567B;
  color: #ffff;
  margin-top: 40px;
  padding: 13px 43px;
  border-radius: 7px;
  font-weight: bold;
`;

export const BotaoEnviarLink = styled.a`
  border: 1px solid #2D567B;
  background-color: #2D567B;
  color: #ffff;
  margin-top: 40px;
  padding: 13px 43px;
  border-radius: 7px;
  font-weight: bold;
`;

export const AgrupaModal = styled.div`
  padding: 40px 70px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const TituloModal = styled.h1`
  font-weight: bold;
  font-size: 24px;
  margin-top: 17px;
  margin-bottom: 24px;
  color: #404b5a;
`;

export const HrModal = styled.hr`
  width: 330px;
`;

export const BtnModal = styled.button`
  border: 1px solid #2D567B;
  background-color: #2D567B;
  color: #ffff;
  margin-top: 40px;
  padding: 13px 43px;
  border-radius: 7px;
  font-weight: bold;
  width: fit-content;
  margin-left: auto;
  display: block;
`;
export const AgrupaLinha = styled.div`
  display: flex;
  align-items: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const AgrupaColuna = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.size}%;
`;

export const Etiqueta = styled.label`
  font-weight: 500;
  font-size: 16px;
  color: #696969;
  margin-top: 20px;
`;

export const InputModal = styled.input`
  width: 100%;
  padding: 4px;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
`;

export const SelectModal = styled.select`
  width: 100%;
  padding: 4px;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
`;

export const IconContainer = styled.div`
  position: fixed;
  bottom: 20px; /* Distância do fundo */
  left: 20px; /* Distância da esquerda */
  display: flex;
  flex-direction: column; /* Ícones em coluna */
  gap: 23px; /* Espaçamento entre os ícones */

  @media (max-width: 768px) {
    flex-direction: row;
  }
  @media (max-width: 375px) {
    display: none;
  }
`;

export const IconLink = styled.a`
  font-size: 24px; /* Tamanho dos ícones */
  transition: color 0.3s; /* Transição suave ao passar o mouse */
    @media (max-width: 768px) {
  }

`;
