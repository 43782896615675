import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { CatalogProductsActionTypes } from "./actionTypes";
import { getCapacidade, getCategory, getNewOrder, getOrderProducts, getPagto, getProductsDetails as getProductsDetailsApi } from "../../api";
import { catalogProductsApiSuccess, catalogProductssApiFail } from "./action";
import { toast } from "react-toastify";

function* getProducts({ payload: selectedFilterProducts }) {
  try {
    const products = yield call(getOrderProducts, selectedFilterProducts);
    yield put(catalogProductsApiSuccess(CatalogProductsActionTypes.GET_PRODUCTS, products?.produtos ? products.produtos : []));
  } catch (error) {
    toast.error(error);
    yield put(catalogProductssApiFail(CatalogProductsActionTypes.GET_PRODUCTS));
  }
}

function* getProductsDetails({ payload: skuList }) {
  try {
    const productDetails = yield call(getProductsDetailsApi, skuList);
    yield put(catalogProductsApiSuccess(CatalogProductsActionTypes.GET_PRODUCTS_DETAILS, productDetails));
  } catch (error) {
    toast.error(error);
    yield put(catalogProductssApiFail(CatalogProductsActionTypes.GET_PRODUCTS_DETAILS));
  }
}

function* getStarterProductsFilter({ payload: data }) {
  try {
    const response = yield call(getNewOrder, data);
    yield put(catalogProductsApiSuccess(CatalogProductsActionTypes.GET_STARTER_FILTERS_PRODUCTS, response));
  } catch (error) {
    toast.error(error);
    yield put(catalogProductssApiFail(CatalogProductsActionTypes.GET_STARTER_FILTERS_PRODUCTS));
  }
}

function* getPaymentConditions({ payload: table }) {
  try {
    const paramns = {
      tabela: table,
    };
    let paymentConditions = yield call(getPagto, paramns);
    yield put(catalogProductsApiSuccess(CatalogProductsActionTypes.GET_PAYMENT_CONDITIONS, paymentConditions));
  } catch (error) {
    toast.error(error);
  }
}

function* getCategories({ payload: group }) {
  try {
    const response = yield call(getCategory, { group });
    let categories = [];
    Object.keys(response || {}).map(function (key) {
      categories.push({ CODIGO: key, DESCR: response[key] });
    });
    yield put(catalogProductsApiSuccess(CatalogProductsActionTypes.GET_CATEGORIES, categories));
  } catch (error) {
    toast.error(error);
  }
}

function* getCapacities({ payload: { group, category } }) {
  try {
    const response = yield call(getCapacidade, { group, category });
    let capacities = [];
    Object.keys(response || {}).map(function (key) {
      capacities.push({ CODIGO: key, DESCR: response[key] });
    });
    yield put(catalogProductsApiSuccess(CatalogProductsActionTypes.GET_CAPACITIES, capacities));
  } catch (error) {
    toast.error(error);
  }
}

export function* watchGetProducts() {
  yield takeEvery(CatalogProductsActionTypes.GET_PRODUCTS, getProducts);
}

export function* watchGetProductsDetails() {
  yield takeEvery(CatalogProductsActionTypes.GET_PRODUCTS_DETAILS, getProductsDetails);
}

export function* watchGetStarterFilterProducts() {
  yield takeEvery(CatalogProductsActionTypes.GET_STARTER_FILTERS_PRODUCTS, getStarterProductsFilter);
}

export function* watchGetPaymentConditions() {
  yield takeEvery(CatalogProductsActionTypes.GET_PAYMENT_CONDITIONS, getPaymentConditions);
}

export function* watchGetCategories() {
  yield takeEvery(CatalogProductsActionTypes.GET_CATEGORIES, getCategories);
}

export function* watchGetCapacities() {
  yield takeEvery(CatalogProductsActionTypes.GET_CAPACITIES, getCapacities);
}

function* catalogProductsSaga() {
  yield all([
    fork(watchGetProducts),
    fork(watchGetProductsDetails),
    fork(watchGetStarterFilterProducts),
    fork(watchGetPaymentConditions),
    fork(watchGetCategories),
    fork(watchGetCapacities),
  ]);
}

export default catalogProductsSaga;