import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input } from "reactstrap";
import ProductItem from "./productItem/ProductItem";
import { useColors } from "../../../hooks/useColors";
import { useDispatch } from "react-redux";
import { getProductsDetails } from "../../../store/actions";
import { useProfile } from "../../../hooks";
import { generatePdf } from "./generatePdfJs";

const ProductsList = ({ products, loading, loadingDetails, hideOutOfStock }) => {
  const { colorDefault } = useColors();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productsThatSoughtDetails, setProductsThatSoughtDetails] = useState([]);
  const maxSelection = 100;
  const dispatch = useDispatch();
  const [currentPageFound, setCurrentPageFound] = useState(1);
  const [currentPageSelected, setCurrentPageSelected] = useState(1);
  const productsPerPage = 10;

  const [paginatedAvailableProducts, setPaginatedAvailableProducts] = useState([]);

  const handleSelectProduct = (product) => {
    if (selectedProducts.some(p => p.CODPRODUTO === product.CODPRODUTO)) {
      setSelectedProducts(selectedProducts.filter(p => p.CODPRODUTO !== product.CODPRODUTO));
    } else {
      if (selectedProducts.length < maxSelection) {
        setSelectedProducts([...selectedProducts, product]);
      }
    }
  };

  const paginate = (items, page) => {
    const startIndex = (page - 1) * productsPerPage;
    return items.slice(startIndex, startIndex + productsPerPage);
  };

  useEffect(() => {
    const filteredProducts = hideOutOfStock
      ? products.filter(product => {
        const totalStock = product.PRDDISPS?.STRETPRDFILADIAS?.reduce((acc, e) => acc + e.ESTOQUE, 0);
        return totalStock > 0;
      })
      : products;

    setPaginatedAvailableProducts(paginate(filteredProducts, currentPageFound));
  }, [products, currentPageFound, hideOutOfStock]);

  useEffect(() => {
    const updatedSelectedProducts = selectedProducts.map((selectedProduct) => {
      const productDetails = products.find((product) => product.id === selectedProduct.id);
      if (productDetails?.details) {
        return {
          ...selectedProduct,
          details: productDetails.details
        };
      }
      return selectedProduct;
    });

    setSelectedProducts(updatedSelectedProducts);
  }, [products]);

  useEffect(() => {
    const skus = paginatedAvailableProducts
      .filter(product => !productsThatSoughtDetails.find(item => item.CODPRODUTO == product.CODPRODUTO))
      .map(product => product.CODPRODUTO);

    if (skus.length > 0) {
      setProductsThatSoughtDetails([...productsThatSoughtDetails, skus]);
      dispatch(getProductsDetails(skus));
    }
  }, [paginatedAvailableProducts]);

  const paginatedSelectedProducts = paginate(selectedProducts, currentPageSelected);
  const { userProfile } = useProfile();

  return (
    <div className="mt-2">
      <Row>
        <Col xs={12} md={6}>
          <h4>Produtos encontrados</h4>
          {paginatedAvailableProducts.length > 0 ? (
            paginatedAvailableProducts.map((product) => {
              const isDisabled = selectedProducts.some(p => p.CODPRODUTO === product.CODPRODUTO);
              return (
                <ProductItem
                  key={product.CODPRODUTO}
                  product={product}
                  isSelected={false}
                  isDisabled={isDisabled}
                  onSelect={() => handleSelectProduct(product)}
                />
              );
            })
          ) : (
            <p>Nenhum produto disponível</p>
          )}
          <div className="pagination mt-2">
            <Button
              className="me-2"
              style={{ backgroundColor: colorDefault, color: "white" }}
              onClick={() => setCurrentPageFound(currentPageFound - 1)}
              disabled={currentPageFound === 1 || loadingDetails}
            >
              Anterior
            </Button>
            <Button
              style={{ backgroundColor: colorDefault, color: "white" }}
              onClick={() => setCurrentPageFound(currentPageFound + 1)}
              disabled={paginatedAvailableProducts.length < productsPerPage || loadingDetails}
            >
              Próxima
            </Button>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <h4>Produtos selecionados</h4>
          {paginatedSelectedProducts.length > 0 ? (
            paginatedSelectedProducts.map((product) => (
              <ProductItem
                key={product.CODPRODUTO}
                product={product}
                isSelected={true}
                onSelect={() => handleSelectProduct(product)}
              />
            ))
          ) : (
            <p>Nenhum produto selecionado</p>
          )}
          <div className="pagination mt-2">
            <Button
              className="me-2"
              style={{ backgroundColor: colorDefault, color: "white" }}
              onClick={() => setCurrentPageSelected(currentPageSelected - 1)}
              disabled={currentPageSelected === 1}
            >
              Anterior
            </Button>
            <Button
              onClick={() => setCurrentPageSelected(currentPageSelected + 1)}
              style={{ backgroundColor: colorDefault, color: "white" }}
              disabled={paginatedSelectedProducts.length < productsPerPage}
            >
              Próxima
            </Button>
          </div>
        </Col>
      </Row>
      <div className="mt-4">
        <p>
          {selectedProducts.length}/{maxSelection} produtos selecionados
        </p>
        <Button
          className="mt-2 gerar-pdf"
          onClick={() => {
            generatePdf(selectedProducts, userProfile);
          }}
          disabled={loading}
        >
          Gerar PDF
        </Button>
        {loadingDetails && (
          <p style={{ color: colorDefault }}>
            Aguarde, estamos carregando as imagens dos produtos...
          </p>
        )}
      </div>
    </div>
  );
};

export default ProductsList;
