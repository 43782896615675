import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import {
  LOGIN_USER,
  LOGIN_UID,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
//Include Both Helper File with needed methods
import {
  getFirebaseBackend,
  setLoggeedInUser,
  logoutUser as logoutUserUtil,
} from "../../../helpers/firebase_helper";
import { toast } from "react-toastify";
const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );

      const formattedData = JSON.parse(response);

      if (
        formattedData["stsTokenManager"] &&
        formattedData["stsTokenManager"]["accessToken"]
      ) {
        // _app_auth_token
        /*  setLoggeedInUser({ ...formattedData, ...userData }); */

        yield put(loginSuccess(formattedData));

        toast.success(`Bem vindo! ${formattedData.displayName}`, {
          theme: "colored",
          position: "bottom-right",
        });

        history.replace("/choose-company");
      }
    }
  } catch (error) {
    const errorCodeUserNotFound = "auth/user-not-found";
    const errorCodeWrongPassword = "auth/wrong-password";
    const errorCodeDesactivatedUser = "auth/too-many-requests";
    const errorCodeNetworkRequestFailed = "auth/network-request-failed";

    const messageErrorUserNotFound = "Não há registro de usuário correspondente a este identificador. O usuário pode ter sido excluído.";
    const messageErrorWrongPassword = "A senha é inválida ou o usuário não possui uma senha.";
    const messageErrorDesactivatedUser = "O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login com falha. Você pode restaurá-lo imediatamente redefinindo sua senha ou pode tentar novamente mais tarde.";
    const messageErrorNetworkRequestFailed = "Você está sem conexão com a internet no momento. Por favor, verifique sua conexão e tente novamente.";

    const messageDefaultError = "Erro desconhecido ao realizar login, tente novamente.";

    const translateErros =
      error.includes(errorCodeUserNotFound)
        ? messageErrorUserNotFound
        : 
        error.includes(errorCodeWrongPassword)
        ? messageErrorWrongPassword
        : 
        error.includes(errorCodeDesactivatedUser)
        ? messageErrorDesactivatedUser
        : 
        error.includes(errorCodeNetworkRequestFailed)
        ? messageErrorNetworkRequestFailed
        : messageDefaultError;
    console.log("error", error);
    toast.error(translateErros, { theme: "colored" });

    yield put(apiError(translateErros));
  }
}

function* logoutUser() {
  try {
    logoutUserUtil();
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
      toast.info(`Deslogado com sucesso! Esperamos te ver em breve`, {
        theme: "colored",
        position: "bottom-right",
      });
    }
  } catch (error) {
    console.log("erro:", error);
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();

      const response = yield call(fireBaseBackend.socialLoginUser, type);
      if (response) {
        history.push("/dashboard");
      } else {
        history.push("/login");
      }
      /* setLoggeedInUser(response); */
      yield put(loginSuccess(response));
    }
  } catch (error) {
    toast.error(error, { theme: "colored" });
    yield put(apiError(error));
  }
}

function* loginUid({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(
        fireBaseBackend.loginUserUid,
        user.password
      );

      const formattedData = JSON.parse(response);

      if (response) {
        yield put(loginSuccess(formattedData));

        toast.success(`Bem vindo! ${formattedData.displayName}`, {
          theme: "colored",
          position: "bottom-right",
        });

        history.push("/choose-company");
      } else {
        history.push("/login");
      }
    }
  } catch (error) {
    yield put(apiError(error));
    
    toast.error(error, { theme: "colored" });
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGIN_UID, loginUid);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
