import firebase from "firebase/compat/app";
// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/remote-config";
import { toast } from "react-toastify";
import { isIntegrador } from "../utils";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.remoteConfig().settings.minimumFetchIntervalMillis = 60000; //1 minuto;
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          const formattedUser = JSON.stringify(user);
          const formattedData = JSON.parse(formattedUser);
          firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then(idTokenResult => {
              var obj = {
                ...formattedData,
                claims: idTokenResult.claims,
                integrador: isIntegrador(idTokenResult.claims.code),
              };

              if (obj.claims.code) {
                console.debug({'usuario':obj.claims.code})
                setLoggeedInUser(obj);
              } else {
                logoutUser();
                toast.error(
                  "Seu cadastro de vendedor não foi localizado, por favor, entre em contato com o setor de cadastro",
                  {
                    theme: "colored",
                  }
                );
              }
            })
            .catch(error => {
              return error;
            });
        } else {
          logoutUser();
        }
      });
    }
  }

  getFirebaseRemoteConfig = ({ defaultValue, methodLogin }) => {
    return new Promise((resolve, reject) => {
      firebase
        .remoteConfig()
        .fetchAndActivate()
        .then(
          () => {
            var value;
            var type = typeof defaultValue;

            switch (type) {
              case "string":
                value = firebase.remoteConfig().getString(methodLogin);
                value != "" ? value : defaultValue;
                return resolve(value);
              case "boolean":
                value = firebase.remoteConfig().getBoolean(methodLogin);
                value != false ? value : defaultValue;
                return resolve(value);
            }
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          () => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          () => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          () => {
            const currentUser = JSON.stringify(firebase.auth().currentUser);
            resolve(currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with uid
   */
  loginUserUid = (password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithCustomToken(password)
        .then(
          () => {
            const currentUser = JSON.stringify(firebase.auth().currentUser);
            resolve(currentUser);
          },
          error => {
            console.log("ERRO loginUID! ", error);
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back to this page.
      url: "https://clubev4-frontend-saopaulo-l2nmyjutgq-rj.a.run.app/login",
      handleCodeInApp: false,
    };
    return new Promise(resolve => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, actionCodeSettings)
        .then(function (user) {
          resolve(user);
        })
        .catch(function (error) {
          //reject(this._handleError(error));
          return error;
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
          logoutUser();
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = async type => {
    let provider = {};
    if (type === "google") {
      return new Promise((resolve, reject) => {
        provider = new firebase.auth.GoogleAuthProvider();
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            let user = result.user;
            resolve(user);
          })
          .catch(error => {
            reject(this._handleError(error));
          });
      });
    } else if (type === "facebook") {
      return new Promise((resolve, reject) => {
        provider = new firebase.auth.FacebookAuthProvider();
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            let user = result.user;
            resolve(user);
          })
          .catch(error => {
            console.log(error);
            reject(this._handleError(error));
          });
      });
    } else if (type === "apple") {
      return new Promise((resolve, reject) => {
        provider = new firebase.auth.OAuthProvider("apple.com");
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            let user = result.user;
            resolve(user);
          })
          .catch(error => {
            console.log(error);
            reject(this._handleError(error));
          });
      });
    } else if (type === "microsoft") {
      return new Promise((resolve, reject) => {
        provider = new firebase.auth.OAuthProvider("microsoft.com");
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            let user = result.user;
            resolve(user);
          })
          .catch(error => {
            console.log(error);
            reject(this._handleError(error));
          });
      });
    }
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}
const setLoggeedInUser = user => {
  localStorage.setItem("_app_auth_user", JSON.stringify(user));
};

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

const getAuthenticatedUser = () => {
  const user = localStorage.getItem("_app_auth_user");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const logoutUser = () => {
  localStorage.removeItem("_app_auth_user");
};

export {
  initFirebaseBackend,
  getFirebaseBackend,
  getAuthenticatedUser,
  setLoggeedInUser,
  logoutUser,
};
