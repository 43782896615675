import React from "react";
import { CardBody, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { clearNewOrder } from "../../../store/actions";

const PedidoEmitido = ({ type, hiddenVisualizar }) => {
  const dispatch = useDispatch();
  const { emissaoPedido } = useSelector(state => ({
    emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
  }));

  return (
    <Card className="pedido-emitido" style={{ marginBottom: 200 }}>
      <CardBody
        className="d-flex flex-column align-items-center"
        style={{ padding: 50 }}
      >
        <div className="check" style={{ fontSize: 50, color: "#13b013" }}>
          <i className="ph-check-circle"></i>
        </div>
        <h3 style={{ fontSize: 18 }}>
          Seu {type == "order" ? "pedido" : "orçamento"} foi realizado com
          sucesso!
        </h3>

        <p style={{ marginBottom: 30 }}>
          {!hiddenVisualizar &&
            `Número do ${type == "order" ? "pedido" : "orçamento"} : #
          ${emissaoPedido?.data?.CODPEDIDO || ""}`}
        </p>

        <div className="botoes-emitido">
          <Link
            hidden={hiddenVisualizar}
            onClick={() => dispatch(clearNewOrder())}
            to={
              type == "order"
                ? `/solar/pedidos/${emissaoPedido?.data?.CODPEDIDO || ""}/${emissaoPedido?.data?.CODFILIAL
                }/order`
                : `/solar/orcamento/${emissaoPedido?.data?.CODPEDIDO || ""}/${emissaoPedido?.data?.CODFILIAL
                }`
            }
            style={{
              fontSize: 14,
              padding: "15px 30px",
              fontWeight: "bold",
              color: "#FFF",
              backgroundColor: "#FF601A",
              border: "1px solid #DCF3FE",
              margin: "20px 10px 0",
              borderRadius: 5,
            }}
          >
            Visualizar {type == "order" ? "pedido" : "orçamento"}{" "}
          </Link>
          <a
            href={`/solar/pedidos/novo/fora-kit`}
            style={{
              fontSize: 14,
              padding: "15px 30px",
              fontWeight: "bold",
              color: "#FF601A",
              backgroundColor: "#fff7f2",
              border: "1px solid #fff7f2",
              margin: "20px 10px 0",
              borderRadius: 5,
            }}
          >
            Novo {type == "order" ? "pedido" : "orçamento"}
          </a>
        </div>
      </CardBody>
    </Card>
  );
};

export default PedidoEmitido;
