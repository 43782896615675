import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Sidebar Menu Items module
import SidebarMenu from "./sidebarMenu/reducer";
import Dashboard from "./dashboard/reducer";
import Customers from "./customers/reducer";
import Download from "./download/reducer";
import Warranty from "./warranty/reducer";
import Events from "./events/reducer";
import Gallery from "./gallery/reducer";
import Order from "./order/reducer";
import Rules from "./rules/reducer";
import Budget from "./budget/reducer";
import Seller from "./seller/reducer";
import PromotionRules from "./promotionRules/reducer";
import Settlements from "./settlements/reducer";
import Closures from "./closures/reducer";
import OrderList from "./orderList/reducer";
import Management from "./management/reducer";
import ChooseCompany from "./chooseCompany/reducer";
import Leads from "./leads/reducer";
import ModalRegister from "./modal/reducer";
import KitsNew from "./kitsNew/reducer";
import FirebaseRemoteConfig from "./firebaseRemoteConfig/reducer";
import RegistrationsReducer from "./registrations/reducer";
import Manufacturers from "./manufacturers/list/reducer";
import ManufacturerDetails from "./manufacturers/detail/reducer";
import CustomField from "./customField/reducer";
import WarrantyDownload from "./warranty/download/reducer";
import SharedResale from "./sharedResales/reducer";
import Collaborator from "./collaborator/reducer";
import CatalogProducts from "./catalogProducts/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  SidebarMenu,
  Dashboard,
  Customers,
  Download,
  Warranty,
  RegistrationsReducer,
  Events,
  Order,
  Rules,
  Gallery,
  Budget,
  Seller,
  PromotionRules,
  Settlements,
  Closures,
  OrderList,
  Management,
  ChooseCompany,
  Leads,
  ModalRegister,
  KitsNew,
  FirebaseRemoteConfig,
  Manufacturers,
  ManufacturerDetails,
  CustomField,
  WarrantyDownload,
  SharedResale,
  Collaborator,
  CatalogProducts
});

export default rootReducer;
