import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, Col, Row, Card, Input, Label, Button } from "reactstrap";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { consultaCep } from "../../../api";
import { addEndValido } from "../../../store/actions";
import { formatCurrencyInput, sanitizeCurrencyInput } from "../../../utils";

const ShippingData = ({
  shippingZipCode,
  setShippingZipCode,
  shippingTotalValue,
  setShippingTotalValue,
  setShippingAddress
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { frete } = useSelector(state => ({
    frete: state.Order.newOrder.newOrder.frete,
  }));

  const getShippingFreight = () => {
    if (shippingZipCode.length == 0 || shippingTotalValue.length == 0) {
      return toast.warning(
        "Preencha com todos os dados de remessa para calcular o frete!",
        { theme: "colored" }
      );
    }

    setLoading(true);

    consultaCep(shippingZipCode)
      .then(res => {
        setShippingAddress({
          address: res.address.address,
          city: res.address.city,
          complement: res.address.complement,
          district: res.address.district,
          state: res.address.state
        });

        dispatch(addEndValido(res.address));

        setLoading(false);
      })
      .catch(err => {
        toast.error(
          err,
          { theme: "colored" }
        );
        setLoading(false);
      });
  }

  return (
    <Row className="mt-3">
      <Card>
        <CardBody>
          <Row>
            <Col md={12}>
              <p
                className="mb-0 d-flex align-items-center"
                style={{ fontWeight: "bold" }}
              >
                <i
                  className="ph-note-pencil"
                  style={{
                    color: "#FF601A",
                    marginRight: 20,
                    fontSize: 25,
                  }}
                ></i>{" "}
                Dados de remessa
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <Label>CEP:</Label>
              <InputMask
                className="form-control"
                mask="99999-999"
                value={shippingZipCode}
                onChange={e => setShippingZipCode(e.target.value.replace(/[^0-9]+/g, ""))}
                disabled={loading || frete.loading}
              />
              {(loading) && (
                <p style={{ marginTop: 5 }}>Buscando dados do cep...</p>
              )}
              {(frete.loading) && (
                <p style={{ marginTop: 5 }}>Calculando valor do frete...</p>
              )}
            </Col>
            <Col md={4}>
              <Label>Valor total da remessa:</Label>
              <Input
                className="form-control"
                value={formatCurrencyInput(shippingTotalValue) || ""}
                onChange={e => setShippingTotalValue(sanitizeCurrencyInput(e.target.value))}
                disabled={loading || frete.loading}
              />
            </Col>
            <Col md={4}>
              <Button
                style={{
                  backgroundColor: "#FF601A",
                  color: "#FFFFFF",
                  marginTop: 30,
                  border: "none",
                  borderRadius: 6,
                }}
                onClick={() => getShippingFreight()}
              >
                {"Calcular Frete"}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
};

export default ShippingData;
