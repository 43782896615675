import { CatalogProductsActionTypes } from "./actionTypes";

export const catalogProductsApiSuccess = (actionType, data) => ({
  type: CatalogProductsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const catalogProductssApiFail = (actionType, error) => ({
  type: CatalogProductsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getStarterOrderFilters = (data) => ({
  type: CatalogProductsActionTypes.GET_STARTER_FILTERS_PRODUCTS,
  payload: data,
});

export const getProductsDetails = skuList => ({
  type: CatalogProductsActionTypes.GET_PRODUCTS_DETAILS,
  payload: skuList,
});

export const getPaymentConditions = table => ({
  type: CatalogProductsActionTypes.GET_PAYMENT_CONDITIONS,
  payload: table
});

export const getCategories = group => ({
  type: CatalogProductsActionTypes.GET_CATEGORIES,
  payload: group
});

export const getCapacities = (group, category) => ({
  type: CatalogProductsActionTypes.GET_CAPACITIES,
  payload: { group, category }
});

export const getProductsList = selectedFilterProducts => ({
  type: CatalogProductsActionTypes.GET_PRODUCTS,
  payload: selectedFilterProducts
});