import React, { useState, useEffect, useCallback } from "react";
import { CardBody, Col, Row, Card, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addStatus,
  editQtdWithoutKit,
  removeFromCartWithoutKit,
} from "../../../store/actions";
import { dateNow, formatCurrencyInput, sanitizeCurrencyInput } from "../../../utils";
import { toast } from "react-toastify";
import { Trash, Plus, FilePdf } from "phosphor-react";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ModalEditQtd from "./ModalEditQtd";
import { valueByQTD } from "../../../utils/formulas";
import RtAndCashBack from "./RtAndCashBack";
import { useProfile } from "../../../hooks";
import { isInverter } from "../../utils/functions/solarUtils";

const AddedItem = ({
  item,
  addValTot,
  valores,
  setItensCarrinho,
  addValorRt,
  ocultarRt,
  percentCashBackAll,
  percentCashBackAllOnBlur,
  setValTotAndRt,
  changeFormOfPayment,
  itHasForecast,
  addValorRtNoRetention,
  shippingValues,
  setShippingValues,
  triangularSale,
}) => {
  const dispatch = useDispatch();
  const { newOrder, user } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
      user: state.Profile.profileDetails.user,
    };
  });
  const { userProfile } = useProfile();
  const displayName = userProfile
    ? userProfile["displayName"] !== null
      ? userProfile["displayName"].split(" ")[0]
      : userProfile["email"]
    : "";

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpeModalEditQtd, setIsOpenModalEditQtd] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const userCode = user?.code ? user?.code : "";

  useEffect(() => {
    if (percentCashBackAll != "0") {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [percentCashBackAll]);

  useEffect(() => {
    if (percentCashBackAll == "0" && collapse) {
      setItensCarrinho(state => {
        return {
          ...state,
          [item.nome]: {
            PRCRT: item.subTotal,
          },
        };
      });
    }
  }, [percentCashBackAll]);

  useEffect(() => {
    if (changeFormOfPayment) {
      setCollapse(false);
    }
  }, [changeFormOfPayment]);

  const onCloseDelete = useCallback(() => {
    setIsOpenDelete(false);
  }, []);

  const onDeleteMessage = () => {
    const date = dateNow();
    const hours = new Date().toLocaleTimeString();

    let status = {
      statusName: "remove",
      name: item.nome,
      date: date,
      hours: hours,
      userCode: userCode,
      displayName: displayName,
    };

    dispatch(addStatus(status));

    if (isInverter(item)) {
      removeStringBoxAndMC4();
    } else {
      addValTotal();
    }

    let removedItems = [item.sku];

    if (item.pecasVinculadas) {
      removedItems = [...removedItems, ...item.pecasVinculadas.map(peca => peca.sku)]
    }

    setShippingValues(prevState => {
      const newShippingValues = Object.fromEntries(
        Object.entries({ ...prevState  }).filter(([key]) => !removedItems.includes(key))
      ); 
    
      return newShippingValues;
    });

    dispatch(removeFromCartWithoutKit({ produto: item }));

    toast.info(`${item.nome} removido do carrinho.`, {
      theme: "colored",
    });

    setIsOpenDelete(false);
  };

  const removeStringBoxAndMC4 = () => {
    item?.pecasVinculadas?.map(peca => {
      newOrder.addedItems.filter(item => item.produto[0].sku == peca.sku).map(product => {
        const productItem = product.produto[0];
        const newQuantity = productItem.qtd - (peca.quantityPerInverter * item.qtd);
        if (newQuantity > 0) {
          editItemQuantity(productItem, newQuantity);
        } else {
          dispatch(removeFromCartWithoutKit({ produto: productItem }));
          removeLinkedPartsValTotal(productItem);
        }
      });
    });
  };

  const editItemQuantity = (product, newItemQuantity) => {
    const valTotal = {
      ...valores,
      [product.nome]: valueByQTD(
        product.precoCliente,
        newItemQuantity
      ),
    };
    addValTot(valTotal);
    dispatch(
      editQtdWithoutKit(product.nome, newItemQuantity)
    );
  }

  const removeLinkedPartsValTotal = (productItem) => {
    let valTotal = valores;
    delete valTotal[
      productItem?.id
        ? productItem?.id + " " + productItem?.nome
        : productItem.nome
    ];
    addValTot(valTotal);
    addValTotal();
  }

  const addValTotal = () => {
    let valTotal = valores;

    delete valTotal[item?.id ? item?.id + " " + item.nome : item.nome];
    addValTot(valTotal);
  };

  const modalConfirm = () => {
    setIsOpenDelete(true);
  };

  const modalEditQtd = () => {
    setIsOpenModalEditQtd(true);
  };

  const handleShippingValue = e => {
    const value = sanitizeCurrencyInput(e.target.value);

    setShippingValues(prevState => {
      const newValues = Object.fromEntries(
        Object.entries({ ...prevState, [item.sku]: value }).filter(([key, value]) => value !== "")
      );
      return newValues;
    });
  }

  const precoCliente = item?.precoClienteAcrescimo
    ? item.precoClienteAcrescimo
    : item.precoCliente;

  const qtd = item?.qtd ? item.qtd : 1;
  const subTotal = precoCliente * qtd;

  const showForecast = itHasForecast.some(value => value.produto[0].sku == item.sku);

  return (
    <Card className="mb-0 mt-4" style={{ overflow: "hidden" }}>
      <CardBody>
        <Row style={{ alignItems: "center" }}>
          <Col md={triangularSale ? 5 : 6}>
            <p
              data-toggle="tooltip"
              data-placement="top"
              title={item.nome}
              className="mb-0"
              style={{
                fontWeight: 500,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item?.nome}
            </p>
          </Col>
          <Col style={{ padding: 0, textAlign: "center" }} md={triangularSale ? 1 : 2}>
            {item?.linkFichaTecnica ? (
              <a target="_blank" rel="noreferrer" href={item?.linkFichaTecnica}>
                <FilePdf
                  style={{ cursor: "pointer" }}
                  size={25}
                  color="#FF601A"
                />
              </a>
            ) : (
              ""
            )}
          </Col>
          <Col md={1}>{item?.qtd ? Math.round(item.qtd) : 1}</Col>{" "}
          {showForecast
            ? <Col md={2}>{item?.previsao}</Col>
            : <Col md={2}>Pronta entrega</Col>
          }
          {triangularSale && (
            <Col md={2}>
              <Input
                className="form-control"
                value={formatCurrencyInput(shippingValues[item.sku]) || ""}
                onChange={handleShippingValue}
              />
            </Col>
          )}
          <Col md={1}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={modalEditQtd}
                className="button-trash-and-edit"
                data-toggle="tooltip"
                data-placement="top"
                title="Alterar a quantidade deste produto"
              >
                <Plus size={18} color="#1392c9" weight="bold" />
              </button>
              <button
                onClick={modalConfirm}
                className="button-trash-and-edit"
                data-toggle="tooltip"
                data-placement="top"
                title="Remover este produto"
              >
                <Trash size={18} color="#c91313" weight="bold" />
              </button>
            </div>
          </Col>
        </Row>
        <Row>
          <RtAndCashBack
            item={item}
            subTotal={subTotal}
            valores={valores}
            setItensCarrinho={setItensCarrinho}
            addValTot={addValTot}
            addValorRt={addValorRt}
            ocultarRt={ocultarRt}
            percentCashBackAll={percentCashBackAll}
            percentCashBackAllOnBlur={percentCashBackAllOnBlur}
            collapse={collapse}
            setValTotAndRt={setValTotAndRt}
            changeFormOfPayment={changeFormOfPayment}
            addValorRtNoRetention={addValorRtNoRetention}
          />
        </Row>
      </CardBody>
      <ConfirmationModal
        message="Confirma remoção deste item?"
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onConfirm={onDeleteMessage}
        loading={false}
      />
      <ModalEditQtd
        isOpeModalEditQtd={isOpeModalEditQtd}
        setIsOpenModalEditQtd={setIsOpenModalEditQtd}
        addValTot={addValTot}
        valores={valores}
        item={item}
      />
    </Card>
  );
};

export default AddedItem;
