import { jsPDF } from "jspdf";
import coverImage from "../../../assets/images/catalogProducts/cover.png";
import headerImage from "../../../assets/images/catalogProducts/header.png";
import footerImage from "../../../assets/images/catalogProducts/footer.png";
import defaultImage from "../../../assets/images/default.png";
import dividerImage from "../../../assets/images/catalogProducts/divider.png"; // Imagem de divisão

export async function generatePdf(products, user) {
  const convertImageToBase64 = (imagePath) => {
    return new Promise((resolve, reject) => {
      fetch(imagePath)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Image = reader.result;
            const format = base64Image.startsWith('data:image/jpeg') ? 'JPEG' : 'PNG';
            resolve({ base64Image, format });
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
    });
  };

  const convertAllImagesToBase64 = async () => {
    try {
      const imagesBase64 = await Promise.all([
        convertImageToBase64(defaultImage),
        convertImageToBase64(coverImage),
        convertImageToBase64(headerImage),
        convertImageToBase64(footerImage),
        convertImageToBase64(dividerImage), // Converte a imagem do divisor
      ]);
      return imagesBase64;
    } catch (error) {
      console.error('Erro ao converter imagens: ', error);
      return [];
    }
  };

  const [defaultImage64, coverImage64, headerImage64, footerImage64, dividerImage64] = await convertAllImagesToBase64();

  const pageWidth = 2490;
  const pageHeight = 4379;

  const doc = new jsPDF({
    unit: 'px',
    format: [pageWidth, pageHeight],
  });

  doc.addImage(coverImage64.base64Image, coverImage64.format, 0, 0, pageWidth, pageHeight);

  const headerHeight = 572;
  const addHeader = () => {
    doc.addImage(headerImage64.base64Image, headerImage64.format, 5, 5, pageWidth, headerHeight);
  };

  const footerHeight = 572;
  const addFooter = () => {
    doc.addImage(footerImage64.base64Image, footerImage64.format, 0, pageHeight - 651, pageWidth, footerHeight);
  };

  const createNewPage = () => {
    doc.addPage();
    addHeader();
    addFooter();
  }

  createNewPage();

  const productHeight = 906;
  const productWidth = 529;
  const imageSize = 401;
  const maxItemsPerPage = 9;

  const dividerHeight = 4;

  const generateProductsTable = async (items) => {
    let itemsAddedOnPage = 0;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const indexOnPage = itemsAddedOnPage % maxItemsPerPage;
      const rowIndex = Math.floor(indexOnPage / 3);
      const colIndex = indexOnPage % 3;
      const xOffset = 451 + (colIndex * productWidth);

      const marginTopProduct = 166;

      const yOffset = (headerHeight + marginTopProduct) + (rowIndex * (productHeight + dividerHeight + marginTopProduct));

      // Adiciona nova página se necessário
      if (indexOnPage === 0 && itemsAddedOnPage > 0) {
        createNewPage();
      }

      const imgToUse = item.details?.fotos?.length > 1
        ? await convertImageToBase64(item.details.fotos[1])
        : defaultImage64;

      doc.addImage(imgToUse.base64Image, imgToUse.format, xOffset + (productWidth - imageSize) / 2, yOffset, imageSize, imageSize);

      doc.setFontSize(48);
      doc.setFont('helvetica', 'normal');

      let descriptionLines = doc.splitTextToSize(item.DESCRICAO.trim(), productWidth - 20);
      let descriptionYOffset = yOffset + imageSize + 117;
      const centerX = xOffset + (productWidth / 2);

      const createDescriptionLines = () => {
        descriptionLines.forEach((line, lineIndex) => {
          const lineWidth = doc.getTextWidth(line);
          doc.text(line, centerX - (lineWidth / 2), descriptionYOffset + (lineIndex * 48));
        });
      }

      createDescriptionLines();

      const createPriceLine = () => {
        const price = `R$ ${Number(item?.PRCVENDA?.STRETPRCFILADIAS[0]?.PRCVENDA).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
        const priceLines = doc.splitTextToSize(price, productWidth - 20);

        const priceYOffset = descriptionYOffset + (descriptionLines.length * 48) + 40;
        priceLines.forEach((line, lineIndex) => {
          const lineWidth = doc.getTextWidth(line);
          doc.text(line, centerX - (lineWidth / 2), priceYOffset + (lineIndex * 48));
        });
      }

      createPriceLine();

      const createDivisorLine = () => {
        if (i < items.length - 1) {
          const dividerYOffset = yOffset + productHeight - 10; 
          const dividerXOffset = (pageWidth - 2026) / 2;
          doc.addImage(dividerImage64.base64Image, dividerImage64.format, dividerXOffset, dividerYOffset, 2026, dividerHeight); 
        }
      }

      createDivisorLine();

      itemsAddedOnPage++;
    }
  };

  await generateProductsTable(products);
  doc.save('Catalogo_de_produtos.pdf');
}
