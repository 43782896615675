import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

// components
import CustomSelect from "./CustomSelect";
import MyExportCSV from "../pages/Warranty/ConsultWarranty/MyExportCSV";
import BasicTable from "./BasicTable";
/*
  totalRecords,
  data,
  columns,
  defaultSorted,
  sizePerPageDropdown,
  isSerach,
  isExcel,
  keyField
  all are necessary parameters
*/

const PAGE_SIZES = [
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "30", value: 30 },
  { label: "50", value: 50 },
];

const Pagination = ({ paginationProps, onChangePage }) => {
  useEffect(() => {
    if (paginationProps.page) {
      onChangePage(paginationProps.page);
    }
  }, [paginationProps.page]);
  return <PaginationListStandalone {...paginationProps} />;
};

const DataTable = ({
  totalRecords,
  data,
  columns,
  defaultSorted,
  sizePerPageDropdown,
  isSerach,
  isExcel,
  keyField,
  sizePerPage,
  isPaginite = true,
}) => {
  const pageOptions = {
    totalSize: totalRecords ? totalRecords : 0,
    custom: true,
    paginationSize: 1,
    sizePerPage: sizePerPage,
  };

  const selectRow = {
    mode: "ROW_SELECT_DISABLED",
    hidden: true,
  };
  /* Serach  */
  const { SearchBar } = Search;

  const [paginationOptions, setPaginationOptions] = useState([...PAGE_SIZES]);

  const [selectedPage, setSelectedPage] = useState(paginationOptions[0]);
  const onChangePageSize = ({ page, onSizePerPageChange }, value) => {
    setSelectedPage(value);
    onSizePerPageChange(value.value, page);
  };
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / selectedPage.value));
  }, [totalRecords, selectedPage]);

  const [currentPage, setCurrentPage] = useState(0);
  const onChangePage = useCallback(page => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    var modifiedOpt = [...PAGE_SIZES];
    if (currentPage === totalPages) {
      const currentPageIdx = (modifiedOpt || []).findIndex(
        p => p.value + "" === selectedPage.value + ""
      );

      for (let i = currentPageIdx + 1; i < modifiedOpt.length; i++) {
        // if (i > currentPageIdx + 1) modifiedOpt[i].isDisabled = true;
        // else modifiedOpt[i].isDisabled = false;
        modifiedOpt[i].isDisabled = true;
      }
    } else {
      modifiedOpt = modifiedOpt.map(o => {
        return {
          ...o,
          isDisabled: false,
        };
      });
    }
    setPaginationOptions(modifiedOpt);
  }, [currentPage, totalPages, PAGE_SIZES, selectedPage]);

  const ref = useRef();

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField={keyField}
        columns={columns}
        data={data}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField={keyField}
            data={data}
            columns={columns}
            bootstrap4
            search
            exportCSV
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  {sizePerPageDropdown ? (
                    <Col md={6} lg={1}>
                      <CustomSelect
                        value={selectedPage}
                        options={paginationOptions}
                        onChange={value =>
                          onChangePageSize(paginationProps, value)
                        }
                      />
                      {/* <SizePerPageDropdownStandalone {...paginationProps} /> */}
                    </Col>
                  ) : null}
                  {isSerach ? (
                    <Col md="6">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  ) : null}
                  {isExcel ? <MyExportCSV {...toolkitProps.csvProps} /> : null}
                </Row>
                <div className="table-responsive">
                  <BasicTable
                    tableRef={ref}
                    keyField={keyField}
                    defaultSorted={defaultSorted}
                    selectRow={selectRow}
                    exportCSV
                    {...toolkitProps.baseProps}
                    {...paginationTableProps}
                  />
                </div>
                {isPaginite && (
                  <Row className="align-items-md-center mt-30">
                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                      <Pagination
                        paginationProps={{ ...paginationProps }}
                        onChangePage={onChangePage}
                      />
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </div>
  );
};

export default DataTable;
