import React, { useState } from "react";
import { Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { mask } from "../../../utils";
import { getVendedor as getVendedorApi } from "../../../api/index";

const PartnerCompany = ({ setParceiros, parceiros }) => {
  const [filters, setFilters] = useState({
    vendedores: [{ codigo: "", label: "" }],
  });
  const [isLoadingVendedores, setIsLoadingVendedores] = useState(false);
  const filterBy = () => true;

  const changeVendedores = inputValue => {
    setIsLoadingVendedores(true);
    setFilters({ ...filters, vendedores: [] });

    getVendedorApi(inputValue)
      .then(res => {
        let data = [];
        res.map(value => {
          data.push({
            codigo: value.CODIGO,
            nome: value.NOME,
            label: `${value.CODIGO} - ${value.NREDUZ.trim()}`,
            nreduz: value.NREDUZ,
            cnpj: value.CPFCNPJ,
          });
        });

        setFilters({ ...filters, vendedores: data });
        setIsLoadingVendedores(false);
      })
      .catch(console.log);
  };

  return (
    <FormGroup>
      <Label className="label-titulo">Selecione o Parceiro:</Label>
      <Row>
        <Col
          md={12}
          className="d-flex justify-content-end align-items-center mb-3"
        >
          <AsyncTypeahead
            id="parceiro"
            className="parceiro-input"
            onChange={setParceiros}
            options={filters.vendedores}
            placeholder="Pesquisar..."
            selected={parceiros}
            minLength={3}
            filterBy={filterBy}
            onSearch={changeVendedores}
            emptyLabel="Não foi encontrado nenhum vendedor"
            promptText="Buscando vendedores"
            searchText="Buscando vendedores"
            isLoading={false}
            inputProps={{
              id: "parceiro-selecionado",
            }}
            renderMenuItemChildren={option => (
              <div>
                {option.codigo} {option.nreduz}
                <div>
                  <small>{option.nome}</small>
                </div>
              </div>
            )}
          />
          {isLoadingVendedores ? (
            <Spinner className="loading-parceiro" />
          ) : (
            <i className="fas fa-search campo-buscar"></i>
          )}
        </Col>
        <Col md={12}>
          <Input
            placeholder="CPF/CNPJ Vendedor"
            value={parceiros[0]?.cnpj ? mask(parceiros[0]?.cnpj) : ""}
            disabled={true}
            readOnly
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

export default PartnerCompany;
