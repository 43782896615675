import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    .box-cart {
      height: fit-content;
      max-height: 600px;
      width: 100%;
      overflow: auto;
      padding: 10px 10px;
    }

    .row-resize {
      max-width:${props => (!props.ItHasForecast.length > 0 ? 1032 : 940)} 
      align-items: center;
                       
    }
  `}
`;
