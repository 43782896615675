import React, { useEffect, useRef, useState } from "react";

import { withRouter, Redirect, Link } from "react-router-dom";
import Logo from "../../assets/images/logos/adias/solar_laranja.svg";
import LogoAr from "../../assets/images/logos/adias/ar_branco.svg"; 
import imgLogin from "../../assets/images/img-login.svg";
import { Tooltip } from "reactstrap";

import solarLaranja from '../../assets/images/logos/adias/solar_laranja.svg';
import { Card, Container, Row, Col, Button, Form, FormGroup, Label, FormText } from 'reactstrap';
import {
  TelaToda,
  WaveImg,
  LogoImg,
  LinhaCard,
  LinkCriarConta,
  DividerLine,
  DividerText,
  DividerContainer,
  IconContainer,
  IconLink,
  CardLogin,
  TituloCard,
  SubtituloCard,
  Formulario,
  Icone,
  IconEye,
  InputUser,
  InputLabel,
  InputSenha,
  CheckboxSenha,
  Input,
  Memorizar,
  LinkRecuperar,
  BotaoEntrar,
  CriarConta,
  LinkCriar,
  LinhaRedes,
  TextoRedes,
  Hr,
  RedesSociais,
  BtnSocial,
  BarraLateral,
  ImgSofa,
  DivBotoes,
  BotaoCriar,
  BotaoVoltar,
  BotaoEnviar,
  BotaoEnviarLink,
  LogoDiv,
  TextoConta,
  TextoParceiro,
  ColCriarConta,
} from "./style";
import { Spinner } from "reactstrap";
import { useProfile } from "../../hooks/UserHooks";
import {
  loginUser,
  loginUID,
  openModalRegister,
  userForgetPassword,
  getFireBaseRemoteConfig,
} from "../../store/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { socialLogin } from "../../store/actions";
import LogoSolar from "../../Solar/assets/images/logos/logo-solar.png";

import ModalRegister from "./ModalRegister";
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import { apiClient2 } from "../../api/apiCore2";
import { apiClient } from "../../api/apiCore";
import { GET_FIREBASE_REMOTE_CONFIG_USER_RESTRICT_PAGE } from "../../store/firebaseRemoteConfig/actionTypes";
import { useDataLayer } from "../../hooks/DataLayerHooks";
import SvgIcon from "../../components/SvgIcon";

const Login = props => {
  const dispatch = useDispatch();
  const { pushToDataLayer } = useDataLayer();
  const [recuperarSenha, setRecuperarSenha] = useState(false);

  const [, setCadastro] = useState(false);
  const { userProfile, loading, token } = useProfile();
  const [tooltipOpenFace, setTooltipOpenFace] = useState(false);
  const [tooltipOpenApple, setTooltipOpenApple] = useState(false);
  const [eyeVisible, setEyeVisible] = useState(false);
  const { loadingLogin, open, social_login } = useSelector(state => ({
    loadingLogin: state.Login.loading,
    open: state.ModalRegister.open,
    social_login: state.FirebaseRemoteConfig.social_login,
  }));
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const [emailInfo, setEmailInfo] = useState({
    msg: "",
    link: "",
  });

  const [isClicked, setIsClicked] = useState(false);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [lostPassword, setLostPassword] = useState({
    email: "",
  });
  const refButtonFace = useRef();
  const refButtonGoogle = useRef();
  const refButtonMicrosoft = useRef();
  const refButtonApple = useRef();
  useEffect(() => {
    dispatch(getFireBaseRemoteConfig("SOCIAL_LOGIN", ""));
  }, []);

  const isVisibleButtonsLogin = () => {
    var social_login_object = JSON.parse(social_login);
    var social_login_exists = ["facebook", "google", "microsoft", "apple"];
    var disabled_social_login = [
      { name: "facebook", funcao: () => refButtonFace.current.remove() },
      { name: "google", funcao: () => refButtonGoogle.current.remove() },
      { name: "microsoft", funcao: () => refButtonMicrosoft.current.remove() },
      { name: "apple", funcao: () => refButtonApple.current.remove() },
    ];
    // verifica qual login irá ficar desabilitado
    let social_login_disabled = social_login_exists.filter(
      a => !social_login_object.social_login.includes(a)
    );

    for (var data in disabled_social_login) {
      var name_social_login = disabled_social_login[data].name;
      var find_name_social_login = social_login_disabled.find(
        item => item == name_social_login
      );
      if (find_name_social_login) {
        disabled_social_login[data].funcao();
      }
    }
  };

  useEffect(() => {
    if (social_login != "") {
      isVisibleButtonsLogin();
    }
  }, [social_login]);

  const singIn = e => {
    e.preventDefault();
    if (!user.email) {
      return toast.error(`Digite um email`, {
        theme: "colored",
        position: "bottom-right",
      });
    }

    if (user.email.indexOf("LOGIN_UID") !== -1 && user.password.length > 6) {
      toast.success("Identificamos login usando UID!", {
        theme: "colored",
      });
      dispatch(
        getFireBaseRemoteConfig(
          "USER_PAGE_RESTRICT_CREATE_PROMOTION",
          "",
          GET_FIREBASE_REMOTE_CONFIG_USER_RESTRICT_PAGE
        )
      );
      return dispatch(loginUID(user, props.history));
    }

    if (user.email.indexOf("@") == -1 || user.email.indexOf(".") == -1) {
      return toast.error(`Digite um email válido`, {
        theme: "colored",
        position: "bottom-right",
      });
    }
    if (!user.password) {
      return toast.error(`Digite uma senha`, {
        theme: "colored",
        position: "bottom-right",
      });
    }
    dispatch(
      getFireBaseRemoteConfig(
        "USER_PAGE_RESTRICT_CREATE_PROMOTION",
        "",
        GET_FIREBASE_REMOTE_CONFIG_USER_RESTRICT_PAGE
      )
    );

    pushToDataLayer(
      'login',
      {
        user_data: user.email
      },
      { isSendStore: false }
    );

    dispatch(loginUser(user, props.history));
  };
  const fireBaseBackend = getFirebaseBackend();

  const getPassword = async () => {
    dispatch(userForgetPassword(lostPassword));
    setLoadingResetPassword(false);
    setIsClicked(true);
  };

  if (userProfile && !loading && token) {
    return <Redirect to={{ pathname: "/choose-company" }} />;
  }

  const signIn = type => {
    dispatch(socialLogin(type, props.history));
    dispatch(
      getFireBaseRemoteConfig(
        "USER_PAGE_RESTRICT_CREATE_PROMOTION",
        "",
        GET_FIREBASE_REMOTE_CONFIG_USER_RESTRICT_PAGE
      )
    );
  };

  const googleResponse = type => {
    signIn(type);
  };

  const facebookResponse = type => {
    signIn(type);
  };

  const microsoftResponse = type => {
    signIn(type);
  };
  /*
  const appleResponse = type => {
    signIn(type);
  }; */

  const toggle = option => {
    if (option == "facebook") {
      setTooltipOpenFace(!tooltipOpenFace);
    } else {
      setTooltipOpenApple(!tooltipOpenApple);
    }
  };

  const getEmailAddress = () => {
    setLoadingResetPassword(true);
    const indexAt = lostPassword.email.indexOf("@");
    const emailOperator = lostPassword.email.substring(indexAt + 1);

    let website, name;

    switch (emailOperator) {
      case "hotmail.com":
      case "outlook.com":
      case "live.com":
      case "hotmail.com.br":
      case "outlook.com.br":
      case "live.com.br":
        name = "Outlook";
        website = "https://www.outlook.live.com/owa/";
        break;

      case "gmail.com":
      case "gmail.com.br":
        name = "Gmail";
        website = "https://www.gmail.com";
        break;

      case "yahoo.com":
      case "yahoo.com.br":
        name = "Yahoo";
        website = "https://www.mail.yahoo.com";
        break;

      case "adias.com":
      case "adias.com.br":
        name = "E-mail";
        website = "https://webmail.exchangecorp.com.br/";
        break;

      case "adias.solar":
      case "adiassolar.com.br":
        name = "E-mail";
        website = "https://outlook.office.com/mail/";
        break;

      default:
        name = "";
        website = "";
    }

    setEmailInfo(
      name ? { msg: `Ir para ${name}`, link: website } : { msg: "", link: "" }
    );

    verifyEmailExists();
  };

  const verifyEmailExists = async () => {
    try {
      const resp = await apiClient.get(
        `/api/checkFirebaseEmail/${lostPassword.email}`
      );
      var status = resp.status;
      if (status) {
        getPassword();
      }
    } catch (err) {
      setLoadingResetPassword(false);
      toast.info("Email não econtrado em nossa base de dados", {
        theme: "colored",
      });
    }
  };

  return (
    <TelaToda>
      <WaveImg/>
      <LogoImg/>
      {!recuperarSenha ? (
        <CardLogin className="shadow-lg card flex-wrap d-flex flex-row p-0">
        <Row className="p-4 pt-2 d-flex flex-wrap justify-content-start">
          <form onSubmit={singIn} >
            <TituloCard className="mx-2 mb-0">Bem vindo de volta!</TituloCard>
            <SubtituloCard className="mx-2">Entre com seu e-mail e senha</SubtituloCard>
            <Formulario className="mx-2 input-user">
                <SvgIcon name="person-fill" className="icon icon-sm"/>
              <InputUser
                name="email"
                value={user.email}
                className="input-form"
                placeholder="Digite o seu e-mail"
                onChange={e =>
                  setUser({ ...user, [e.target.name]: e.target.value })
                }
              />
            </Formulario>
            <Formulario className="mt-2 mx-2 mb-2 input-user">
              <SvgIcon name="lock" className="icon icon-sm"/>
              <InputUser
                type={eyeVisible ? "text" : "password"}
                name="password"
                className="input-form"
                value={user.password}
                placeholder="Digite a sua senha"
                onChange={e =>
                  setUser({ ...user, [e.target.name]: e.target.value })
                }
              />
              <IconEye onClick={() => setEyeVisible(prevState => !prevState)}>
                {eyeVisible ? (
                  <i style={{ marginTop: 2 }} className="ph-eye-slash" />
                ) : (
                  <i style={{ marginTop: 2 }} className="ph-eye" />
                )}
              </IconEye>
            </Formulario>
              <Row>
                <CheckboxSenha  className="mx-2">
                <Col md={5} className="d-flex">

                  <Input type="checkbox" />
                  <Memorizar>Memorizar senha</Memorizar>
                </Col>
                <Col md={7} className="d-flex justify-content-end">
                  <LinkRecuperar
                    style={{ cursor: "pointer" }}
                    onClick={() => setRecuperarSenha(!recuperarSenha)}
                  >
                    <span style={{textDecoration: "underline"}}>Esqueci minha senha </span><i className="ph-caret-right"></i>
                  </LinkRecuperar>
                </Col>
                </CheckboxSenha>
              </Row>
            <BotaoEntrar type="submit">
              {loadingLogin ? <Spinner /> : "Entrar"}
            </BotaoEntrar>
          </form>
          <div className="d-flex flex-wrap pt-0 p-4">
            <span className="text-white">Ainda não tem uma conta?</span>
            <LinkCriarConta className="d-flex justify-content-end"  onClick={() => dispatch(openModalRegister(true))} 
            >
              <span style={{textDecoration: "underline"}}>Crie sua conta</span>
            </LinkCriarConta>
          </div>
          {/* <CriarConta>
            Ainda não tem uma conta?
            <LinkCriar onClick={() => dispatch(openModalRegister(true))}>
              Crie sua conta <i className="ph-caret-right"></i>
            </LinkCriar>
          </CriarConta> */}
          <LinhaRedes>
          {/* <DividerContainer>
            <DividerLine />
              <DividerText>ou entre com</DividerText>
            <DividerLine />
          </DividerContainer>
          */}
          {/* <RedesSociais>
            <BtnSocial
              ref={refButtonGoogle}
              color="#DD4B39"
              onClick={() => googleResponse("google")}
            >
              <i className="fab fa-google"></i>
            </BtnSocial>
            <Tooltip
              placement="right"
              isOpen={tooltipOpenFace}
              target="facebook"
              toggle={() => toggle("facebook")}
            >
              Esta opção será liberada em breve!
            </Tooltip>

            <BtnSocial
              ref={refButtonFace}
              id="facebook"
              color="#3B5999"
              onClick={() => facebookResponse("facebook")}
            >
              <i className="fab fa-facebook-f"></i>
            </BtnSocial>

            <BtnSocial
              id="microsoft"
              ref={refButtonMicrosoft}
              onClick={() => microsoftResponse("microsoft")}
            >
              <i className="fab fa-microsoft"></i>
            </BtnSocial>
            <BtnSocial
              id="apple"
              color="#000"
              ref={refButtonApple}
              style={{ opacity: "0.2", cursor: "not-allowed" }}
            >
              <i className="fab fa-apple"></i>
            </BtnSocial>
          </RedesSociais> */}
          </LinhaRedes>
          </Row>
        </CardLogin>
      ) : (
        <Card style={{maxWidth: "500px"}}>
          {isClicked ? (
            <>
              <LogoDiv className="d-flex justify-content-center p-3">
                <img style={{width: "185px", borderRadius: "15px", backgroundColor: "#2D567B"}} src={LogoAr} />
                {/* <img src={LogoSolar} /> */}
              </LogoDiv>
              <LinhaCard />
              <TituloCard className="p-3 text-black">E-mail enviado com sucesso!</TituloCard>
              <SubtituloCard className="p-3 text-black">
                Verifique seu e-mail para dar continuidade a atualização da
                senha
              </SubtituloCard>
              <DivBotoes className="p-3">
                <BotaoVoltar
                  onClick={() => {
                    setIsClicked(false);
                    setRecuperarSenha(!recuperarSenha);
                  }}
                >
                  Voltar
                </BotaoVoltar>
                {emailInfo.link !== "" ? (
                  <BotaoEnviarLink
                    href={emailInfo.link}
                    target="_blank"
                    onClick={() => {
                      setIsClicked(false);
                      setRecuperarSenha(!recuperarSenha);
                    }}
                    replace={true}
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                  >
                    {emailInfo.msg}
                  </BotaoEnviarLink>
                ) : null}
              </DivBotoes>
            </>
          ) : (
            <>
            <div className="p-5">
              <LogoDiv className="d-flex justify-content-center">
                <img src={LogoAr} style={{width: "185px", borderRadius: "15px", backgroundColor: "#2D567B"}}/>
                {/* <img src={LogoSolar} /> */}
              </LogoDiv>
              <LinhaCard />
              <TituloCard className="text-black">Esqueceu sua senha?</TituloCard>
              <SubtituloCard className="text-black">
                Entre com seu email abaixo para restaurar sua senha:
              </SubtituloCard>
              <InputLabel>Email</InputLabel>
              <Formulario>
                <Icone>
                  <i className="ph-envelope"></i>
                </Icone>
                <InputUser
                  name="email"
                  value={lostPassword.email}
                  onChange={e =>
                    setLostPassword({
                      ...lostPassword,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Formulario>
              <DivBotoes>
                <BotaoVoltar onClick={() => setRecuperarSenha(!recuperarSenha)}>
                  Voltar
                </BotaoVoltar>
                <BotaoEnviar
                  onClick={getEmailAddress}
                  style={{ maxHeight: 49 }}
                >
                  {loadingResetPassword ? <Spinner /> : "Enviar"}
                </BotaoEnviar>
              </DivBotoes>
              </div>
            </>
          )}
        </Card>
      )}
      {/* <BarraLateral> */}
        {/* <ImgSofa src={imgLogin} /> */}
      {/* </BarraLateral> */}


      <ModalRegister setCadastro={setCadastro} cadastro={open} />

        <IconContainer className="ms-3">
          <IconLink href="https://www.instagram.com/clube.adias" target="_blank" rel="noopener noreferrer">
            <SvgIcon name="instagram" className="icon icon-xl"/>
          </IconLink>
          <IconLink href="https://www.youtube.com/adiasarcondicionado" target="_blank" rel="noopener noreferrer">
            <SvgIcon name="youtube" className="icon icon-xl"/>
          </IconLink>
          <IconLink href="https://www.linkedin.com/company/adias-ar-condicionado/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
            <SvgIcon name="linkedin" className="icon icon-xl"/>
          </IconLink>
        </IconContainer>


    </TelaToda>
  );
};

export default withRouter(Login);
