export const OrderActionTypes = {
  API_RESPONSE_SUCCESS: "@@order/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@order/API_RESPONSE_ERROR",
  GET_ORDER_ACTIVE: "@@order/GET_ORDER_ACTIVE",
  GET_DOCUMENTS: "@@order/GET_DOCUMENTS",
  GET_PAYMENT: "@@order/GET_PAYMENT",
  GET_GFE: "@@order/GET_GFE",
  GET_SLIPS: "@@order/GET_SLIPS",
  GET_PRODUCTS: "@@order/GET_PRODUCTS",
  GET_NEW_ORDER: "@@order/GET_NEW_ORDER",
  GET_FILTERS: "@@order/GET_FILTERS",
  CHANGE_GROUP: "@@order/CHANGE_GROUP",
  CHANGE_CATEGORY: "@@order/CHANGE_CATEGORY",
  CHANGE_PAGTO: "@@order/CHANGE_PAGTO",
  GET_ORDER_PRODUCTS: "@@order/GET_ORDER_PRODUCTS",
  GET_PRODUCT_DETAILS: "@@order/GET_PRODUCT_DETAILS",
  ADD_ITEM_TO_CART: "@@order/ADD_ITEM_TO_CART",
  ADD_ITEMS_TO_CART: "@@order/ADD_ITEMS_TO_CART",
  ADD_ITEM_TO_CART_WITHOUT_KIT: "@@order/ADD_ITEM_TO_CART_WITHOUT_KIT",
  REMOVE_ITEM_FROM_CART: "@@order/REMOVE_ITEM_FROM_CART",
  REMOVE_ITEM_FROM_CART_WITHOUT_KIT: "@@order/REMOVE_ITEM_FROM_CART_WITHOUT_KIT",
  ADD_ENDERECO_VALIDO: "@@order/ADD_ENDERECO_VALIDO",
  CART_RULE_UPDATE: "@@order/CART_RULE_UPDATE",  
  GET_FRETE_VALUE: "@@order/GET_FRETE_VALUE",
  GET_CUSTOMER: "@@order/GET_CUSTOMER",
  GET_SALDO: "@@order/GET_SALDO",
  EMITIR_PEDIDO: "@@order/EMITIR_PEDIDO",
  ZERAR_CARRINHO: "@@order/ZERAR_CARRINHO",
  GET_ITEM_TABLE: "@@order/GET_ITEM_TABLE",
  GET_CUPONS_NOT_USED: "@@order/GET_CUPONS_NOT_USED",
  VERIFICAR_CUPOM: "@@order/VERIFICAR_CUPOM",
  CANCEL_ORDER: "@@order/CANCEL_ORDER",
  GET_ARQUITETOS: "@@order/GET_ARQUITETOS",
  ADD_ARQUITETO: "@@order/ADD_ARQUITETO",
  CLEAR_CUSTOMER: "@@order/CLEAR_CUSTOMER",
  ATUALIZA_CARRINHO: "@@order/ATUALIZA_CARRINHO",
  ADD_PRODUCT_BY_SKU: "@@order/ADD_PRODUCT_BY_SKU",
  EDITAR_PEDIDO: "@@order/EDITAR_PEDIDO",
  CLEAR_NEW_ORDER: "@@order/CLEAR_NEW_ORDER",
  REMOVE_ORDER_ACTIVE: "@@order/REMOVE_ORDER_ACTIVE",
  CLEAR_ORDER_ERROR: "@@order/CLEAR_ORDER_ERROR",
  VERIFY_EMAIL_EXISTS: "@@order/VERIFY_EMAIL_EXISTS",
  REMOVE_ITEM_FROM_CART_KITS: "@@order/REMOVE_ITEM_FROM_CART_KITS",
  EDIT_QTD_KITS: "@@order/EDIT_QTD_KITS",
  EDIT_QTD_WITHOUT_KIT: "@@order/EDIT_QTD_KITS_WITHOUT_KIT",
  EDIT_PRECOCLIENTE_KITS: "@@order/EDIT_PRECOCLIENTE_KITS",
  EDIT_PRECOCLIENTE_WITHOUT_KIT: "@@order/EDIT_PRECOCLIENTE_KITS_WITHOUT_KIT",
  REMOVE_ITEM_TO_CART: "REMOVE_ITEM_TO_CART",
  GET_FRETE_VALUE_RESET: "GET_FRETE_VALUE_RESET",
  GET_DATA_CLIENT_ORDER_CHILDREN: "GET_DATA_CLIENT_ORDER_CHILDREN",
  RESET_ALL_DATA: "RESET_ALL_DATA",
  OCULTAR_RT: "OCULTAR_RT",
  PAGTO_SELECTED: "PAGTO_SELECTED",
  FILTER_PEDIDO: "FILTER_PEDIDO",
  PARCEIROS: "PARCEIROS",
  TABELA: "TABELA",
  REMOVE_PROMOTIONS_AND_COUPONS: "@@order/REMOVE_PROMOTIONS_AND_COUPONS"
};
