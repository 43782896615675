import React, { useState, useEffect, useCallback } from "react";
import { CardBody, Col, Row, Card } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addStatus,
  editQtdKits,
  removeFromCartKits,
} from "../../../store/actions";
import { dateNow } from "../../../utils";
import { toast } from "react-toastify";
import { FilePdf } from "phosphor-react";
import { valueByQTD } from "../../../utils/formulas";
import RtAndCashBack from "./RtAndCashBack";
import { useProfile } from "../../../hooks";
import { isInverter } from "../../utils/functions/solarUtils";

const AddedItem = ({
  item,
  addValTot,
  valores,
  setItensCarrinho,
  setPercentWattpay,
  itensCarrinho,
  valArquiteto,
  addValArqu,
  valorRt,
  addValorRt,
  ocultarRt,
  percentCashBackAll,
  setPercentCashBackAll,
  percentCashBackAllOnBlur,
  setValTotAndRt,
  acrescimo,
  changeFormOfPayment,
  itHasForecast,
  setRealPower,
  addValorRtNoRetention
}) => {
  const dispatch = useDispatch();
  const { newOrder, user } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
      user: state.Profile.profileDetails.user,
    };
  });
  const { userProfile } = useProfile();
  const displayName = userProfile
    ? userProfile["displayName"] !== null
      ? userProfile["displayName"].split(" ")[0]
      : userProfile["email"]
    : "";

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpeModalEditQtd, setIsOpenModalEditQtd] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const userCode = user?.code ? user?.code : "";

  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };

  useEffect(() => {
    if (percentCashBackAll != "0") {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [percentCashBackAll]);

  useEffect(() => {
    if (percentCashBackAll == "0" && collapse) {
      setItensCarrinho(state => {
        return {
          ...state,
          [item.nome]: {
            PRCRT: item.subTotal,
          },
        };
      });
    }
  }, [percentCashBackAll]);

  useEffect(() => {
    if (changeFormOfPayment) {
      setCollapse(false);
    }
  }, [changeFormOfPayment]);

  const onCloseDelete = useCallback(() => {
    setIsOpenDelete(false);
  }, []);

  const onDeleteMessage = () => {
    const date = dateNow();
    const hours = new Date().toLocaleTimeString();
    let thereIsAModuleInTeCart = item?.nome?.includes("MOD.")
      ? item?.nome?.includes("MOD.")
      : item?.nome?.includes("Modulos")
        ? item?.nome?.includes("Modulos")
        : item?.nome?.includes("MOD FOTOV")
          ? item?.nome?.includes("MOD FOTOV")
          : item?.nome?.includes("MOD. FOTOV")
            ? item?.nome?.includes("MOD. FOTOV")
            : item?.nome?.includes("GCL");

    if (thereIsAModuleInTeCart) {
      toast.warning(
        `Entre em contato com o comercial para realizar a compra de itens fora do Kit. Os valores podem sofrer alterações`,
        { theme: "colored" }
      );
    }

    if (newOrder.addedItems.length > 1) {
      let status = {
        statusName: "remove",
        name: item.nome,
        date: date,
        hours: hours,
        userCode: userCode,
        displayName: displayName,
      };
      dispatch(addStatus(status));

      if (isInverter(item)) {
        removeStringBoxAndMC4();
      } else {
        addValTotal();
      }

      dispatch(removeFromCartKits({ produto: item }));

      toast.info(`${item.nome} removido do carrinho.`, {
        theme: "colored",
      });
    } else {
      toast.info("Não é possivel remover todos os itens", { theme: "colored" });
    }
    setIsOpenDelete(false);
  };

  const removeStringBoxAndMC4 = () => {
    item?.pecasVinculadas?.map(peca => {
      newOrder.addedItems.filter(item => item.produto[0].sku == peca.sku).map(product => {
        const productItem = product.produto[0];
        const newQuantity = productItem.qtd - (peca.quantityPerInverter * item.qtd);
        if (newQuantity > 0) {
          editItemQuantity(productItem, newQuantity);
        } else {
          dispatch(removeFromCartKits({ produto: productItem }));
          removeLinkedPartsValTotal(productItem);
        }
      });
    });
  };

  const editItemQuantity = (product, newItemQuantity) => {
    const valTotal = {
      ...valores,
      [product.nome]: valueByQTD(
        product.precoCliente,
        newItemQuantity
      ),
    };
    addValTot(valTotal);
    dispatch(
      editQtdKits(product.nome, newItemQuantity)
    );
  }

  const removeLinkedPartsValTotal = (productItem) => {
    let valTotal = valores;
    delete valTotal[
      productItem?.id
        ? productItem?.id + " " + productItem?.nome
        : productItem.nome
    ];
    addValTot(valTotal);
    addValTotal();
  }

  const addValTotal = () => {
    let valTotal = valores;

    delete valTotal[item?.id ? item?.id + " " + item.nome : item.nome];
    addValTot(valTotal);
  };

  const modalConfirm = () => {
    setIsOpenDelete(true);
  };

  const modalEditQtd = () => {
    setIsOpenModalEditQtd(true);
  };

  const handleCollapse = () => {
    setCollapse(state => !state);
  };

  const precoCliente = item?.precoClienteAcrescimo
    ? item.precoClienteAcrescimo
    : item.precoCliente;

  const qtd = item?.qtd ? item.qtd : 1;
  const subTotal = precoCliente * qtd;

  const showForecast = itHasForecast.some(value => value.produto[0].sku == item.sku);

  return (
    <Card className="mb-0 mt-4" style={{ overflow: "hidden" }}>
      <CardBody>
        <Row style={{ alignItems: "center" }}>
          <Col md={6}>
            <p
              data-toggle="tooltip"
              data-placement="top"
              title={item.nome}
              className="mb-0"
              style={{
                fontWeight: 500,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item?.nome}
            </p>
          </Col>
          {/*    <Col md={2}>
            {parseFloat(precoCliente)?.toLocaleString("pt-br", formato)}
          </Col> */}
          <Col style={{ padding: 0, textAlign: "center" }} md={2}>
            {item?.linkFichaTecnica ? (
              <a target="_blank" rel="noreferrer" href={item?.linkFichaTecnica}>
                <FilePdf
                  style={{ cursor: "pointer" }}
                  size={25}
                  color="#FF601A"
                />
              </a>
            ) : (
              ""
            )}
          </Col>
          <Col md={1}>{item?.qtd ? Math.round(item.qtd) : 1}</Col>{" "}
          {showForecast
            ? <Col md={2}>{item?.previsao}</Col> 
            : <Col md={2}>Pronta entrega</Col>
          }
        </Row>
        <Row /* style={{ marginTop: collapse ? 11 : 0 }} */>
          <RtAndCashBack
            item={item}
            subTotal={subTotal}
            valores={valores}
            setItensCarrinho={setItensCarrinho}
            setPercentWattpay={setPercentWattpay}
            addValTot={addValTot}
            itensCarrinho={itensCarrinho}
            valArquiteto={valArquiteto}
            addValArqu={addValArqu}
            valorRt={valorRt}
            addValorRt={addValorRt}
            ocultarRt={ocultarRt}
            percentCashBackAll={percentCashBackAll}
            setPercentCashBackAll={setPercentCashBackAll}
            percentCashBackAllOnBlur={percentCashBackAllOnBlur}
            collapse={collapse}
            setValTotAndRt={setValTotAndRt}
            changeFormOfPayment={changeFormOfPayment}
            addValorRtNoRetention={addValorRtNoRetention}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default AddedItem;
