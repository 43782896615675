import { CatalogProductsActionTypes } from "./actionTypes";

const INIT_STATE = {
  products: [],
  filters: {
    tables: [],
    paymentConditions: [],
    groups: [],
    categories: [],
    capacities: [],
    voltages: [],
    manufacturers: [],
  },
  loading: false,
  loadingDetails: false
};

const CatalogProducts = (state = INIT_STATE, action) => {
  switch (action.type) {

    case CatalogProductsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case CatalogProductsActionTypes.GET_PRODUCTS:
          return {
            ...state,
            products: action.payload.data,
            loading: false
          }

        case CatalogProductsActionTypes.GET_PRODUCTS_DETAILS:
          const products = state.products;
          products.forEach(product => {
            if (!product.details && action.payload.data[product.CODPRODUTO]) {
              product.details = action.payload.data[product.CODPRODUTO];
            }
          });
          return {
            ...state,
            loadingDetails: false,
            products,
          };

        case CatalogProductsActionTypes.GET_STARTER_FILTERS_PRODUCTS:
          const online = Object.entries(action.payload.data.condPagto['Pagamento Online']).map(([id, item]) => ({
            id,
            ...item
          }));
          const offline = Object.entries(action.payload.data.condPagto['Pagamento a prazo - Precisa de De Acordo assinado']).map(([id, item]) => ({
            id,
            ...item
          }));
          const pushPaymentConditions = [...online, ...offline];
          const pushTable = Object.entries(action.payload.data.tabela).map(([id, item]) => ({
            id,
            name: item
          }));
          const pushGroups = Object.entries(action.payload.data.grupos).map(([id, item]) => ({
            ...item
          }));
          return {
            ...state,
            filters: {
              tables: pushTable,
              paymentConditions: pushPaymentConditions,
              groups: pushGroups,
              categories: action.payload.data.categorias,
              capacities: action.payload.data.capacidade,
              voltages: action.payload.data.voltagem,
              manufacturers: action.payload.data.fabricantes,
            },
            loading: false
          }

        case CatalogProductsActionTypes.GET_PAYMENT_CONDITIONS:
          const paymentConditions = action.payload.data.map((item, id) => ({
            id: item.CODPAG,
            name: item.DESCRPAG,
            tplib: item.TPLIB,
            limit: item.LIMITE
          }));
          return {
            ...state,
            filters: {
              ...state.filters,
              paymentConditions
            }
          }

        case CatalogProductsActionTypes.GET_CATEGORIES:
          return {
            ...state,
            filters: {
              ...state.filters,
              categories: action.payload.data
            }
          }

        case CatalogProductsActionTypes.GET_CAPACITIES:
          return {
            ...state,
            filters: {
              ...state.filters,
              capacities: action.payload.data
            }
          }

        default:
          return { ...state };
      }
    case CatalogProductsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case CatalogProductsActionTypes.GET_PRODUCTS_DETAILS:
          return {
            ...state,
            loadingDetails: false
          }

        case CatalogProductsActionTypes.GET_STARTER_FILTERS_PRODUCTS:
        case CatalogProductsActionTypes.GET_PRODUCTS:
          return {
            ...state,
            loading: false
          }

        default:
          return { ...state };
      }

    case CatalogProductsActionTypes.GET_PRODUCTS_DETAILS:
      return {
        ...state,
        loadingDetails: true
      }

    case CatalogProductsActionTypes.GET_PRODUCTS:
    case CatalogProductsActionTypes.GET_STARTER_FILTERS_PRODUCTS:
      return {
        ...state,
        loading: true
      }
    case CatalogProductsActionTypes.GET_PAYMENT_CONDITIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          paymentConditions: []
        }
      }
    case CatalogProductsActionTypes.GET_CATEGORIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          categories: []
        }
      }
    case CatalogProductsActionTypes.GET_CAPACITIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          capacities: []
        }
      }
    default:
      return state;
  }
};

export default CatalogProducts;