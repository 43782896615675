import React from "react";
import { Redirect } from "react-router-dom";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";


import "./newOrder.css";

import ControleRemoto from "./ControleRemoto";
import Frete from "./Frete";
import RegisterUser from "./RegisterUser";
import Resumo from "./Resumo";
import CartProducts from "./CartProducts";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import useLogic from "./useLogic";
import OrderFilter from "./OrderFilter";

const EditOrder = ({ history }) => {
  const {
    newOrder,
    loading,
    order,
    addedItems,
    emissaoPedido,
    addFrete,
    addValArqu,
    addValTot,
    addValorRt,
    arrayShippingFilialSelect,
    awaitModfyCart,
    cartBranchSameCustomerUfTable,
    cepCliente,
    changeCepCliente,
    changeClient,
    changePedidoEmitido,
    changeSaldo,
    changeType,
    client,
    currentPage,
    dataProducts,
    discountValue,
    errosDataProspectAndPartner,
    formVisible,
    frete,
    id,
    isRevenda,
    itensCarrinho,
    loadingProspect,
    newAddItemsSeparadoPorFilial,
    nfIsOpen,
    obsIsOpen,
    ocultarSemEstoque,
    pagamento,
    paginatedProducts,
    pedido,
    percentResumo,
    qtdAdd,
    saldo,
    setAwaitModfyCart,
    setClient,
    setCurrentPage,
    setDiscountValue,
    setErrosDataProspectAndPartner,
    setFormVisible,
    setItensCarrinho,
    setLoadingProspect,
    setNfIsOpen,
    setObsIsOpen,
    setOcultarSemEstoque,
    setPagamento,
    setPercentResumo,
    setQtdAdd,
    setSendValServ,
    setValServ,
    type,
    ufValidate,
    valArquiteto,
    valRt,
    valTot,
    valorRt,
    valores,
    vendedorComRt
  } = useLogic();

  const renderView = () => {
    if ("fabricantes" in newOrder) {
      return (
        <React.Fragment>
          {loadingProspect && (
            <div className="carregando-emissao">
              <Spinner />
            </div>
          )}

          {loading && (
            <div className="carregando-emissao">
              <Spinner />
            </div>
          )}
          <React.Fragment>
          <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente OrderFilter">
              <OrderFilter
                pagamento={pagamento}
                setPagamento={setPagamento}
                setAwaitModfyCart={setAwaitModfyCart}
                pedido={pedido}
                ufValidate={ufValidate}
                history={history}
              />
            </ErrorBoundary>
            {(newOrder?.addedItems.length || 0) > 0 ? (
              <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente CartProducts">
                <CartProducts
                  setValServ={setValServ}
                  setSendValServ={setSendValServ}
                  addValTot={addValTot}
                  valores={valores}
                  setItensCarrinho={setItensCarrinho}
                  itensCarrinho={itensCarrinho}
                  valArquiteto={valArquiteto}
                  addValArqu={addValArqu}
                  valorRt={valorRt}
                  addValorRt={addValorRt}
                  setPercentResumo={setPercentResumo}
                  setQtdAdd={setQtdAdd}
                  valTot={valTot}
                />
              </ErrorBoundary>
            ) : (
              <div />
            )}
            <Row className="mt-4">
              <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente RegisterUser">
                <RegisterUser
                  valTot={valTot}
                  changeCepCliente={changeCepCliente}
                  changeClient={changeClient}
                  client={client}
                  formVisible={formVisible}
                  setFormVisible={setFormVisible}
                  errosDataProspectAndPartner={errosDataProspectAndPartner}
                  setErrosDataProspectAndPartner={
                    setErrosDataProspectAndPartner
                  }
                />
              </ErrorBoundary>
            </Row>
            <Row className="d-flex justify-content-between" id="frete">
              <Col md={7}>
                <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente Frete">
                  <Frete
                    valTot={valTot}
                    addFrete={addFrete}
                    cepCliente={cepCliente}
                    newAddItemsSeparadoPorFilial={
                      newAddItemsSeparadoPorFilial
                    }
                    awaitModfyCart={awaitModfyCart}
                    setAwaitModfyCart={setAwaitModfyCart}
                  />{" "}
                </ErrorBoundary>
                {/*  <Arquiteto valArq={valArq} /> */}
              </Col>
              <Col md={5}>
                {" "}
                <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente Resumo">
                  <Resumo
                    valTot={valTot}
                    valRt={valRt}
                    frete={frete}
                    saldo={saldo}
                    pedido={pedido}
                    changePedidoEmitido={changePedidoEmitido}
                    changeType={changeType}
                    formVisible={formVisible}
                    setLoadingProspect={setLoadingProspect}
                    percent={percentResumo}
                    qtd={qtdAdd}
                    discountValue={discountValue}
                    arrayShippingFilialSelect={arrayShippingFilialSelect}
                    newAddItemsSeparadoPorFilial={
                      newAddItemsSeparadoPorFilial
                    }
                  />{" "}
                </ErrorBoundary>
              </Col>
            </Row>
            <ControleRemoto />
          </React.Fragment>
        </React.Fragment>
      );
    } else {
      return (
        <div
          style={{
            marginInline: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content neworder">
        <Container>
          {
            order ? (
              order?.Situacao < "F" ? (
                addedItems.length > 0 ? (
                  <React.Fragment>{renderView()}</React.Fragment>
                ) : (
                  <Spinner />
                )
              ) : (
                <Redirect to={`/pedidos/${order?.NumPedido}/order`} />
              )
            ) : (
              <Spinner />
            )
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditOrder;
